import { spawn } from 'redux-saga/effects';
import authSagas from './auth';
import artworkSagas from './artwork';
import artistSaga from './artist';
import orderSagas from './order';

export default function* rootSaga() {
  yield spawn(authSagas);
  yield spawn(artworkSagas);
  yield spawn(artistSaga);
  yield spawn(orderSagas);
}
