import React from 'react'
import upcoming1 from '../../assets/upcoming1.png'
import upcoming2 from '../../assets/artstudio.jpg'


const BookEvent = () => {
  return (
    <div className='container relative px-4 lg:py-10 mx-auto max-w-5xl'>
      <div>
        <div className='grid grid-cols-2 gap-2 lg:gap-4 w-full'>
          <div className='col-span-1'>
            <div className='relative'>
              <div>
                <img
                  src={upcoming1}
                  alt='img'
                  className='w-full h-[48vh] lg:w-[500px] lg:h-[90vh] object-cover'
                />
              </div>

              <div className='bg-[#100F0E] lg:text-end text-white bg-opacity-60 absolute bottom-0 h-[45%] lg:h-[40%] md:h-[37%] w-full'>
                <div className='absolute bottom-0 h-[120px] md:h-[150px] lg:h-[200px] left-[10px] lg:left-[30px]'>
                  <p className='font-heading text-[12px] md:text-[20px] lg:text-[30px] tracking-normal text-left w-full lg:w-[300px] font-extrabold lg:leading-[35px]'>
                    The Unveiling of the ARTIST
                  </p>
                  <div className='text-[10px] lg:text-[18px] font-semibold tracking-wide text-left pt-[10px] lg:pt-[22px]'>
                    December 27, 2023.
                  </div>
                  <div className='absolute lg:left-[350px] right-3 lg:right-0 pt-3 lg:pt-[0px]'>
                    <div className='border border-[#FA9219] text-[10px] lg:text-sm text-[#FA9219] text-center p-1 lg:p-2 w-[100px] lg:w-[100px] font-heading'>
                      Book Now
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className='col-span-1'>
            <div className='lg:mb-4'>
              <div className='relative'>
                <img
                  src={upcoming2}
                  alt='img'
                  className='w-full h-[48vh] lg:w-[500px] lg:h-[90vh] object-cover'
                />
                <div className='bg-[#100F0E] lg:text-end text-white bg-opacity-60 absolute bottom-0 h-[45%] lg:h-[40%] md:h-[37%] w-full'>
                  <div className='absolute bottom-0 h-[120px] md:h-[150px] lg:h-[200px] left-[10px] lg:left-[30px]'>
                    <p className='font-heading text-[12px] md:text-[20px] lg:text-[30px] tracking-normal text-left w-full lg:w-[300px] font-extrabold lg:leading-[35px]'>
                      The Unveiling of the ARTIST
                    </p>
                    <div className='text-[10px] lg:text-[18px] font-semibold tracking-wide text-left pt-[10px] lg:pt-[22px]'>
                      December 29, 2023.
                    </div>
                    <div className='absolute lg:left-[350px] right-3 lg:right-0 pt-3 lg:pt-[0px]'>
                      <div className='border border-[#FA9219] text-[10px] lg:text-sm text-[#FA9219] text-center p-1 lg:p-2 w-[100px] lg:w-[100px] font-heading'>
                        Book Now
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BookEvent
