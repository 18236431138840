import React, { useState } from 'react'
import LoginRegBtn from '../../atoms/button/LoginRegBtn'
import Input from '../../atoms/input/Input'
import ContactInput from '../../atoms/input/ContactInput'
import ContactButton from '../../atoms/button/ContactButton'

const ContactForm = () => {
  const [signUp, setSignUp] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    textarea: '',
  })

  const { firstName, lastName, email, password, password2 } = signUp

  const onInputChange = (e) => {
    setSignUp({
      ...signUp,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = (e) => {
    // e.preventDefault();
    if (password !== password2) {
      // console.log("incorrect password");
    }
  }
  return (
    <div className='lg:mb-[100px]'>
      <div className='mt-[10px] lg:mt-[20px]'>
        <div>
          <form>
            <div className='w-full lg:w-[899px]'>
              <ContactInput
                inputType='text'
                inputName='firstName'
                inputPlaceholder='First Name'
                inputValue={signUp.firstName}
                onChange={onInputChange}
              />
            </div>
            <div className='mt-2 lg:mt-[20px] w-full lg:w-[899px]'>
              <ContactInput
                inputType='text'
                inputName='lastName'
                inputPlaceholder='Last Name'
                inputValue={signUp.lastName}
                onChange={onInputChange}
              />
            </div>
            <div className='mt-2 lg:mt-[20px] w-full lg:w-[899px]'>
              <ContactInput
                inputType='number'
                inputName='phoneNumber'
                inputPlaceholder='Phone Number'
                inputValue={signUp.phoneNumber}
                onChange={onInputChange}
              />
            </div>
            <div className='mt-2 lg:mt-[20px] w-full lg:w-[899px]'>
              <ContactInput
                inputType='email'
                inputName='email'
                inputPlaceholder='Email address'
                inputValue={signUp.email}
                onChange={onInputChange}
              />
            </div>
            <div className='mt-2 lg:mt-[20px] w-full lg:w-[899px]'>
              <textarea
                cols='50'
                rows='10'
                placeholder='Write a message'
                name='textarea'
                onChange={onInputChange}
                value={signUp.textarea}
                className='w-[100%] p-[5px_0px_5px_6px] lg:p-[9px_0px_10px_24px] bg-white text-black placeholder-[#918B83] border border-[#413E3A] text-[12px] lg:text-[14px]'
              ></textarea>
            </div>
          </form>
        </div>
        <div
          onClick={() => handleSubmit()}
          className='lg:mt-[45px] mt-[10px] mb-[25px] lg:mb-[0px] w-[160px] lg:w-[200px]'
        >
          <ContactButton
            buttonText='SUBMIT HERE'
            padding=''
          />
        </div>
      </div>
    </div>
  )
}

export default ContactForm
