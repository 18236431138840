import React, { useEffect, useState } from "react";
import Logo from "../../assets/Logo.svg";
import { Link } from "react-router-dom";

const AuthNavbar = () => {
  return (
    <header className="container mx-auto w-full p-4">
      <Link to="/">
        <img
          src={Logo}
          alt="kachiLogo"
          className="h-6 lg:w-[193px] lg:h-[32px] cursor-pointer"
        />
      </Link>
    </header>
  );
};

export default AuthNavbar;
