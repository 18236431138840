import React, { useRef } from "react";
import LandingLayout from "../../../components/layout/landingLayout/LandingLayout";
import HomeLandingPage from "../../../molecules/homeLandingPage/HomeLandingPage";
import RhythmOfExpression from "../../../molecules/rhythmOfExpression/RhythmOfExpression";
import ArtIsLife from "../../../molecules/artLife/ArtIsLife";
import UpComingEvents from "../../../molecules/upComingEvents/UpComingEvents";

// for card 1
import img1 from "../../../assets/Rectangle2.png";
import img4 from "../../../assets/Rectangle4.png";
import img3 from "../../../assets/Rectangle3.png";
import img5 from "../../../assets/Rectangle5.png";

// for card 3
import img11 from "../../../assets/womanDark.png";
import img14 from "../../../assets/solace.png";
import img13 from "../../../assets/imageDrum.png";
import img15 from "../../../assets/woman.png";
// for card 2
import img6 from "../../../assets/orderImg.png";
import img7 from "../../../assets/manAndWomanBicycle.png";
import img8 from "../../../assets/Rectangle18.png";
import img9 from "../../../assets/Rectangle23.png";

import arrowRight from "../../../assets/arrowRightColored.svg";
import arrowRightWhite from "../../../assets/arrowRightWhite.png";
import { MapComponent } from "../../../molecules/map/map";
const Home = () => {
  const ref = useRef(null);

  const smoothScroll = () => {
    const element = ref.current;
    let rect = element.getBoundingClientRect();
    window.scroll({
      top: rect.top,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <LandingLayout height={""}>
        <HomeLandingPage smoothScroll={smoothScroll} />
        <div className="mb-[30px] lg:my-[40px]">
          <ArtIsLife
            headerTwo="We believe that Art is Life."
            paragraph={`Whether you're a seasoned art enthusiast or a casual art admirer,
            our gallery offers something for everyone.`}
            // for card 1
            img1={img1}
            img3={img3}
            img4={img4}
            img5={img5}
          />
        </div>
        <div className="lg:mb-[20px]">
          <RhythmOfExpression
            backgroundImage={"bg-canvaImg"}
            bgImageBottomCenter={"lg:bg-[center]"}
          />
        </div>
        <div className="mb-[20px] lg:mb-[40px]">
          {/* art unveiled with hover and scrolling event */}
          <ArtIsLife
            headerTwo={`Art Un-veiled`}
            paragraph={`we celebrate the beauty of diversity in art. Each piece offers a fresh perspective, a unique narrative, and a chance to connect with the artist's soul.`}
            buttonText="View Art"
            img1={img11}
            img3={img13}
            img4={img14}
            img5={img15}
            url={"/artist"}
          />
        </div>
        {/* <RhythmOfExpression /> */}
        {/* map */}
        <div ref={ref} className="mb-10">
          <MapComponent
            backgroundImage={"bg-map"}
            header="We are expecting you"
            buttonText="Pay Us a Visit Today"
            buttonIcon={arrowRightWhite}
          />
        </div>
        <div className="mb-[40px]">
          {/* UpComingEvents */}
          <UpComingEvents />
        </div>
        {/*  */}
        <RhythmOfExpression
          backgroundImage={"bg-imgFrames"}
          img1={img1}
          img3={img3}
          img4={img4}
          img5={img5}
        />
        {/* Bid now */}
        <div className="bg-[#FAFAFA] mt-[50px] mb-[50px] lg:mb-[100px] lg:mt-[100px] pt-[9px] pb-[16px] lg:pt-[1px] lg:pb-[2px]">
          <ArtIsLife
            headerOne={"AUCTION"}
            headerTwo={"BID NOW"}
            paragraph={`we celebrate the beauty of diversity in art. Each piece offers a fresh perspective, a unique narrative, and a chance to connect with the artist's soul.`}
            buttonText="More"
            buttonIcon={arrowRight}
            img1={img6}
            img3={img9}
            img4={img7}
            img5={img8}
            url={"/artist"}
          />
        </div>
        <RhythmOfExpression left="left" backgroundImage={"bg-manWithLongEye"} />
      </LandingLayout>
    </div>
  );
};

export default Home;
