import React from 'react'
import AdminTableWrapper from './AdminTableWrapper'
import AdminTableHead from './AdminTableHead'
import ContactButton from '../../../../atoms/button/ContactButton'

const AdminTableLayout = () => {
  const tableHeaders = [
    { title: 'S/N', width: '30%' },
    { title: 'Name', width: '30%' },
    { title: 'Email address', width: '30%' },
    { title: 'Amount', width: '30%' },
    { title: 'Status', width: '30%' },
    { title: 'Date', width: '30%' },
  ]

  const latestOrderData = [
    {
      sn: 3051,
      name: 'Bate Lane',
      email: 'bate@kjart.com',
      amount: '100,000,000,000',
      status: 'Delivered',
      date: '09.09.24',
    },
    {
      sn: 3051,
      name: 'Bate Lane',
      email: 'bate@kjart.com',
      amount: '100,000',
      status: 'Delivered',
      date: '09.09.24',
    },
    {
      sn: 3051,
      name: 'Bate Lane',
      email: 'bate@kjart.com',
      amount: '100,000',
      status: 'Delivered',
      date: '09.09.24',
    },
    {
      sn: 3051,
      name: 'Bate Lane',
      email: 'bate@kjart.com',
      amount: '100,000',
      status: 'Delivered',
      date: '09.09.24',
    },
    {
      sn: 3051,
      name: 'Bate Lane',
      email: 'bate@kjart.com',
      amount: '100,000',
      status: 'Delivered',
      date: '09.09.24',
    },
    {
      sn: 3051,
      name: 'Bate Lane',
      email: 'bate@kjart.com',
      amount: '100,000',
      status: 'Canceled',
      date: '09.09.24',
    },
    {
      sn: 3051,
      name: 'Bate Lane',
      email: 'bate@kjart.com',
      amount: '100,000',
      status: 'Pending',
      date: '09.09.24',
    },
    {
      sn: 3051,
      name: 'Bate Lane',
      email: 'bate@kjart.com',
      amount: '100,000',
      status: 'Delivered',
      date: '09.09.24',
    },
    {
      sn: 3051,
      name: 'Bate Lane',
      email: 'bate@kjart.com',
      amount: '100,000',
      status: 'Delivered',
      date: '09.09.24',
    },
  ]

  return (
    <>
      <table className=''>
        <AdminTableWrapper>
          <AdminTableHead headers4={tableHeaders}>
            {latestOrderData.map((item, index) => {
              return (
                <tr className='border-b-2 border-[#C4C2BA]' key={index}>
                  <td className='whitespace-nowrap p-4 '>{item.sn}</td>
                  <td className='whitespace-nowrap p-4 '>{item.name}</td>
                  <td className='p-4'> {item.email}</td>
                  <td className='p-4 whitespace-nowrap'># {item.amount}</td>
                  <td className='p-4 text-center'>
                    <ContactButton
                      buttonText={item.status}
                      padding='lg:p-[10px_30px_10px_30px] p-[10px_10px_10px_10px]'
                    />
                  </td>
                  <td className='p-4'>{item.date}</td>
                </tr>
              )
            })}
          </AdminTableHead>
        </AdminTableWrapper>
      </table>
    </>
  )
}

export default AdminTableLayout
