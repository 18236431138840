import axios from "./axios";
import { call, put, takeLatest, spawn } from "redux-saga/effects";
import { clientErrorMessage, delay } from "./reusable";
import {
  CREATE_ARTWORK_REQUEST,
  DELETE_ARTWORK_REQUEST,
  GET_ARTWORKS_REQUEST,
  GET_ARTWORK_REQUEST,
} from "../types";
import {
  createArtworkFailure,
  createArtworkLoading,
  createArtworkSuccess,
  deleteArtworkFailure,
  deleteArtworkLoading,
  deleteArtworkSuccess,
  getArtworkFailure,
  getArtworkLoading,
  getArtworkSuccess,
  getArtworksFailure,
  getArtworksLoading,
  getArtworksSuccess,
} from "../action";

const ajaxDBCalls = {
  createArtwork: async ({ formData }) => {
    const response = await axios.post(`/artwork/create`, formData);
    return response;
  },
  getArtworks: async () => {
    const response = await axios.get(`/artwork`);
    return response;
  },
  getArtwork: async (id) => {
    const response = await axios.get(`/artwork/${id}`);
    return response;
  },
  deleteArtwork: async (data) => {
    const response = await axios.delete(`/artwork/delete`, { data });
    return response;
  },
};

function* createArtwork({ payload }) {
  try {
    yield put(createArtworkLoading(true));

    const res = yield call(ajaxDBCalls.createArtwork, payload);

    yield put(createArtworkSuccess(res.data));

    yield put(createArtworkLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(createArtworkFailure(errorMessage));
    yield put(createArtworkLoading(false));
    yield delay();
    yield put(createArtworkFailure(""));
  }
}

function* getArtworks({ payload }) {
  try {
    yield put(getArtworksLoading(true));

    const res = yield call(ajaxDBCalls.getArtworks, payload);

    yield put(getArtworksSuccess(res.data));

    yield put(getArtworksLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(getArtworksFailure(errorMessage));
    yield put(getArtworksLoading(false));
    yield delay();
    yield put(getArtworksFailure(""));
  }
}

function* getArtwork({ payload }) {
  try {
    yield put(getArtworkLoading(true));

    const res = yield call(ajaxDBCalls.getArtwork, payload);

    yield put(getArtworkSuccess(res.data));

    yield put(getArtworkLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(getArtworkFailure(errorMessage));
    yield put(getArtworkLoading(false));
    yield delay();
    yield put(getArtworkFailure(""));
  }
}

// delete Artworks
function* deleteArtwork({ payload }) {
  try {
    yield put(deleteArtworkLoading(true));
    const res = yield call(ajaxDBCalls.deleteArtwork, payload);
    yield put(deleteArtworkSuccess(res.data));
    yield put(deleteArtworkLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      const { error } = err.response.data;
      const { message } = err.response.data;

      if (error) errorMessage = error;
      else errorMessage = message;
    }

    yield put(deleteArtworkFailure(errorMessage));
    yield put(deleteArtworkLoading(false));
    yield delay();
    yield put(deleteArtworkFailure(""));
  }
}

//Watchers
function* deleteArtworkWatcher() {
  yield takeLatest(DELETE_ARTWORK_REQUEST, deleteArtwork);
}

//Watchers
function* createArtworkWatcher() {
  yield takeLatest(CREATE_ARTWORK_REQUEST, createArtwork);
}
function* getArtworksWatcher() {
  yield takeLatest(GET_ARTWORKS_REQUEST, getArtworks);
}
function* getArtworkWatcher() {
  yield takeLatest(GET_ARTWORK_REQUEST, getArtwork);
}

export default function* artworkSagas() {
  yield spawn(createArtworkWatcher);
  yield spawn(getArtworksWatcher);
  yield spawn(getArtworkWatcher);
  yield spawn(deleteArtworkWatcher);
}
