import React, { useState } from "react";
import { Link } from "react-router-dom";

const MobileMenu = ({ navItems, isLoggedIn }) => {
  const [isOpen, setIsOpen] = useState(false);
  // const [menu, setMenu] = useState('Menu')
  // const [handleOpenNavbar, setHandleOpenNavbar] = useState(openNavbar)
  return (
    <div className=" text-white transition-all duration-500">
      <div>
        <div
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className={`${
            isOpen ? "text-mainGold underline" : ""
          } font-heading text-[14px] cursor-pointer`}
        >
          {isOpen ? "close" : "Menu"}
        </div>
        <div>
          <div
            style={{
              // opacity: isOpen ? "1" : "0",
              transition: "all .5s",
              visibility: isOpen ? "visible" : "hidden",
              left: isOpen ? "20px" : "-200px",
            }}
            className="bg-black box w-[200px] absolute top-[65px] bg-opacity-80 "
          >
            {navItems.length > 0 &&
              navItems.map(({ name, id, to, nav, active }) => (
                <div key={id}>
                  <Link
                    to={`/${to}`}
                    className="flex w-full items-center max-sm:pl-7 sm:w-fit font-medium text-white font-heading text-xs p-2 pt-6"
                  >
                    <span>{name}</span>
                  </Link>
                </div>
              ))}

            {isLoggedIn ? (
              <div>
                <Link
                  to={`/dashboard/profile`}
                  className="flex w-full items-center max-sm:pl-7 sm:w-fit font-medium text-white font-heading text-xs p-2 pt-6 pb-6"
                >
                  <span>Dashboard</span>
                </Link>
              </div>
            ) : (
              <>
                <div>
                  <Link
                    to={`/register`}
                    className="flex w-full items-center max-sm:pl-7 sm:w-fit font-medium text-white font-heading text-xs p-2 pt-6"
                  >
                    <span>Register</span>
                  </Link>
                </div>
                <div>
                  <Link
                    to={`/login`}
                    className="flex w-full items-center max-sm:pl-7 sm:w-fit font-medium text-white font-heading text-xs p-2 pt-6 pb-6"
                  >
                    <span>Login</span>
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
