import React, { useState } from "react";
import Input from "../../../atoms/input/Input";
import LoginRegBtn from "../../../atoms/button/LoginRegBtn";
import { Link } from "react-router-dom";

const ConfirmForgotPasswordEmail = () => {
  return (
    <div className="h-[100vh] overflow-scroll bg-[#000000]">
      <div className="container mx-auto max-w-3xl px-4 py-10">
        <div>
          <h3 className="font-heading text-4xl text-white">Email Sent</h3>
          <p className="py-8 font-body text-xl text-white">
            We sent a code to your email address
          </p>
        </div>
        <div>
          <form autoComplete="off" className="grid grid-cols-4 lg:max-w-sm">
            {/* max-w-sm */}
            <div className="w-10">
              <Input
                inputType="number"
                inputName="number"
                inputPlaceholder="0"
              />
            </div>
            <div className="w-10">
              <Input
                inputType="number"
                inputName="number"
                inputPlaceholder="0"
              />
            </div>
            <div className="w-10">
              <Input
                inputType="number"
                inputName="number"
                inputPlaceholder="0"
              />
            </div>
            <div className="w-10">
              <Input
                inputType="number"
                inputName="number"
                inputPlaceholder="0"
              />
            </div>
          </form>
        </div>
        <div className="mt-8 w-40">
          <LoginRegBtn buttonText="Check mail" padding="" />
        </div>
        <div className="mt-5 flex items-center ">
          <div>
            <p className="text-sm text-white">Didn’t receive a mail?</p>
          </div>
          <div>
            <Link to="/" className="ml-[10px] font-heading text-sm text-white">
              Resend mail
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmForgotPasswordEmail;
