import axios from "./axios";
import { call, put, takeLatest, spawn } from "redux-saga/effects";
import { clientErrorMessage, delay } from "./reusable";
import { CREATE_ORDER_REQUEST, GET_USER_ORDER_REQUEST } from "../types";
import {
  createOrderFailure,
  createOrderLoading,
  createOrderSuccess,
  getUserOrderFailure,
  getUserOrderLoading,
  getUserOrderSuccess,
} from "../action";

const ajaxDBCalls = {
  createOrder: async ({ formData }) => {
    const response = await axios.post(`/order/create`, formData);
    return response;
  },
  getUserOrder: async ({ userId }) => {
    const response = await axios.get(`/order/user/${userId}`);
    return response;
  },
};

function* createOrder({ payload }) {
  try {
    yield put(createOrderLoading(true));

    const res = yield call(ajaxDBCalls.createOrder, payload);

    yield put(createOrderSuccess(res.data));

    yield put(createOrderLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      const { error } = err.response.data;
      const { message } = err.response.data;

      if (error) errorMessage = error;
      else errorMessage = message;
    }

    yield put(createOrderFailure(errorMessage));
    yield put(createOrderLoading(false));
    yield delay();
    yield put(createOrderFailure(""));
  }
}

function* getUserOrder({ payload }) {
  try {
    yield put(getUserOrderLoading(true));

    const res = yield call(ajaxDBCalls.getUserOrder, payload);

    yield put(getUserOrderSuccess(res.data));

    yield put(getUserOrderLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(getUserOrderFailure(errorMessage));
    yield put(getUserOrderLoading(false));
    yield delay();
    yield put(getUserOrderFailure(""));
  }
}

//Watchers
function* createOrderWatcher() {
  yield takeLatest(CREATE_ORDER_REQUEST, createOrder);
}

function* getUserOrderWatcher() {
  yield takeLatest(GET_USER_ORDER_REQUEST, getUserOrder);
}

export default function* artworkSagas() {
  yield spawn(createOrderWatcher);
  yield spawn(getUserOrderWatcher);
}
