import React from 'react'
import AdminDashboardActivity from '../../Dashboard'
import AdminAllArtistTable from '../../molecule/adminTable/AdminAllArtistTable'
const AdminAllArtist = () => {
  return (
    <div>
      <AdminDashboardActivity>
        <div className='container max-w-5xl mx-auto my-16'>
          <h4 className='font-heading lg:text-[25px] lg:leading-[41px] mb-[12px] lg:mb-[20px] text-black'>
            All Artistes
          </h4>
          <div className='overflow-x-scroll'>
            <AdminAllArtistTable />
          </div>
        </div>
      </AdminDashboardActivity>
    </div>
  );
}

export default AdminAllArtist
