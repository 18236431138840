import React, { useState } from "react";
import Input from "../../../atoms/input/Input";
import LoginRegBtn from "../../../atoms/button/LoginRegBtn";

const ForgotPassword = () => {
  return (
    <div className="h-[100vh] overflow-scroll bg-[#000000]">
      <div className="container mx-auto max-w-3xl px-4 py-10">
        <div>
          <div>
            <h3 className="font-heading text-4xl text-white">
              Forgot your password?
            </h3>
            <p className="py-8 font-body text-xl text-white">
              Enter the email address you used to register on Kachi james art
              gallery.
            </p>
          </div>
        </div>
        <div className="">
          <form autoComplete="off">
            <div className="flex flex-col gap-y-2">
              <Input
                inputType="email"
                inputName="email"
                inputPlaceholder="Email address"
                formik={""}
              />
            </div>
          </form>
        </div>
        <div className="mt-8 w-40">
          <LoginRegBtn
            action={""}
            loading={""}
            buttonText="Submit"
            padding=""
          />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
