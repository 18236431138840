import {
  LOGOUT,
  REGISTER_FAIL,
  REGISTER_LOADING,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  CLEAR_REGISTER_SUCCESS,
  LOGIN_FAIL,
  LOGIN_LOADING,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  CLEAR_LOGIN_SUCCESS,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_LOADING,
  CLEAR_UPDATE_USER_SUCCESS,
} from "../types";

export const logout = () => {
  return {
    type: LOGOUT,
  };
};

export const registerRequest = (prop) => {
  return {
    type: REGISTER_REQUEST,
    payload: prop,
  };
};

export const registerSuccess = (prop) => {
  return {
    type: REGISTER_SUCCESS,
    payload: prop,
  };
};

export const registerFailure = (errors) => {
  return {
    type: REGISTER_FAIL,
    payload: { errors },
  };
};

export const registerLoading = (loading) => {
  return {
    type: REGISTER_LOADING,
    payload: loading,
  };
};

export const clearRegisterSuccess = (prop) => {
  return {
    type: CLEAR_REGISTER_SUCCESS,
    payload: prop,
  };
};

//UPDATE_USER

export const updateUserRequest = (prop) => {
  return {
    type: UPDATE_USER_REQUEST,
    payload: prop,
  };
};

export const updateUserSuccess = (prop) => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: prop,
  };
};

export const updateUserFailure = (errors) => {
  return {
    type: UPDATE_USER_FAIL,
    payload: { errors },
  };
};

export const updateUserLoading = (loading) => {
  return {
    type: UPDATE_USER_LOADING,
    payload: loading,
  };
};

export const clearUpdateUserSuccess = (prop) => {
  return {
    type: CLEAR_UPDATE_USER_SUCCESS,
    payload: prop,
  };
};

// LOGIN
export const loginRequest = (prop) => {
  return {
    type: LOGIN_REQUEST,
    payload: prop,
  };
};

export const loginSuccess = (prop) => {
  return {
    type: LOGIN_SUCCESS,
    payload: prop,
  };
};

export const loginFailure = (errors) => {
  return {
    type: LOGIN_FAIL,
    payload: { errors },
  };
};

export const loginLoading = (loading) => {
  return {
    type: LOGIN_LOADING,
    payload: loading,
  };
};

export const clearLoginSuccess = (prop) => {
  return {
    type: CLEAR_LOGIN_SUCCESS,
    payload: prop,
  };
};
