import React, { useState } from 'react'

const ProfileOrderNavbar = ({ setActiveNav, activeNav }) => {
  const navbarLinkNames = [
    {
      name: 'Current Order',
      id: 1,
    },
    {
      name: 'Order History',
      id: 2,
    },
  ]

  const switchNav = (navId) => {
    setActiveNav(navId)
  }

  return (
    <div>
      <div className='flex gap-5'>
        {navbarLinkNames.map(({ name, id }) => (
          <div key={id}>
            <div
              className={`flex lg:w-full items-center justify-center max-sm:pl-2 md:pl-2 text-[13px] lg:text-[15px] sm:w-fit ${
                activeNav === id ? 'text-[#100F0E] underline font-bold' : ''
              } cursor-pointer`}
              onClick={() => switchNav(id)}
            >
              <span>{name}</span>
            </div>
            <div></div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProfileOrderNavbar
