import React, { useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Modal from "@mui/material/Modal";
import Button from "../../../../atoms/button/Button";
import {
  clearDeleteArtworkSuccess,
  deleteArtworkRequest,
  getArtworksRequest,
} from "../../../../redux/action";

const RemoveArtWork = ({ getArtworksLoading, artworks }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  const { deleteArtworkSuccess, deleteArtworkLoading, deleteArtworkError } =
    useSelector((state) => {
      const {
        success: { deleteArtwork: deleteArtworkSuccess },
        errors: { deleteArtwork: deleteArtworkError },
      } = state.ajaxStatuses;
      // delete artwork
      const { deleteArtworkLoading } = state.loadingIndicator;

      return {
        deleteArtworkLoading,
        deleteArtworkSuccess,
        deleteArtworkError,
      };
    });

  const deleteArtworkFunc = (id) => {
    // dispatch(deleteArtworkRequest());
    dispatch(deleteArtworkRequest({ artist_id: id }));
    setOpen(false);
  };

  // useEffect(() => {
  //   if (deleteArtworkLoading) {
  //     dispatch(deleteArtworkRequest());
  //   }
  // }, [deleteArtworkRequest]);

  useEffect(() => {
    if (deleteArtworkSuccess) {
      toast.success(deleteArtworkSuccess, {
        duration: 3000,
      });
      dispatch(clearDeleteArtworkSuccess());
    }
  }, [deleteArtworkSuccess]);

  return (
    <div>
      <div className="container mx-auto max-w-6xl px-4 pb-[51px] lg:pb-[130px]">
        {/* lg:pl-[66px] lg:pr-[66px] */}

        {getArtworksLoading ? (
          <div className="flex items-center justify-center lg:h-[550px]">
            <CircularProgress size={20} style={{ color: "black" }} />
          </div>
        ) : artworks.length === 0 ? (
          <div>
            <div
              colSpan="3"
              className="my-11 p-4 text-center font-heading text-xl"
            >
              No artwork here
            </div>
          </div>
        ) : (
          <div className="mb-[3px] flex flex-wrap gap-4 lg:mb-[14px]">
            {artworks.map((artwork, index) => (
              <div key={index}>
                <div className="relative">
                  <div className="absolute z-10 p-[10px] text-end lg:w-[250px] lg:p-[15px]">
                    <p
                      className="font-heading text-[10px] lg:text-[12px]"
                      onClick={handleOpen}
                    >
                      Remove
                    </p>
                    {open && (
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className="bg-white bg-opacity-5"
                      >
                        <div
                          //  sx={style}
                          className="flex h-[30vh] flex-col justify-center rounded-md bg-white lg:mx-8"
                        >
                          <div>
                            <h5 className="mx-auto w-[80%] pt-8 font-heading text-sm lg:text-xl">
                              {/* Do you want to delete {item.firstname}{' '}
                              {item.lastname} ? */}
                              Do you want to delete this Artwork?
                            </h5>
                          </div>
                          <div className="my-8 flex flex-col items-center justify-center gap-[10px] lg:flex-row lg:gap-[45px]">
                            <div className="w-[200px]">
                              <Button
                                action={handleClose}
                                color={"border-[green] text-[green]"}
                                buttonText={"cancel"}
                              />
                            </div>
                            <div className="w-[200px]">
                              <Button
                                action={() => deleteArtworkFunc(artwork._id)}
                                buttonText={"delete"}
                                color={"border-[red] text-[red]"}
                                // loading={deleteArtworkLoading}
                                padding="lg:p-[10px_25px_10px_25px] p-[10px_10px_10px_10px]"
                              />
                            </div>
                          </div>
                        </div>
                      </Modal>
                    )}
                  </div>
                  <img
                    src={artwork.img_url}
                    alt=""
                    className="h-[30vh] w-full object-cover lg:h-[40vh] lg:w-[250px]"
                  />
                </div>
                {/*  */}
                <div className="pt-3">
                  <div>
                    <p className="font-heading text-[13px] tracking-normal lg:text-[15px]">
                      {artwork.name_of_artwork}
                    </p>
                  </div>
                  <div className="">
                    <div className="text-[9px] font-normal lg:text-[12px]">
                      By
                    </div>
                    <div className="text-[9px] font-normal tracking-wide lg:text-[12px]">
                      {artwork.artist_id === null
                        ? "No Name"
                        : artwork.artist_id.firstname}{" "}
                      {artwork.artist_id === null
                        ? "No Name"
                        : artwork.artist_id.lastname}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default RemoveArtWork;
