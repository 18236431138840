import React, { useState } from "react";
import Input from "../../../atoms/input/Input";
import LoginRegBtn from "../../../atoms/button/LoginRegBtn";

const ForgotPasswordSucessful = () => {
  return (
    <div className="h-[100vh] overflow-scroll bg-[#000000]">
      <div className="container mx-auto max-w-3xl px-4 py-10">
        <div>
          <h3 className="font-heading text-4xl text-white">
            Successful password Reset
          </h3>
          <p className="py-8 font-body text-xl text-white">
            You can now use your new password to log in into your account.
          </p>
        </div>
        {/* <div className='lg:mt-[48px]'>
          <form autoComplete='off'>
            <div className='lg:mt-[34px] w-[185px] lg:w-[899px]'>
              <Input
                inputType='email'
                inputName='email'
                inputPlaceholder='Email address'
                inputValue={logIn.firstName}
                onChange={onInputChange}
              />
            </div>
          </form>
        </div> */}
        <div className="mt-8 w-52">
          <LoginRegBtn buttonText="Back to login" padding="" />
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordSucessful;
