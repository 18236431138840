import React, { useState } from "react";
const Input = ({
  inputType,
  inputName,
  inputValue,
  inputPlaceholder,
  onChange,
  formik,
}) => {

  return (
    <>
      <div>
        <input
          type={inputType}
          name={inputName}
          placeholder={inputPlaceholder}
          onChange={onChange}
          // width: "600px", height: "60px",
          className="w-full p-[9px_0px_10px_16px] bg-[#0c0c0c] text-white placeholder-[#666257] border border-[#FFFFFF] text-md"
          autoComplete="off"
          {...formik}
        />
      </div>
    </>
  );
};

export default Input;
