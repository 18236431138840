import React from "react";
import AdminDashboardActivity from "../../Dashboard";
import AdminPainting from "../../molecule/adminPainting/AdminPainting";
import AdminDrawing from "../../molecule/adminDrawing/AdminDrawing";
import AdminSculpture from "../../molecule/adminSculpture/AdminSculpture";
const AllArtWork = () => {
  return (
    <div>
      <AdminDashboardActivity>
        <div className="mx-auto my-16">
          <div>
            <div>
              <h4 className="font-heading lg:text-[25px] lg:leading-[41px] mb-[12px] lg:mb-[30px] lg:mx-8">
                {/* Painting */}
                All artworks
              </h4>
            </div>
            {/*  */}
            <AdminPainting />
          </div>
          {/*  */}
          {/* <div>
            <div className="pt-10">
              <h4 className="font-heading lg:text-[25px] lg:leading-[41px] mb-[12px] lg:mb-[30px]">
                Drawing
              </h4>
            </div>
            <AdminDrawing />
          </div> */}
          {/*  */}
          {/* <div>
            <div className="pt-10">
              <h4 className="font-heading lg:text-[25px] lg:leading-[41px] mb-[12px] lg:mb-[30px]">
                Sculpture
              </h4>
            </div>
            <AdminSculpture />
          </div> */}
        </div>
      </AdminDashboardActivity>
    </div>
  );
};

export default AllArtWork;
