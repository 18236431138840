import React from "react";
import arrowTop from "../../assets/arrowTop.svg";
import backgroundImg from "../../assets/landingPage.png";
const HomeLandingPage = ({ smoothScroll }) => {
  return (
    <div className=" h-[50vh] lg:h-[100vh] relative">
      <img
        className="w-full absolute h-[50vh] lg:h-[100vh] object-cover"
        src={backgroundImg}
        alt=""
      />
      <div className="bg-[#FA9219] absolute w-[154px] bottom-0 left-6 lg:left-12 lg:w-[380px] 2xl:w-[497px] bg-opacity-70">
        <div className="p-[14px_17px_8px_17px] lg:p-[38px_38px_75px_38px] 2xl:p-[38px_38px_95px_38px]">
          <div className="text-[14px] leading-[13px] lg:text-[35px] 2xl:text-[48px] lg:leading-[50px] 2xl:leading-[62px] font-heading">
            Welcome to the world of ART
          </div>
          <div className="text-[12px] leading-[13px] lg:text-[25px] 2xl:text-[36px] lg:leading-[38px] pt-[8px] pb-[10px] lg:pt-[20px] lg:pb-[30px] font-body">
            Explore the story in every piece.
          </div>
          <div
            onClick={smoothScroll}
            className="flex items-center justify-between lg:w-[220px]"
          >
            <div className="text-[10px] leading-[10px] lg:text-[20px] 2xl:text-[32px] lg:leading-[0px] 2xl:leading-[48px] font-body">
              Visit gallery today
            </div>
            <div>
              <img
                src={arrowTop}
                alt="navigation"
                className="h-2 lg:h-[20px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeLandingPage;
