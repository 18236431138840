import React from "react";
import ArtistCardOne from "../../molecules/artistCardOne/ArtistCardOne";
import RhythmOfExpression from "../../molecules/rhythmOfExpression/RhythmOfExpression";
import ArtistCardTwo from "../../molecules/artistCardTwo/ArtistCardTwo";

const Painting = () => {
  return (
    <div>
      <ArtistCardOne />
      <div className='lg:mb-[180px] mb-[50px]'>
        <RhythmOfExpression
          backgroundImage={'bg-canvaImg'}
          bgImageBottomCenter={'lg:bg-[center]'}
        />
      </div>
      <div>
        <ArtistCardOne />
      </div>
      <div className='lg:mb-[100px] mb-[50px]'>
        <RhythmOfExpression left='left' backgroundImage={'bg-manWithLongEye'} />
      </div>
    </div>
  );
};

export default Painting;
