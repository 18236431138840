import {
  CREATE_ARTWORK_SUCCESS,
  GET_ARTWORK_SUCCESS,
  GET_ARTWORKS_SUCCESS,
} from "../types";

const initialState = () => ({
  artwork: {},
  artworks: [],
});

const artworkReducer = (state = initialState(), { type, payload }) => {
  switch (type) {
    case CREATE_ARTWORK_SUCCESS:
      return {
        ...state,
        artwork: payload.artwork,
      };
    case GET_ARTWORKS_SUCCESS:
      return {
        ...state,
        artworks: payload.artworks,
      };
    case GET_ARTWORK_SUCCESS:
      return {
        ...state,
        artwork: payload.artwork,
      };

    default:
      return state;
  }
};

export default artworkReducer;
