import React from "react";

const OrderCard = ({
  artName,
  artAmount,
  quantityNumber,
  artStatus,
  deliveryDate,
  orderImg,
}) => {
  return (
    <div className="grid grid-cols-3  p-4 shadow-md lg:h-[200px] ">
      <div className="col-span-2 flex gap-x-2">
        <img src={orderImg} alt="img" className="h-[140px] lg:h-[170px]" />
        <div className="flex flex-col justify-between max-w-[200px]">
          <div className="flex flex-col">
            <p className="font-heading text-xs lg:text-xl">{artName}</p>
            {/* <h4 className="text-xs">By {artAuthor}</h4> */}
          </div>

          <div className="">
            <p className="mt-[24px] lg:mt-[10px] text-[13px] lg:text-[15px] lg:leading-[20px] font-[500]">
              ₦ {artAmount}
            </p>
            {/* <div className="flex items-center justify-items-center gap-x-2">
                <div className="text-sm">Quantity</div>
                <div className=" w-4 h-4 lg:h-8 lg:w-8 border flex items-center  justify-center  border-black">
                  {quantityNumber}
                </div>
              </div> */}
          </div>
        </div>
      </div>

      {/*  */}

      <div className="grid col-span-1 justify-self-end lg:justify-self-start ">
        <div className="">
          <p className="text-xs">Status</p>
          <div className="text-[#FA9219] font-[500] lg:text-lg">
            {artStatus}
          </div>
        </div>

        {/* <div className="">
            <p className="text-xs">Delivery expected by</p>
            <div className="text-md lg:text-lg font-[500]">{deliveryDate}</div>
          </div> */}
      </div>
    </div>
  );
};

export default OrderCard;
