import React, { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { FormError } from "../../../components/formError";
// import LoginRegBtn from '../../../atoms/button/LoginRegBtn';
import ContactInput from "../../../atoms/input/ContactInput";
import { AdminFormBtn } from "../../../atoms/button/AdminFormBtn";
import { toast } from "react-toastify";
import axios from "axios";

const SinglePageArtistForm = ({ artwork }) => {
  const [loading, setLoading] = useState(false);
  const [successRes, setSuccessRes] = useState(null);
  const [errorRes, setErrorRes] = useState(null);

  const formik = useFormik({
    initialValues: {
      fullname: "",
      email: "",
      mobile: "",
      artname: "",
      artimg: "",
      art_id: "",
    },
    validationSchema: yup.object({
      fullname: yup
        .string()
        .required("full name is required")
        .max(32, "full name is too long")
        .trim(),
      email: yup
        .string()
        .email("Email is not valid")
        .required("Email is required"),
      mobile: yup.string().required("Phone number is required"),
    }),

    onSubmit: (prop) => {
      // setLoading(true);

      let newArray = [];

      prop.fullname.split(" ").forEach((str) => {
        let firstLetter = str.charAt(0);

        let firstLetterCap = firstLetter.toUpperCase();

        let remainingLetters = str.slice(1);

        const capitalizedWord = firstLetterCap + remainingLetters;

        str = capitalizedWord;

        newArray.push(str);
      });

      if (Object.keys(artwork).length === 0 && artwork.constructor === Object) {
        toast.error("No Artwork", {
          duration: 3000,
        });
      } else {
        prop.fullname = newArray.join(" ");
        prop.art_id = artwork._id;
        prop.artname = artwork.name_of_artwork;
        prop.artimg = artwork.img_url;

        let formData = prop;

        sendMail(formData);
      }
    },
  });

  const sendMail = async (formData) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `https://kachijamesgalleryapi.onrender.com/auth/sendmail`,
        formData
      );

      if (response.status === 200) {
        toast.success("Enquiry sent!", {
          duration: 3000,
        });
      }

      setLoading(false);
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        toast.error(message, {
          duration: 3000,
        });
      }

      setLoading(false);
    }
  };

  return (
    <div className="mx-auto max-w-3xl px-8 py-12">
      <div className="">
        <div>
          <p className="pb-2 text-xs">We can’t wait to hear from you!</p>
          <h3 className="text-xl">
            For More Information on Art Availability and Price{" "}
            <b>contact us.</b>
          </h3>
        </div>
        <form autoComplete="off">
          <div className=" mt-5 flex flex-col gap-y-2 ">
            <ContactInput
              inputType="text"
              inputName="fullName"
              inputPlaceholder="Full Name"
              formik={formik.getFieldProps("fullname")}
            />
            {formik.errors.fullname && formik.touched.fullname && (
              <FormError message={formik.errors.fullname} />
            )}
          </div>
          <div className="mt-5 flex flex-col gap-y-2">
            <ContactInput
              inputType="email"
              inputName="email"
              inputPlaceholder="Email address"
              formik={formik.getFieldProps("email")}
            />
            {formik.errors.email && formik.touched.email && (
              <FormError message={formik.errors.email} />
            )}
          </div>
          <div className="mt-5 flex flex-col gap-y-2">
            <ContactInput
              inputType="Phone"
              inputName="Phone"
              inputPlaceholder="Phone Number"
              formik={formik.getFieldProps("mobile")}
            />
            {formik.errors.mobile && formik.touched.mobile && (
              <FormError message={formik.errors.mobile} />
            )}
          </div>
          <div>
            <p className="text-md w-64 pt-4 lg:w-full">
              Hello, I would love to get this artwork, How do I go about it?
            </p>
          </div>
        </form>
      </div>
      <div className="mt-8 w-full">
        <AdminFormBtn
          action={formik.handleSubmit}
          loading={loading}
          buttonText="Talk to us"
          padding=""
        />
      </div>
    </div>
  );
};

export default SinglePageArtistForm;
