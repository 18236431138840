import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ArtistNavbar = ({ setActiveNav, activeNav }) => {
  const location = useLocation();
  //   const [activeNav, setActiveNav] = useState(1)
  const [navItems, setNavItems] = useState([]);

  const navbarLinkNames = [
    {
      name: "Painting",
      id: 1,
      nav: 1,
      to: "painting",
      active: location.pathname.includes("painting"),
    },
    {
      name: "Digital painting",
      id: 2,
      to: "digital-painting",
      nav: 2,
      active: location.pathname.includes("digital-painting"),
    },
    {
      name: "Sculpture",
      id: 3,
      to: "sculpture",
      nav: 3,
      active: location.pathname.includes("sculpture"),
    },
  ];

  const switchNav = (navId) => {
    setActiveNav(navId);
  };

  useEffect(() => {
    const path = navbarLinkNames.map((item) => {
      if (location.pathname.includes(item.to)) {
        setActiveNav(item.id);
      }
      return item;
    });
    setNavItems(path);
  }, [location.pathname]);
  return (
    <div>
      {/* absolute top-[-90px] transform translate-x-1/2 */}
      <div className="flex justify-center text-center lg:gap-10 bg-white p-[11px_9px] lg:p-[30px_10px] shadow-normal">
        {navItems.length > 0 &&
          navItems.map(({ name, id, to, nav, active }) => (
            <div
              key={id}
              className={`flex items-center justify-center pl-2 text-[6px] lg:text-[14px] font-medium font-heading ${
                activeNav === id ? "text-[#FA9219] underline" : ""
              } cursor-pointer`}
              onClick={() => switchNav(id)}
            >
              <span>{name}</span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ArtistNavbar;
