import React from 'react'
import dashboardNaira from '../../../../assets/dashboardNaira.svg'
const AdminTotalActivity = () => {
  return (
    <div className='flex justify-between flex-wrap gap-y-2 py-16'>
      <div>
        <div className='flex justify-between items-center'>
          <div>
            <img src={dashboardNaira} alt='' />
          </div>
          <div className='px-4'>
            <div>
              <p className='text-[#777369]'>Total Sales</p>
            </div>
            <div>
              <p className='text-[15px] font-bold font-heading'>#634 700.00</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className='flex justify-between items-center'>
          <div>
            <img src={dashboardNaira} alt='' />
          </div>
          <div className='px-4'>
            <div>
              <p className='text-[#777369]'>Total Sales</p>
            </div>
            <div>
              <p className='text-[15px] font-bold font-heading'>#634 700.00</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className='flex justify-between items-center'>
          <div>
            <img src={dashboardNaira} alt='' />
          </div>
          <div className='px-4'>
            <div>
              <p className='text-[#777369]'>Total Sales</p>
            </div>
            <div>
              <p className='text-[15px] font-bold font-heading'>#634 700.00</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminTotalActivity
