import {
  CLEAR_CREATE_ARTWORK_SUCCESS,
  CLEAR_DELETE_ARTWORK_SUCCESS,
  CREATE_ARTWORK_FAIL,
  CREATE_ARTWORK_LOADING,
  CREATE_ARTWORK_REQUEST,
  CREATE_ARTWORK_SUCCESS,
  DELETE_ARTWORK_FAIL,
  DELETE_ARTWORK_LOADING,
  DELETE_ARTWORK_REQUEST,
  DELETE_ARTWORK_SUCCESS,
  GET_ARTWORKS_FAIL,
  GET_ARTWORKS_LOADING,
  GET_ARTWORKS_REQUEST,
  GET_ARTWORKS_SUCCESS,
  GET_ARTWORK_FAIL,
  GET_ARTWORK_LOADING,
  GET_ARTWORK_REQUEST,
  GET_ARTWORK_SUCCESS,
} from "../types";

export const createArtworkRequest = (prop) => {
  return {
    type: CREATE_ARTWORK_REQUEST,
    payload: prop,
  };
};

export const createArtworkSuccess = (prop) => {
  return {
    type: CREATE_ARTWORK_SUCCESS,
    payload: prop,
  };
};

export const createArtworkFailure = (errors) => {
  return {
    type: CREATE_ARTWORK_FAIL,
    payload: { errors },
  };
};

export const createArtworkLoading = (loading) => {
  return {
    type: CREATE_ARTWORK_LOADING,
    payload: loading,
  };
};

export const clearCreateArtworkSuccess = (prop) => {
  return {
    type: CLEAR_CREATE_ARTWORK_SUCCESS,
    payload: prop,
  };
};

export const getArtworksRequest = (prop) => {
  return {
    type: GET_ARTWORKS_REQUEST,
    payload: prop,
  };
};

export const getArtworksSuccess = (prop) => {
  return {
    type: GET_ARTWORKS_SUCCESS,
    payload: prop,
  };
};

export const getArtworksFailure = (errors) => {
  return {
    type: GET_ARTWORKS_FAIL,
    payload: { errors },
  };
};

export const getArtworksLoading = (loading) => {
  return {
    type: GET_ARTWORKS_LOADING,
    payload: loading,
  };
};

export const getArtworkRequest = (prop) => {
  return {
    type: GET_ARTWORK_REQUEST,
    payload: prop,
  };
};

export const getArtworkSuccess = (prop) => {
  return {
    type: GET_ARTWORK_SUCCESS,
    payload: prop,
  };
};

export const getArtworkFailure = (errors) => {
  return {
    type: GET_ARTWORK_FAIL,
    payload: { errors },
  };
};

export const getArtworkLoading = (loading) => {
  return {
    type: GET_ARTWORK_LOADING,
    payload: loading,
  };
};

// delete artwork
export const deleteArtworkRequest = (prop) => {
  return {
    type: DELETE_ARTWORK_REQUEST,
    payload: prop,
  };
};

export const deleteArtworkSuccess = (prop) => {
  return {
    type: DELETE_ARTWORK_SUCCESS,
    payload: prop,
  };
};

export const deleteArtworkFailure = (errors) => {
  return {
    type: DELETE_ARTWORK_FAIL,
    payload: { errors },
  };
};

export const deleteArtworkLoading = (loading) => {
  return {
    type: DELETE_ARTWORK_LOADING,
    payload: loading,
  };
};

export const clearDeleteArtworkSuccess = (prop) => {
  return {
    type: CLEAR_DELETE_ARTWORK_SUCCESS,
    payload: prop,
  };
};