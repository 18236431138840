import { combineReducers } from 'redux';
import userData from './auth';

import artworkData from './artwork';
import orderData from './order';
import artistData from './artist';

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import loadingIndicator from './loadingIndicator';
import ajaxStatuses from './ajaxStatuses';

const authPersistConfig = {
  key: 'userData',
  storage,
  whitelist: ['isLoggedIn', 'token', 'user'],
};

export default combineReducers({
  userData: persistReducer(authPersistConfig, userData),
  artworkData,
  artistData,
  orderData,
  loadingIndicator,
  ajaxStatuses,
});
