import React from 'react'
import img5 from '../../assets/photo(1).png'


const ArtAuction = ({ children }) => {
  return (
    <div>
      <div className='container relative lg:py-2 mx-auto max-w-5xl'>
        <div>
          <div>
            <div className='w-full'>
              <div className=''>
                <div className='relative'>
                  <div>
                    <img
                      src={img5}
                      alt=''
                      className='w-full h-[65vh] md:h-[40vh] lg:w-full lg:h-[75vh] object-cover'
                    />
                  </div>
                  <div>{children}</div>
                </div>
              </div>
              {/* <div className='grid grid-cols-2 lg:grid-cols-1'>
                <div className='lg:mb-4'>
                  <div className='relative'>
                    <img
                      src={upcoming2}
                      alt=''
                      className='w-full h-[35vh] lg:w-[500px] lg:h-[100vh] object-cover'
                    />
                    <div className='bg-[#100F0E] text-end text-white bg-opacity-60 absolute bottom-0 h-[30%] lg:h-[40%] lg:w-[500px] w-full'>
                      <div className='absolute bottom-0 h-[120px] lg:h-[200px] left-[30px]'>
                        <p className='font-heading text-[20px] lg:text-[30px] tracking-normal text-left lg:w-[300px] font-extrabold lg:leading-[35px]'>
                          Gallery opening
                        </p>
                        <div className='text-[13px] lg:text-[18px] font-semibold tracking-wide text-left pt-[10px] lg:pt-[22px]'>
                          December 27, 2023.
                        </div>
                        <div className='absolute left-[320px] right-0'>
                          <div className='border border-[#FA9219] text-[14px] text-[#FA9219] text-center p-2 w-[130px] font-heading'>
                            Join Now
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArtAuction
