import React, { useRef } from "react";
import ProfileDashboard from "../../ProfileDashboard";
import ProfileForm from "../profileForm/ProfileForm";
import ProfileDetails from "../profileDetails/ProfileDetails";

const Profile = () => {
  const ref = useRef(null);

  const smoothScroll = (page) => {
    const element = ref.current;

    element.scrollIntoView({
      top: -800,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <ProfileDashboard>
        <div className="max-w-5xl mx-auto lg:px-16 lg:my-16 mb-[300px]">
          <h4 className="hidden lg:flex font-heading lg:text-[25px] lg:leading-[41px] mb-[12px] lg:mb-[20px] text-white bg-black py-5 px-8">
            Profile
          </h4>

          {/*  */}
          <div className="pt-7 flex flex-col gap-y-10 ">
            <div className="">
              <ProfileDetails smoothScroll={smoothScroll} />
            </div>
            <div ref={ref} className="">
              <ProfileForm />
            </div>
          </div>
          {/*  */}
        </div>
      </ProfileDashboard>
    </div>
  );
};

export default Profile;
