import React, { useEffect } from "react";
// import arrowRight from "../../assets/arrow right artist.png";
// import arrowLeft from "../../assets/arrow left.png";
import { ArtCard } from "../../components/card/ArtCard";
import { useDispatch, useSelector } from "react-redux";
import { getArtworksRequest } from "../../redux/action";
import { CircularProgress } from "@material-ui/core";

const ArtistCardOne = () => {
  const dispatch = useDispatch();

  const {
    artworks,
    getArtworksLoading,
    getArtworksError,
    registerSuccess,
    user,
  } = useSelector((state) => {
    const {
      errors: { getArtworks: getArtworksError },
    } = state.ajaxStatuses;

    const { getArtworksLoading } = state.loadingIndicator;

    const { user } = state.userData;
    const { artworks } = state.artworkData;

    return {
      artworks,
      getArtworksLoading,
      getArtworksError,
      user,
    };
  });

  useEffect(() => {
    dispatch(getArtworksRequest());
  }, [getArtworksRequest]);

  return (
    <div className="container max-w-[940px] mx-auto pb-[51px] lg:pb-[130px] px-4">
      {/* lg:pl-[66px] lg:pr-[66px] */}

      {getArtworksLoading ? (
        <div className="flex items-center justify-center lg:h-[550px]">
          <CircularProgress size={20} style={{ color: "black" }} />
        </div>
      ) : (
        <div className="grid grid-cols-2 items-center justify-center gap-4  mb-[3px] lg:mb-[14px]">
          {artworks.map((artwork, index) => (
            <ArtCard key={index} artwork={artwork} />
          ))}
        </div>
      )}
    </div>
  );
};

export default ArtistCardOne;
