import React, { useEffect, useState } from "react";
import ProfileDashboard from "../../ProfileDashboard";
import ProfileOrderNavbar from "../profileOrderNavbar/ProfileOrderNavbar";
import CurrentOrder from "../../molecule/currentOrder/CurrentOrder";
import OrderHistory from "../../molecule/orderHistory/OrderHistory";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserOrderRequest } from "../../../../redux/action";

const ProfileOrder = () => {
  const [activeNav, setActiveNav] = useState(1);

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    getUserOrderSuccess,
    getUserOrderError,
    getUserOrderLoading,
    orders,
    user,
  } = useSelector((state) => {
    const {
      success: { getUserOrder: getUserOrderSuccess },
      errors: { getUser: getUserError, getUserOrder: getUserOrderError },
    } = state.ajaxStatuses;

    const { getUserOrderLoading, getUserLoading } = state.loadingIndicator;

    const { user } = state.userData;
    const { orders } = state.orderData;

    return {
      getUserLoading,
      getUserError,
      getUserOrderSuccess,
      getUserOrderError,
      getUserOrderLoading,
      orders,
      user,
    };
  });

  useEffect(() => {
    dispatch(getUserOrderRequest({ userId: user._id }));
  }, [getUserOrderRequest]);

  return (
    <div>
      <ProfileDashboard>
        <div className="container max-w-5xl mx-auto lg:px-16 lg:my-16 mb-[300px]">
          <h4 className="hidden lg:flex font-heading lg:text-[25px] lg:leading-[41px] mb-[12px] lg:mb-[20px] text-white bg-black py-5 px-8">
            Orders
          </h4>

          {/*  */}
          <div className="pt-3">
            <div>
              <div className="">
                <ProfileOrderNavbar
                  setActiveNav={setActiveNav}
                  activeNav={activeNav}
                />
              </div>
            </div>
            <div>
              <div className="lg:pt-[0px] pt-[51px]">
                {activeNav === 1 ? (
                  <CurrentOrder loading={getUserOrderLoading} orders={orders} />
                ) : activeNav === 2 ? (
                  <OrderHistory loading={getUserOrderLoading} orders={orders} />
                ) : null}
              </div>
            </div>
          </div>
          {/*  */}
        </div>
      </ProfileDashboard>
    </div>
  );
};

export default ProfileOrder;
