import {
  CREATE_ARTWORK_LOADING,
  CREATE_ORDER_LOADING,
  DELETE_ARTIST_LOADING,
  GET_ARTISTS_LOADING,
  GET_ARTWORKS_LOADING,
  GET_ARTWORK_LOADING,
  GET_USER_ORDER_LOADING,
  LOGIN_LOADING,
  REGISTER_ARTIST_LOADING,
  REGISTER_LOADING,
  UPDATE_USER_LOADING,
  GET_SINGLE_ARTIST_LOADING,
} from "../types";

const initialState = {
  registerLoading: false,
  registerArtistLoading: false,
  loginLoading: false,
  createArtworkLoading: false,
  createOrderLoading: false,
  updateUserLoading: false,
  getArtworksLoading: true,
  getArtworkLoading: true,
  getUserOrderLoading: true,
  getArtistsLoading: true,
  getSingleArtistLoading:true,
  deleteArtistLoading:true,
};

const loadingIndicator = (state = initialState, { type, payload }) => {
  switch (type) {
    // User loading state
    case REGISTER_LOADING:
      return {
        ...state,
        registerLoading: payload,
      };
    case LOGIN_LOADING:
      return {
        ...state,
        loginLoading: payload,
      };
    case UPDATE_USER_LOADING:
      return {
        ...state,
        updateUserLoading: payload,
      };

    //Artwork loading state
    case CREATE_ARTWORK_LOADING:
      return {
        ...state,
        createArtworkLoading: payload,
      };

    //Artist state
    case REGISTER_ARTIST_LOADING:
      return {
        ...state,
        registerArtistLoading: payload,
      };

    case GET_ARTWORKS_LOADING:
      return {
        ...state,
        getArtworksLoading: payload,
      };
    case GET_ARTWORK_LOADING:
      return {
        ...state,
        getArtworkLoading: payload,
      };

    //Order loading states
    case CREATE_ORDER_LOADING:
      return {
        ...state,
        createOrderLoading: payload,
      };
    case GET_USER_ORDER_LOADING:
      return {
        ...state,
        getUserOrderLoading: payload,
      };
    // get artists loading state
    case GET_ARTISTS_LOADING:
      return {
        ...state,
        getArtistsLoading: payload,
      };
    // get single artist loading state
    case GET_SINGLE_ARTIST_LOADING:
      return {
        ...state,
        getSingleArtistLoading: payload,
      };
    // delete artist loading state
    case DELETE_ARTIST_LOADING:
      return {
        ...state,
        deleteArtistLoading: payload,
      };
    default:
      return state;
  }
};

export default loadingIndicator;
