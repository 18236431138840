import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { getArtworksRequest } from "../../../../redux/action";
import RemoveArtWork from "../removeAllArtWork/RemoveArtWork";
import adminImg from "../../../../assets/Rectangle2.png";

const AdminPainting = () => {
  const dispatch = useDispatch();

  const {
    artworks,
    getArtworksLoading,
    getArtworksError,
    registerSuccess,
    user,
  } = useSelector((state) => {
    const {
      errors: { getArtworks: getArtworksError },
    } = state.ajaxStatuses;

    const { getArtworksLoading } = state.loadingIndicator;

    const { user } = state.userData;
    const { artworks } = state.artworkData;
    return {
      artworks,
      getArtworksLoading,
      getArtworksError,
      user,
    };
  });

  useEffect(() => {
    dispatch(getArtworksRequest());
  }, [getArtworksRequest]);
  return (
    <div>
      <RemoveArtWork
        getArtworksLoading={getArtworksLoading}
        artworks={artworks}
      />
      {/* Reuseable component */}
      {/* <div className='flex flex-row items-center justify-between my-14'>
        <div>
          <div>
            <img src={arrowLeft} alt='' className='w-[25px] h-[25px]' />
          </div>
        </div>
        <div className='flex flex-row items-center justify-between'>
          <div className='mr-8'>
            <p className='font-heading'>Select All</p>
          </div>
          <div>
            <ContactButton
              buttonText={'Upload'}
              padding='lg:p-[10px_25px_10px_25px] p-[10px_10px_10px_10px]'
            />
          </div>
        </div>
      </div> */}
      {/*  */}
      {/* <div>
        <div className='border border-[#E3E2DE] w-[120px] shadow-inner text-center p-1 mb-6'>
          <p className='text-[13px]'>Painting</p>
        </div>
      </div> */}

      {/* <div className="mt-20 grid grid-cols-4 gap-5 lg:mx-8"> */}
      {/* {getArtworksLoading ? (
          <div className="absolute left-[40%] mx-auto my-11 flex items-center justify-center">
            <div className="">
              <CircularProgress size={20} color={"inherit"} />
            </div>
          </div>
        ) : artworks.length === 0 ? (
          <div>
            <div
              colSpan="3"
              className="my-11 p-4 text-center font-heading text-xl"
            >
              No artwork here
            </div>
          </div>
        ) : (
          artworks.map((artwork, index) => (
            <div key={index} className="col-span-12 md:col-span-2">
              <div className="mb-[0px] flex h-[30vh] items-center gap-[20px] pr-[20px] shadow-md md:h-[40vh] lg:mb-[0px] lg:mt-[0px]">
                <div>
                  <img
                    src={artwork.img_url}
                    alt="img"
                    className="h-[30vh] w-full md:h-[40vh] lg:min-w-[220px]"
                  />
                </div>
                <div className="">
                  <div>
                    <div className="flex items-baseline justify-between text-[26px] leading-[30px] lg:max-w-[400px] lg:text-[70px] lg:leading-[25px]">
                      <div className="">
                        <div>
                          <p className="font-heading text-base tracking-normal lg:text-[15px]">
                            {artwork.name_of_artwork}
                          </p>
                        </div>
                        <div className="flex text-center">
                          <div className="text-[9px] font-normal lg:text-[15px]">
                            By
                          </div>

                          <div className="pl-2 text-[9px] font-normal lg:text-[15px]">
                            {artwork.artist_id === null
                              ? "No Name"
                              : artwork.artist_id.firstname}{" "}
                            {artwork.artist_id === null
                              ? "No Name"
                              : artwork.artist_id.lastname}
                          </div>
                        </div>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          name="checkbox"
                          className="h-[20px] border-2 border-[#100F0E]"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="mt-[24px] max-w-[100%] text-sm lg:mt-[10px] lg:text-base lg:leading-[20px]">
                      <p className="">{artwork.description} </p>
                    </div>
                  </div>
                  <div>
                    <div>
                      <p className="py-[10px] font-heading text-[18px] font-bold">
                        {artwork.price
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
        <div className="col-span-2"> */}
      {/* <div className="mb-[0px] flex h-[52vh] items-center gap-[20px] pr-[20px] shadow-md lg:mb-[0px] lg:mt-[0px]">
        <div>
          <img src={adminImg} alt="img" className="h-[350px] min-w-[220px]" />
        </div>
        <div className="">
          <div>
            <div className="flex items-baseline justify-between text-[26px] leading-[30px] lg:max-w-[400px] lg:text-[70px] lg:leading-[25px]">
              <div className="">
                <div>
                  <p className="font-heading text-[13px] tracking-normal lg:text-[15px]">
                    Shameful Pain
                  </p>
                </div>
                <div className="flex text-center">
                  <div className="text-[9px] font-normal lg:text-[15px]">
                    By
                  </div>
                  <div className="pl-2 text-[9px] font-normal tracking-wide lg:text-[15px]">
                    Gozie Adewale
                  </div>
                </div>
              </div>
              <div>
                <input
                  type="checkbox"
                  name="checkbox"
                  className="h-[20px] border-2 border-[#100F0E]"
                />
              </div>
            </div>
          </div>
          <div>
            <div className="mt-[24px] max-w-[100%] text-[13px] lg:mt-[10px] lg:text-[15px] lg:leading-[20px]">
              <p className="">
                In this captivating masterpiece, we are transported to an epic
                universe of romance, where love and nature intertwine in a
                harmonious dance. Each brushstroke and color palette are a
                celebration of these timeless forces, weaving a narrative that
                transcends time and space.
              </p>
            </div>
          </div>
          <div>
            <div>
              <p className="py-[10px] font-heading text-[18px] font-bold">
                #80,000
              </p>
            </div>
          </div>
        </div>
      </div> */}
      {/* </div> */}
      {/*  */}
      {/* <div className="col-span-2">
          <div className="mb-[0px] flex h-[52vh] items-center gap-[20px] pr-[20px] shadow-md lg:mb-[0px] lg:mt-[0px]">
            <div>
              <img
                src={adminImg}
                alt="img"
                className="h-[350px] min-w-[220px]"
              />
            </div>
            <div className="">
              <div>
                <div className="flex items-baseline justify-between text-[26px] leading-[30px] lg:max-w-[400px] lg:text-[70px] lg:leading-[25px]">
                  <div className="">
                    <div>
                      <p className="font-heading text-[13px] tracking-normal lg:text-[15px]">
                        Shameful Pain
                      </p>
                    </div>
                    <div className="flex text-center">
                      <div className="text-[9px] font-normal lg:text-[15px]">
                        By
                      </div>
                      <div className="pl-2 text-[9px] font-normal tracking-wide lg:text-[15px]">
                        Gozie Adewale
                      </div>
                    </div>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="checkbox"
                      className="h-[20px] border-2 border-[#100F0E]"
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="mt-[24px] max-w-[100%] text-[13px] lg:mt-[10px] lg:text-[15px] lg:leading-[20px]">
                  <p className="">
                    In this captivating masterpiece, we are transported to an
                    epic universe of romance, where love and nature intertwine
                    in a harmonious dance. Each brushstroke and color palette
                    are a celebration of these timeless forces, weaving a
                    narrative that transcends time and space.
                  </p>
                </div>
              </div>
              <div>
                <div>
                  <p className="py-[10px] font-heading text-[18px] font-bold">
                    #80,000
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      {/*  */}
      {/* <div className="col-span-2">
          <div className="mb-[0px] flex h-[52vh] items-center gap-[20px] pr-[20px] shadow-md lg:mb-[0px] lg:mt-[0px]">
            <div>
              <img
                src={adminImg}
                alt="img"
                className="h-[350px] min-w-[220px]"
              />
            </div>
            <div className="">
              <div>
                <div className="flex items-baseline justify-between text-[26px] leading-[30px] lg:max-w-[400px] lg:text-[70px] lg:leading-[25px]">
                  <div className="">
                    <div>
                      <p className="font-heading text-[13px] tracking-normal lg:text-[15px]">
                        Shameful Pain
                      </p>
                    </div>
                    <div className="flex text-center">
                      <div className="text-[9px] font-normal lg:text-[15px]">
                        By
                      </div>
                      <div className="pl-2 text-[9px] font-normal tracking-wide lg:text-[15px]">
                        Gozie Adewale
                      </div>
                    </div>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="checkbox"
                      className="h-[20px] border-2 border-[#100F0E]"
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="mt-[24px] max-w-[100%] text-[13px] lg:mt-[10px] lg:text-[15px] lg:leading-[20px]">
                  <p className="">
                    In this captivating masterpiece, we are transported to an
                    epic universe of romance, where love and nature intertwine
                    in a harmonious dance. Each brushstroke and color palette
                    are a celebration of these timeless forces, weaving a
                    narrative that transcends time and space.
                  </p>
                </div>
              </div>
              <div>
                <div>
                  <p className="py-[10px] font-heading text-[18px] font-bold">
                    #80,000
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      {/*  */}
    </div>
  );
};

export default AdminPainting;
