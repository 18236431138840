import React from "react";
import AdminDashboardActivity from "../../Dashboard";
import { CreateArtworkForm } from "../../molecule/adminForm/CreateArtworkForm";

const CreateArtwork = () => {
  return (
    <div>
      <AdminDashboardActivity>
        <div className="container max-w-4xl  my-16 px-4">
          <h4 className="font-heading text-xl ">Create Artwork</h4>
          <CreateArtworkForm />
        </div>
      </AdminDashboardActivity>
    </div>
  );
};

export default CreateArtwork;
