import React from 'react'

const NotificationTableHead = ({ headers4, children }) => {
  return (
    <>
      <thead>
        {headers4
          ? headers4.map((header, idx) => {
              return (
                <th
                  scope='col'
                  key={idx}
                  className='text-start border-b-2 p-4 bg-black text-[15px] text-white'
                  style={{
                    width: header.width,
                  }}
                >
                  {header.title}
                </th>
              )
            })
          : null}
      </thead>
      <tbody className=''>{children}</tbody>
    </>
  )
}

export default NotificationTableHead
