import { CircularProgress } from "@material-ui/core";
import React from "react";

export const AdminFormBtn = ({ buttonText, action, loading }) => {
  return (
    <button
      onClick={action}
      type="button"
      className={`button border border-[#FFFFFF] text-white bg-black font-heading w-full h-10 text-md`}
    >
      {loading ? (
        <div>
          <CircularProgress size={20} color={"inherit"} />
        </div>
      ) : (
        buttonText
      )}
    </button>
  );
};
