import React, { useState } from "react";
import Input from "../../../atoms/input/Input";
import LoginRegBtn from "../../../atoms/button/LoginRegBtn";

const ResetPassword = () => {
  return (
    <div className="h-[100vh] overflow-scroll bg-[#000000]">
      <div className="container mx-auto max-w-3xl px-4 py-10">
        <div>
          <h3 className="font-heading text-4xl text-white">Rest password</h3>
          <p className="py-8 font-body text-xl text-white">
            Enter your new password
          </p>
        </div>
        <div className="">
          <form autoComplete="off">
            <div className="mt-5 flex flex-col gap-y-2">
              <Input
                inputType="password"
                inputName="password"
                inputPlaceholder="New password"
              />
            </div>
            <div className="mt-5 flex flex-col gap-y-2">
              <Input
                inputType="password"
                inputName="password"
                inputPlaceholder="Confirm password"
              />
            </div>
          </form>
        </div>
        <div className="mt-8 w-52">
          <LoginRegBtn buttonText="Reset password" padding="" />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
