import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as yup from "yup";
import Input from "../../../atoms/input/Input";
import LoginRegBtn from "../../../atoms/button/LoginRegBtn";
import {
  clearRegisterSuccess,
  registerRequest,
} from "../../../redux/action/auth";
import { FormError } from "../../../components/formError";
import AuthNavbar from "../../../components/navbar/AuthNavbar";

const Register = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const {
    getUserLoading,
    getUserError,
    registerSuccess,
    registerError,
    registerLoading,
    user,
    singleUser,
  } = useSelector((state) => {
    const {
      success: { register: registerSuccess },
      errors: { getUser: getUserError, register: registerError },
    } = state.ajaxStatuses;

    const { registerLoading, getUserLoading } = state.loadingIndicator;

    const { user, singleUser } = state.userData;

    return {
      getUserLoading,
      getUserError,
      registerSuccess,
      registerError,
      registerLoading,
      user,
      singleUser,
    };
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      firstname: "",
      lastname: "",
      mobile: "",
      email: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: yup.object({
      title: yup.string().required("Title is required"),
      firstname: yup
        .string()
        .required("first name is required")
        .max(32, "first name is too long")
        .trim()
        .matches(
          /^[a-zA-Z]+$/,
          "first name must be alphabetic without spacing"
        ),
      lastname: yup
        .string()
        .required("last name is required")
        .max(32, "last name is too long")
        .trim()
        .matches(/^[a-zA-Z]+$/, "last name must be alphabetic without spacing"),
      email: yup
        .string()
        .email("Email is not valid")
        .required("Email is required"),
      mobile: yup.string().required("Phone number is required"),
      password: yup.string().required("Password is required"),
    }),

    onSubmit: (prop) => {
      if (prop.password !== prop.password_confirmation) {
        toast.error("Password does not match");
      } else {
        let formData = prop;
        dispatch(registerRequest({ formData }));
      }
    },
  });

  useEffect(() => {
    if (registerError) {
      toast.error(registerError, {
        duration: 3000,
      });
    }
  }, [registerError]);

  useEffect(() => {
    if (registerSuccess) {
      history.push("/dashboard/profile");
      dispatch(clearRegisterSuccess());
    }
  }, [registerSuccess]);

  return (
    <div className="bg-[#0c0c0c]">
      <AuthNavbar />
      <div className="container mx-auto max-w-3xl px-4 py-10">
        <div>
          <h3 className="font-heading text-2xl text-white lg:text-4xl">
            Welcome to Kachi James Art Gallery
          </h3>
          <p className="py-4 font-body text-lg text-white lg:py-8 lg:text-xl">
            Sign up for updates of exhibitions, art events, and art pieces.
          </p>
        </div>
        <div>
          <div>
            <form>
              <div className="flex flex-col gap-y-2">
                <select
                  name="title"
                  className="text-md h-full w-[100%] border border-[#FFFFFF] bg-[#0c0c0c] p-[9px_10px_10px_16px] pr-10 text-white placeholder-[#666257]"
                  {...formik.getFieldProps("title")}
                >
                  <option>Select Title</option>
                  <option value={"Master"}>Master</option>
                  <option value={"Mr"}>Mr</option>
                  <option value={"Mister"}>Mister</option>
                  <option value={"Miss"}>Miss</option>
                  <option value={"Mrs"}>Mrs</option>
                  <option value={"Mistress"}>Mistress</option>
                  <option value={"Sir"}>Sir</option>
                </select>
                {formik.errors.title && formik.touched.title && (
                  <FormError message={formik.errors.title} />
                )}
              </div>
              <div className=" mt-5 flex flex-col gap-y-2 ">
                <Input
                  inputType="text"
                  inputName="firstName"
                  inputPlaceholder="First Name"
                  formik={formik.getFieldProps("firstname")}
                />
                {formik.errors.firstname && formik.touched.firstname && (
                  <FormError message={formik.errors.firstname} />
                )}
              </div>
              <div className="mt-5 flex flex-col gap-y-2 ">
                <Input
                  inputType="text"
                  inputName="lastName"
                  inputPlaceholder="Last Name"
                  formik={formik.getFieldProps("lastname")}
                />
                {formik.errors.lastname && formik.touched.lastname && (
                  <FormError message={formik.errors.lastname} />
                )}
              </div>
              <div className="mt-5 flex flex-col gap-y-2 ">
                <Input
                  inputType="email"
                  inputName="email"
                  inputPlaceholder="Email address"
                  formik={formik.getFieldProps("email")}
                />
                {formik.errors.email && formik.touched.email && (
                  <FormError message={formik.errors.email} />
                )}
              </div>
              <div className="mt-5 flex flex-col gap-y-2">
                <Input
                  inputType="Phone"
                  inputName="Phone"
                  inputPlaceholder="Phone"
                  formik={formik.getFieldProps("mobile")}
                />
                {formik.errors.mobile && formik.touched.mobile && (
                  <FormError message={formik.errors.mobile} />
                )}
              </div>
              <div className="mt-5 flex flex-col gap-y-2 ">
                <Input
                  inputType="password"
                  inputName="password"
                  inputPlaceholder="Password"
                  formik={formik.getFieldProps("password")}
                />
                {formik.errors.password && formik.touched.password && (
                  <FormError message={formik.errors.password} />
                )}
              </div>
              <div className="mt-5 flex flex-col gap-y-2 ">
                <Input
                  inputType="password"
                  inputName="password2"
                  inputPlaceholder="Confirm password"
                  formik={formik.getFieldProps("password_confirmation")}
                />
                {formik.errors.password_confirmation &&
                  formik.touched.password_confirmation && (
                    <FormError message={formik.errors.password_confirmation} />
                  )}
              </div>
            </form>

            <Link to="/login">
              <p className="mt-4 text-sm text-white">
                Already have an account? Login
              </p>
            </Link>
          </div>
          <div className="mt-8 w-40">
            <LoginRegBtn
              action={formik.handleSubmit}
              loading={registerLoading}
              buttonText="Sign Up"
              padding=""
            />
          </div>
          <div className="mt-8 text-sm text-[#A8A599]">
            <p>
              By submitting this form you are agreeing to our
              <b> terms & conditions.</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
