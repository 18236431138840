import React from 'react'
import eventImg4 from '../../assets/studio.jpg';
import upcoming1 from '../../assets/upcoming1.png'
import upcoming2 from '../../assets/manevent.png'

const Events = () => {
  return (
    <div>
      <div className='container relative px-2 lg:px-0 lg:pt-10 mx-auto max-w-5xl'>
        <div>
          <div>
            <div className='grid grid-cols-2 gap-2 lg:gap-4 w-full'>
              <div className='col-span-1'>
                <div className='relative'>
                  <div>
                    <img
                      src={upcoming1}
                      alt='img'
                      className='w-full h-[48vh] lg:w-[500px] lg:h-[90vh] object-cover'
                    />
                  </div>

                  <div className='bg-[#100F0E] lg:text-end text-white bg-opacity-60 absolute bottom-0 h-[45%] lg:h-[40%] md:h-[37%] lg:w-[500px] w-full'>
                    <div className='absolute bottom-0 h-[120px] md:h-[150px] lg:h-[200px] left-[10px] lg:left-[30px]'>
                      <p className='font-heading text-[12px] md:text-[20px] lg:text-[30px] tracking-normal text-left w-full lg:w-[300px] font-extrabold lg:leading-[35px]'>
                        The Unveiling of the ARTIST
                      </p>
                      <div className='text-[10px] lg:text-[18px] font-semibold tracking-wide text-left pt-[10px] lg:pt-[22px]'>
                        December 27, 2023.
                      </div>
                      <div className='absolute lg:left-[350px] right-3 lg:right-0 pt-3 lg:pt-[0px]'>
                        <div className='border border-[#FA9219] text-[10px] lg:text-[14px] text-[#FA9219] text-center p-1 lg:p-2 w-[70px] lg:w-[100px] font-heading'>
                          Closed
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className='col-span-1'>
                <div className='lg:mb-4'>
                  <div className='relative'>
                    <img
                      src={upcoming2}
                      alt='img'
                      className='w-full h-[48vh] lg:w-[500px] lg:h-[90vh] object-cover'
                    />
                    <div className='bg-[#100F0E] lg:text-end text-white bg-opacity-60 absolute bottom-0 h-[45%] lg:h-[40%] md:h-[37%] lg:w-[500px] w-full'>
                      <div className='absolute bottom-0 h-[120px] md:h-[150px] lg:h-[200px] left-[10px] lg:left-[30px]'>
                        <p className='font-heading text-[12px] md:text-[20px] lg:text-[30px] tracking-normal text-left w-full lg:w-[300px] font-extrabold lg:leading-[35px]'>
                          The Unveiling of the ARTIST
                        </p>
                        <div className='text-[10px] lg:text-[18px] font-semibold tracking-wide text-left pt-[10px] lg:pt-[22px]'>
                          December 27, 2023.
                        </div>
                        <div className='absolute lg:left-[350px] right-3 lg:right-0 pt-3 lg:pt-[0px]'>
                          <div className='border border-[#FA9219] text-[10px] lg:text-[14px] text-[#FA9219] text-center p-1 lg:p-2 w-[70px] lg:w-[100px] font-heading'>
                            Closed
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*Card 2  */}
          <div className='grid grid-cols-9 lg:grid-cols-9 mb-[50px] lg:mb-[100px] mt-2 lg:mt-[4px]'>
            <div className='col-span-4'>
              <img
                src={eventImg4}
                alt='art'
                className='h-[30vh] lg:h-[55vh] w-full object-cover'
              />
            </div>
            {/* w-[500px] h-[625px] */}
            <div className='col-span-5'>
              <div className='bg-[#100F0E] text-end text-white pl-[20px] lg:pl-[60px] h-[30vh] lg:h-[55vh] pt-[65px] md:pt-[120px] lg:pt-[80px] pb-[37px]'>
                {/* p-[185px_313px_0px_127px] */}
                <div>
                  <p className='font-heading text-[13px] md:text-[20px] lg:text-[30px] tracking-normal text-left w-full md:w-[250px] lg:leading-[40px] font-extrabold'>
                    The Unveiling of the ARTIST
                  </p>
                </div>
                <div className='pt-[18px] lg:pt-[35px]'>
                  <div className='text-[10px] lg:text-[18px] font-semibold tracking-wide text-left pt-[10px] lg:pt-[22px]'>
                    December 27, 2023.
                  </div>
                </div>
                <div className='absolute pt-3 lg:pt-0 right-5'>
                  <div className='border border-[#FA9219] text-[10px] lg:text-[14px] text-[#FA9219] text-center p-1 lg:p-2 w-[100px] lg:w-[130px] font-heading'>
                    Join Now
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Events
