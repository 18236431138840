import { CircularProgress } from "@material-ui/core";
import React from "react";

const LoginRegBtn = ({ buttonText, width, padding, action, loading }) => {
  return (
    <button
      onClick={action}
      type="button"
      className={`button border border-[#FFFFFF] text-white font-heading w-full h-10 text-md`}
      // style={{ width: `${width}%` }}
    >
      {loading ? (
        <div>
          <CircularProgress size={20} color={"inherit"} />
        </div>
      ) : (
        buttonText
      )}
    </button>
  );
};

export default LoginRegBtn;
