import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { logout } from "../../../../redux/action";
import { useDispatch } from "react-redux";

const ProfileSideNavbar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [activeNav, setActiveNav] = useState(1);
  const [navItems, setNavItems] = useState([]);
  const navbarLinkNames = [
    {
      name: "Profile",
      id: 1,
      to: "profile",
      active: location.pathname.includes("dashboard"),
    },
    {
      name: "Orders",
      id: 2,
      to: "orders",
      active: location.pathname.includes("dashboard"),
    },
  ];
  useEffect(() => {
    const path = navbarLinkNames.map((item) => {
      if (location.pathname.includes(item.to)) {
        setActiveNav(item.id);
      }
      return item;
    });
    setNavItems(path);
  }, [location.pathname]);
  return (
    <div>
      <div className="bg-black px-4 lg:px-10 mt-[80px] lg:my-0 py-4 lg:py-[200px] lg:h-screen relative">
        <div className="flex lg:flex-col lg:justify-between text-center gap-5">
          {navItems.length > 0 &&
            navItems.map(({ name, id, to, active }, idx) => (
              <div key={id}>
                <Link
                  to={to}
                  className={`flex w-full  font-medium font-heading text-[12px] lg:text-[14px] ${
                    activeNav === id
                      ? "text-mainGold"
                      : "hover:text-mainGold text-white"
                  }`}
                >
                  <span>{name}</span>
                </Link>
              </div>
            ))}
          <div
            onClick={() => dispatch(logout())}
            className="flex w-[70px] lg:w-full ml-auto  font-medium text-white font-heading text-[12px] lg:text-[14px] lg:absolute lg:bottom-20 cursor-pointer"
          >
            <p>Log Out</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSideNavbar;
