import React from 'react'
import backgroundImg from '../../assets/bgImg.png';


const AboutLanding = () => {
  return (
    <div className='h-[40vh] md:h-[40vh] lg:h-[80vh] relative'>
      <img
        className='w-full absolute h-[35vh] md:h-[35vh] lg:h-[70vh] object-cover'
        src={backgroundImg}
        alt='img'
      />
      <div className='text-white ml-[0px] lg:ml-[70px] md:pb-[0px] lg:pb-[64px] 2xl:pb-[180px] absolute bottom-0 top-[23vh] md:top[25vh] lg:top-[40vh] left-5 md:left-12 md:h-[28vh] lg:h-[60vh]'>
        <div>
          <h4 className='font-heading text-[16px] lg:text-[40px] md:text-[30px]'>
            L'art, c'est de la magie.
          </h4>
        </div>
        <div>
          <p className='text-[10px] md:text-[15px] lg:text-[20px] leading-[13px] w-[231px] lg:leading-[30px] md:leading-[25px] mt-[2px] lg:mt-[15px] md:w-[80%] lg:w-[70%] font-normal'>
            <b>Art is Magic.</b> Explore our gallery and experience the
            enchantment of creativity.
          </p>
        </div>
      </div>
    </div>
  )
}

export default AboutLanding
