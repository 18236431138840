import React from "react";

const RhythmOfExpression = ({
  left,
  backgroundImage,
  bgImageBottomCenter,
  header,
  buttonText,
  buttonIcon,
}) => {
  return (
    <div>
      {/*background image  */}
      <div
        className={`${
          left ? "justify-start pl-[18px] lg:pl-[0px]" : "justify-end"
        } ${backgroundImage} ${
          bgImageBottomCenter ? bgImageBottomCenter : "bg-cover"
        } bg-cover object-cover h-[266px] md:h-[40vh] lg:h-[100vh]  flex flex-row items-end relative`}
        // lg:bg-[top_center_2%]
      >
        <div
          className={` ${
            left
              ? "left-10 bottom-5 lg:bottom-10 w-[200px] lg:w-[380px] py-4 px-[20px] lg:px-[38px]"
              : "bottom-10 right-10 w-[130px] lg:w-[320px]"
          } bg-[#5A5A5A] absolute text-white w-[154px] py-2 bg-opacity-40 border-[#FFFFFF] border`}
        >
          {/* rhythm card */}
          {buttonText ? (
            <div className="">
              <div>
                <p className="font-heading text-[13px] lg:text-[28px] tracking-normal">
                  {header}
                </p>
              </div>
              <div className="flex items-center justify-between pt-[15px] pb-[5px]">
                <div className="text-[12px] lg:text-[25px] 2xl:text-[40px] lg:leading-[30px] font-medium">
                  {buttonText}
                </div>
                <div className="text-[40px] leading-[76px] font-semibold tracking-wide">
                  <img
                    src={buttonIcon}
                    alt=""
                    className="w-[12px] h-[12px]  lg:w-[35px] lg:h-[30px]"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="w-[100px] lg:w-[220px] mx-auto">
              <div>
                <p className="font-heading text-[13px] lg:text-[28px] tracking-normal">
                  Rhythm of expression
                </p>
              </div>
              <div className="text-right">
                <div className="text-[9px] lg:text-[20px] font-normal">By</div>
                <div className="text-[9px] lg:text-[20px] font-semibold tracking-wide">
                  Emily Kadiri
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RhythmOfExpression;
