import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as yup from "yup";
import {
  clearEditSingleArtistSuccess,
  clearRegisterArtistSuccess,
  editSingleArtistRequest,
  // editSingleArtistSuccess,
  getSingleArtistRequest,
  registerArtistLoading,
  registerArtistRequest,
} from "../../../../redux/action/artist";
import { FormError } from "../../../../components/formError";
import { InputWhite } from "../../../../atoms/input/inputWhite";
import Textarea from "../../../../atoms/textarea/Textarea";
import { AdminFormBtn } from "../../../../atoms/button/AdminFormBtn";

const EditArtistForm = ({ buttonText, id }) => {
  // const [artistValue, setArtistValue] = useState({});
  const dispatch = useDispatch();
  const {
    getSingleArtistSuccess,
    getSingleArtistError,
    getSingleArtistLoading,
    getSingleArtist,
    editSingleArtistSuccess,
    editSingleArtistError,
    editSingleArtist,
    deleteArtistSuccess,
    deleteArtistLoading,
    deleteArtistError,
  } = useSelector((state) => {
    const {
      success: {
        getSingleArtist: getSingleArtistSuccess,
        deleteArtist: deleteArtistSuccess,
        editSingleArtist: editSingleArtistSuccess,
      },
      errors: {
        getSingleArtist: getSingleArtistError,
        deleteArtist: deleteArtistError,
        editSingleArtist: editSingleArtistError,
      },
    } = state.ajaxStatuses;
    // GET single artist
    const { getSingleArtistLoading, deleteArtistLoading } =
      state.loadingIndicator;
    const getSingleArtist = state.artistData.getSingleArtist;
    const editSingleArtist = state.artistData.editSingleArtist.message;
    return {
      getSingleArtistSuccess,
      getSingleArtistError,
      getSingleArtistLoading,
      getSingleArtist,
      deleteArtistLoading,
      deleteArtistSuccess,
      deleteArtistError,
      editSingleArtist,
      editSingleArtistSuccess,
      editSingleArtistError,
    };
  });

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      mobile: "",
      artist_bio: "",
      artist_statement: "",
      email: "",
    },
    validationSchema: yup.object({
      // title: yup.string().required('Title is required'),
      firstname: yup
        .string()
        .required("first name is required")
        .max(15, "first name is too long")
        .trim()
        .matches(
          /^[a-zA-Z]+$/,
          "first name must be alphabetic without spacing"
        ),
      lastname: yup
        .string()
        .required("last name is required")
        .max(15, "last name is too long")
        .trim()
        .matches(/^[a-zA-Z]+$/, "last name must be alphabetic without spacing"),
      email: yup
        .string()
        .email("Email is not valid")
        .required("Email is required"),
      mobile: yup
        .string()
        .required("Phone number is required")
        .min(10, "Phone number must be at least 10 characters long"),
      artist_bio: yup.string().required("Artist Bio is required"),
      artist_statement: yup.string().required("Artist Statement is required"),
      // date_of_birth: yup
      //   .string()
      //   // .transform((value) => value.slice(0, 2) + '-' + value.slice(2) + '-')
      //   .required("Date of birth (DD-MM-YYYY) is required"),
      // bank_name: yup.string().required("Bank Name is required"),
      // account_number: yup.number().required("Account Number is required"),
    }),

    onSubmit: (prop) => {
      let formData = prop;
      dispatch(editSingleArtistRequest({ formData, id }));
    },
  });

  useEffect(() => {
    dispatch(getSingleArtistRequest(id));
  }, [id]);

  useEffect(() => {
    if (getSingleArtistSuccess) {
      formik.setValues((values) => ({
        ...values,
        firstname: getSingleArtist.firstname,
        lastname: getSingleArtist.lastname,
        mobile: getSingleArtist.mobile,
        artist_bio: getSingleArtist.artist_bio,
        artist_statement: getSingleArtist.artist_statement,
        email: getSingleArtist.email,
      }));
      // dispatch(registerArtistLoading(false))
    }
  }, [getSingleArtistSuccess, getSingleArtist, id]);

  useEffect(() => {
    if (editSingleArtist) {
      toast.success(editSingleArtist, {
        duration: 3000,
      });

      dispatch(clearEditSingleArtistSuccess());
    }
  }, [editSingleArtist]);

  useEffect(() => {
    if (editSingleArtistError) {
      toast.error(editSingleArtistError, {
        duration: 3000,
      });
    }
  }, [editSingleArtistError]);

  return (
    <div key={id}>
      <form className="mt-5 grid grid-cols-2 gap-x-6">
        <div className="flex flex-col gap-y-4">
          <div>
            <label className="text-[15px] font-medium">First Name</label>
            <InputWhite
              inputType="text"
              inputName="firstName"
              inputPlaceholder="First Name"
              formik={formik.getFieldProps("firstname")}
            />
            {formik.errors.firstname && formik.touched.firstname && (
              <FormError message={formik.errors.firstname} />
            )}
          </div>
          <div>
            <label className="text-[15px] font-medium">Last Name</label>
            <InputWhite
              inputType="text"
              inputName="lastName"
              inputPlaceholder="Last Name"
              formik={formik.getFieldProps("lastname")}
            />
            {formik.errors.lastname && formik.touched.lastname && (
              <FormError message={formik.errors.lastname} />
            )}
          </div>
          <div>
            <label className="text-[15px] font-medium">Email Address</label>
            <InputWhite
              inputType="email"
              inputName="email"
              inputPlaceholder="Email address"
              formik={formik.getFieldProps("email")}
            />
            {formik.errors.email && formik.touched.email && (
              <FormError message={formik.errors.email} />
            )}
          </div>
          <div>
            <label className="text-[15px] font-medium">Phone Number</label>
            <InputWhite
              inputType="Phone"
              inputName="Phone"
              inputPlaceholder="Phone"
              formik={formik.getFieldProps("mobile")}
            />
            {formik.errors.mobile && formik.touched.mobile && (
              <FormError message={formik.errors.mobile} />
            )}
          </div>
          <div>
            <label className="text-[15px] font-medium">Artist Bio</label>
            <Textarea
              inputType="text"
              inputName="artist_bio"
              inputPlaceholder="Artist Biography"
              formik={formik.getFieldProps("artist_bio")}
            />
            {formik.errors.artist_bio && formik.touched.artist_bio && (
              <FormError message={formik.errors.artist_bio} />
            )}
          </div>
        </div>
        <div className="flex flex-col gap-y-4">
          <div>
            <label className="text-[15px] font-medium">Artist Statement</label>
            <Textarea
              inputType="text"
              inputName="artist_statement"
              inputPlaceholder="Artist Statement"
              formik={formik.getFieldProps("artist_statement")}
            />
            {formik.errors.artist_statement &&
              formik.touched.artist_statement && (
                <FormError message={formik.errors.artist_statement} />
              )}
          </div>
          {/* <div>
            <label className="text-[15px] font-medium">Date of Birth</label>
            <InputWhite
              inputType="text"
              inputName="date_of_birth"
              inputPlaceholder="Date of birth (DD-MM-YYYY)"
              formik={formik.getFieldProps("date_of_birth")}
            />
            {formik.errors.date_of_birth && formik.touched.date_of_birth && (
              <FormError message={formik.errors.date_of_birth} />
            )}
          </div> */}
          {/* <div>
            <label className="text-[15px] font-medium">Bank Name</label>
            <InputWhite
              inputType="text"
              inputName="bank_name"
              inputPlaceholder="Bank Name"
              formik={formik.getFieldProps("bank_name")}
            />
            {formik.errors.bank_name && formik.touched.bank_name && (
              <FormError message={formik.errors.bank_name} />
            )}
          </div>
          <div>
            <label className="text-[15px] font-medium">Account Number</label>
            <InputWhite
              inputType="number"
              inputName="account_number"
              inputPlaceholder="Account Number"
              formik={formik.getFieldProps("account_number")}
            />
            {formik.errors.account_number && formik.touched.account_number && (
              <FormError message={formik.errors.account_number} />
            )}
          </div> */}
        </div>
        {/* update artist button  */}
        <div className="mt-8 w-full">
          <AdminFormBtn
            action={formik.handleSubmit}
            buttonText={buttonText}
            // loading={registerArtistLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default EditArtistForm;
