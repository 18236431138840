import React, { useState } from "react";
import OrderCard from "../../component/orderCard/OrderCard";
import { CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";

const OrderHistory = ({ loading, orders }) => {
  return (
    <div>
      {loading ? (
        <div className="flex items-center justify-center my-20">
          <CircularProgress size={20} color="inherit" />
        </div>
      ) : (
        <div className="lg:mt-8">
          {orders.length === 0 ? (
            <div className="grid grid-cols-2  p-4 shadow-md lg:h-[200px] ">
              <p className="font-heading text-xs lg:text-xl">
                We are looking forward to you purchasing Art soon
              </p>
              <Link
                to="/artist"
                className="text-[#FA9219] font-[500] lg:text-lg mt-auto justify-self-end underline cursor-pointer"
              >
                View artworks
              </Link>
            </div>
          ) : (
            orders.map((order, index) => (
              <OrderCard
                key={index}
                artName={order.artwork_id.name_of_artwork}
                artAmount={order.artwork_id.price}
                artStatus={order.status}
                orderImg={order.artwork_id.img_url}
                deliveryDate={"22 October 2023"}
              />
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default OrderHistory;
