import React from 'react'
import AdminDashboardActivity from '../../Dashboard'
import AdminTotalActivity from '../../molecule/adminTotalActivity/AdminTotalActivity'
import AdminTableLayout from '../../molecule/adminTableLayout/AdminTableLayout'

const AdminDashboard = () => {
  return (
    <div>
      <AdminDashboardActivity>
        <div className='container max-w-5xl mx-auto lg:px-8 mb-16'>
          <div>
            <AdminTotalActivity />
          </div>
          <div className=''>
            <h4 className='font-heading lg:text-[25px] lg:leading-[41px] mb-[12px] lg:mb-[20px] text-black'>
              Latest Orders
            </h4>
          </div>
          <div>
            <AdminTableLayout />
          </div>
        </div>
      </AdminDashboardActivity>
    </div>
  )
}

export default AdminDashboard
