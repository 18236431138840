import React from 'react';
import { Link } from 'react-router-dom';
import Gmail from '../../assets/gmail.svg';
import Instagram from '../../assets/instagram.svg';
import Twitter from '../../assets/twitter.svg';
import Facebook from '../../assets/facebook.svg';
import arrowRight from '../../assets/arrowRight.png';

const Footer = () => {
  const socials = [
    { icon: Gmail, name: 'Gmail', url: '#' },
    { icon: Instagram, name: 'Instagram', url: '#' },
    { icon: Twitter, name: 'Twitter', url: '#' },
    { icon: Facebook, name: 'Facebook', url: '#' },
  ];
  return (
    <div className='bg-[#100F0E] mt-[50px] lg:mt-[100px] 2xl:mt-[182px]'>
      <div className='flex justify-between align-baseline items-end md:items-baseline pt-[30px] md:pt-[50px] ml-[21px] md:ml-[70px]'>
        <div className='font-heading text-white'>
          <div>
            <p className='text-[26px] md:text-[50px] 2xl:text-[118px] leading-5 md:leading-[45px]'>
              Contact
            </p>
            <Link to='/contact-us'>
              <div className='bg-[#FA9219] flex items-center justify-between pr-[8px] md:pr-[20px]'>
                <p className='text-[26px] md:text-[50px] 2xl:text-[118px] pt-[4px] pl-[5px] md:pt-[0px] md:pl-[15px]'>
                  Us
                </p>
                <img
                  src={arrowRight}
                  alt='arrow'
                  className='w-[14px] h-[15px] md:w-[30px] md:h-[30px]'
                />
              </div>
            </Link>
          </div>
        </div>
        <div className='text-white text-[8px] md:text-[15px] lg:text-[20px] 2xl:text-[32px] w-[121px] md:w-[450px] mr-[30px] md:mr-[15px]'>
          <p>
            Got to show your art works to a large interested audience? Let’s
            work together to make your dream a reality.
          </p>
        </div>
      </div>
      <div className='flex justify-between items-center mt-[30px] md:mt-[80px] mb-[38px] md:mb-[60px]'>
        <div className='font-body ml-[20px] md:ml-[70px] text-white text-[10px] md:text-[22px] md:leading-[35px] leading-[15px]'>
          <Link to={'/artist'}>
            <div>
              <p>Artist</p>
            </div>
          </Link>
          <Link
            to='/about'
            className='mt-[0px] mb-[0px] md:mt-[20px] md:mb-[20px] 2xl:mt-[20px] 2xl:mb-[20px]'
          >
            <p>About</p>
          </Link>
          <Link to='/contact-us'>
            <p>Contact us</p>
          </Link>
        </div>
        <div>
          {/* socials */}
          <div className='flex items-center justify-center gap-5 md:gap-5 bg-[#4B4947] p-[7px_9px] md:p-[15px_20px_15px_20px] md:mr-[65px] mr-[20px]'>
            {socials.map(({ name, icon, url }) => (
              <Link to={url} key={name}>
                <span className='sr-only'>{name}</span>
                <img
                  src={icon}
                  alt={name}
                  className='w-[11px] h-[11px] md:w-[30px] md:h-[30px]'
                />
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className='ml-[19px] md:ml-[60px] text-white pb-[11px] md:pb-[10px] mr-[19px] md:mr-[60px]'>
        <hr />
        <p className='text-center mt-[4px] md:mt-[15px] text-[8px] md:text-[15px]'>
          @{new Date().getFullYear()} KACHI JAMES GALLERY LLC.
        </p>
      </div>
    </div>
  );
};

export default Footer;
