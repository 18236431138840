import React, { useState } from "react";
import LandingLayout from "../../../components/layout/landingLayout/LandingLayout";
import ArtistLandingPage from "../../../molecules/artistLandingPage/ArtistLandingPage";
import ArtistNavbar from "../../../components/artistNavbar/ArtistNavbar";
import Painting from "../../../components/painting/Painting";
import Drawing from "../../../components/drawing/Drawing";
import Sculpture from "../../../components/sculpture/Sculpture";

const ArtistAndArtPage = () => {
  const [activeNav, setActiveNav] = useState(1);
  return (
    <div>
      <LandingLayout height={""}>
        <div className="relative">
          <ArtistLandingPage />
          <div className="absolute m-auto left-0 right-0 lg:left-0 lg:right-0 w-[230px] lg:w-[600px] lg:top-[430px]">
            <ArtistNavbar setActiveNav={setActiveNav} activeNav={activeNav} />
          </div>
        </div>
        <div className=" pt-[51px] lg:pt-[0px]">
          {activeNav === 1 ? (
            <Painting />
          ) : activeNav === 2 ? (
            <Drawing />
          ) : activeNav === 3 ? (
            <Sculpture />
          ) : null}
        </div>
      </LandingLayout>
    </div>
  );
};

export default ArtistAndArtPage;
