import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const AdminSideNavbar = () => {
  const location = useLocation();
  const [activeNav, setActiveNav] = useState(1);
  // const [navItems, setNavItems] = useState([]);
  const navbarLinkNames = [
    {
      name: "Dashboard",
      id: 1,
      to: "dashboard",
    },
    {
      name: "All Art works",
      id: 2,
      to: "admin-all-art-works",
    },
    {
      name: "Create Artwork",
      id: 3,
      to: "create-artwork",
    },
    {
      name: "Artists",
      id: 4,
      to: "admin-all-artist",
    },
    {
      name: "Create Artists",
      id: 5,
      to: "create-artist",
    },
    {
      name: "All orders",
      id: 6,
      to: "all-orders",
    },
  ]
  
   useEffect(() => {
     // Find the index of the current route in the navbarLinkNames array
     const index = navbarLinkNames.findIndex((item) =>
       location.pathname.includes(item.to)
     );
     setActiveNav(index + 1);
   }, [location.pathname, navbarLinkNames]);
  return (
    <div>
      <div className="relative z-50 overflow-x-scroll  bg-white py-10 shadow-sm  lg:mt-0  lg:h-screen lg:bg-black lg:px-10 lg:py-0 lg:pt-20 lg:shadow-none">
        <div className="flex gap-10 text-center lg:flex-col lg:justify-between">
          {navbarLinkNames.map(({ name, id, to }) => (
            <div key={id}>
              <Link
                to={`/admin/${to}`}
                className={`flex w-full items-center justify-center whitespace-nowrap font-heading text-[12px] font-medium max-sm:pl-4 sm:w-full  md:pl-6 lg:justify-start lg:pl-0 lg:text-[14px] ${
                  id === activeNav
                    ? "text-mainGold"
                    : "text-[#A9A49E] hover:text-mainGold"
                }`}
                onClick={() => setActiveNav(id)}
              >
                <span>{name}</span>
              </Link>
            </div>
          ))}
          <div className="flex w-full items-center justify-center whitespace-nowrap pr-5 font-heading text-[12px] font-medium text-[#A9A49E] max-sm:pl-4 sm:w-fit lg:absolute lg:bottom-20 lg:pr-0 lg:text-[14px] lg:text-white">
            <p>Log Out</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSideNavbar;
