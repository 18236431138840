import React from "react";
// import img3 from "../../assets/Photo (2).png";
import { Link } from "react-router-dom";

export const ArtCard = ({ artwork }) => {
  return (
    <div className="relative grid grid-rows-2  w-full lg:w-[450px] h-[350px] lg:h-[550px]">
      {artwork.img_url && (
        <img
          src={artwork.img_url}
          alt="art"
          className="w-full h-full absolute object-cover"
        />
      )}

      <div></div>
      <div className="lg:mx-4 mt-auto lg:h-[23vh] px-2 lg:px-4  bg-[#5A5A5A] text-white py-2  lg:pt-5  bg-opacity-40 border-[#FFFFFF] border relative bottom-0">
        <div className="text-right text-white">
          <p className="font-heading text-[13px] lg:text-2xl tracking-normal ">
            {artwork.name_of_artwork && artwork.name_of_artwork}
          </p>
          <div className="text-[9px] lg:text-xl font-thin lg:pb-5">
            By{" "}
            <b className="">
              {artwork.artist_id &&
                artwork.artist_id.firstname + " " + artwork.artist_id &&
                artwork.artist_id.lastname}
            </b>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className="">
            {/* <h4 className="text-white text-[10px] lg:text-[18px]">
              ₦ {artwork.price && artwork.price}
            </h4> */}
          </div>

          <Link to={`/art-page/${artwork._id}`}>
            <div className="border border-white text-[10px] lg:text-[12px] text-white text-center p-1 lg:p-2  w-[80px] lg:w-[130px] font-heading cursor-pointer">
              View
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};
