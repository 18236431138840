import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as yup from "yup";
import {
  clearRegisterArtistSuccess,
  registerArtistRequest,
} from "../../../../redux/action/artist";
import { FormError } from "../../../../components/formError";
import { InputWhite } from "../../../../atoms/input/inputWhite";
import Textarea from "../../../../atoms/textarea/Textarea";
import { AdminFormBtn } from "../../../../atoms/button/AdminFormBtn";

const CreateArtistForm = ({ buttonText }) => {
  const dispatch = useDispatch();

  const { registerArtistSuccess, registerArtistError, registerArtistLoading } =
    useSelector((state) => {
      const {
        success: { registerArtist: registerArtistSuccess },
        errors: { registerArtist: registerArtistError },
      } = state.ajaxStatuses;

      const { registerArtistLoading } = state.loadingIndicator;

      return {
        registerArtistSuccess,
        registerArtistError,
        registerArtistLoading,
      };
    });

  const formik = useFormik({
    initialValues: {
      // title: '',
      firstname: "",
      lastname: "",
      mobile: "",
      artist_bio: "",
      artist_statement: "",
      email: "",
    },
    validationSchema: yup.object({
      // title: yup.string().required('Title is required'),
      firstname: yup
        .string()
        .required("first name is required")
        .max(15, "first name is too long")
        .trim()
        .matches(
          /^[a-zA-Z]+$/,
          "first name must be alphabetic without spacing"
        ),
      lastname: yup
        .string()
        .required("last name is required")
        .max(15, "last name is too long")
        .trim()
        .matches(/^[a-zA-Z]+$/, "last name must be alphabetic without spacing"),
      email: yup
        .string()
        .email("Email is not valid")
        .required("Email is required"),
      mobile: yup
        .string()
        .required("Phone number is required")
        .min(10, "Phone number must be at least 10 characters long"),
      artist_bio: yup.string().required("Artist Bio is required"),
      artist_statement: yup.string().required("Artist Statement is required"),
    }),

    onSubmit: (prop) => {
      let formData = prop;
      dispatch(registerArtistRequest({ formData }));
      // Reset the form after successful submission
    },
  });

  useEffect(() => {
    if (registerArtistError) {
      toast.error(registerArtistError, {
        duration: 3000,
      });
    }
  }, [registerArtistError]);

  useEffect(() => {
    if (registerArtistSuccess) {
      toast.success(registerArtistSuccess, {
        duration: 3000,
        // position: 'bottom-right',
      });
      dispatch(clearRegisterArtistSuccess());
    }
  }, [registerArtistSuccess]);
  return (
    <div>
      <form className="grid grid-cols-2 gap-x-6 mt-5">
        <div className="flex flex-col gap-y-4">
          <div>
            <InputWhite
              inputType="text"
              inputName="firstName"
              inputPlaceholder="First Name"
              formik={formik.getFieldProps("firstname")}
            />
            {formik.errors.firstname && formik.touched.firstname && (
              <FormError message={formik.errors.firstname} />
            )}
          </div>
          <div>
            <InputWhite
              inputType="text"
              inputName="lastName"
              inputPlaceholder="Last Name"
              formik={formik.getFieldProps("lastname")}
            />
            {formik.errors.lastname && formik.touched.lastname && (
              <FormError message={formik.errors.lastname} />
            )}
          </div>
          <div>
            <InputWhite
              inputType="email"
              inputName="email"
              inputPlaceholder="Email address"
              formik={formik.getFieldProps("email")}
            />
            {formik.errors.email && formik.touched.email && (
              <FormError message={formik.errors.email} />
            )}
          </div>
          <div>
            <InputWhite
              inputType="Phone"
              inputName="Phone"
              inputPlaceholder="Phone"
              formik={formik.getFieldProps("mobile")}
            />
            {formik.errors.mobile && formik.touched.mobile && (
              <FormError message={formik.errors.mobile} />
            )}
          </div>
        </div>
        <div className="flex flex-col gap-y-4">
          <div>
            <Textarea
              inputType="text"
              inputName="artist_statement"
              inputPlaceholder="Artist Statement"
              formik={formik.getFieldProps("artist_statement")}
            />
            {formik.errors.artist_statement &&
              formik.touched.artist_statement && (
                <FormError message={formik.errors.artist_statement} />
              )}
          </div>
          <div>
            <Textarea
              inputType="text"
              inputName="artist_bio"
              inputPlaceholder="Artist Biography"
              formik={formik.getFieldProps("artist_bio")}
            />
            {formik.errors.artist_bio && formik.touched.artist_bio && (
              <FormError message={formik.errors.artist_bio} />
            )}
          </div>
        </div>
        <div className="w-full mt-8">
          <AdminFormBtn
            action={formik.handleSubmit}
            buttonText={buttonText}
            loading={registerArtistLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default CreateArtistForm;
