export const DISPATCH_ERROR = "DISPATCH_ERROR";
export const CLEAR_ERROR_STATE = "CLEAR_ERROR_STATE";

export const LOGOUT = "LOGOUT";

// user types
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const CLEAR_REGISTER_SUCCESS = "CLEAR_REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_LOADING = "REGISTER_LOADING";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const CLEAR_LOGIN_SUCCESS = "CLEAR_LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_LOADING = "LOGIN_LOADING";

export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const CLEAR_UPDATE_USER_SUCCESS = "CLEAR_UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_LOADING = "UPDATE_USER_LOADING";

//artwork types
export const CREATE_ARTWORK_SUCCESS = "CREATE_ARTWORK_SUCCESS";
export const CLEAR_CREATE_ARTWORK_SUCCESS = "CLEAR_CREATE_ARTWORK_SUCCESS";
export const CREATE_ARTWORK_FAIL = "CREATE_ARTWORK_FAIL";
export const CREATE_ARTWORK_REQUEST = "CREATE_ARTWORK_REQUEST";
export const CREATE_ARTWORK_LOADING = "CREATE_ARTWORK_LOADING";

// Register artist (Admin)
export const REGISTER_ARTIST_SUCCESS = "REGISTER_ARTIST_SUCCESS";
export const CLEAR_REGISTER_ARTIST_SUCCESS = "CLEAR_REGISTER_ARTIST_SUCCESS";
export const REGISTER_ARTIST_FAIL = "REGISTER_ARTIST_FAIL";
export const REGISTER_ARTIST_REQUEST = "REGISTER_ARTIST_REQUEST";
export const REGISTER_ARTIST_LOADING = "REGISTER_ARTIST_LOADING";

export const GET_ARTWORKS_SUCCESS = "GET_ARTWORKS_SUCCESS";
export const CLEAR_GET_ARTWORKS_SUCCESS = "CLEAR_ARTWORKS_SUCCESS";
export const GET_ARTWORKS_FAIL = "GET_ARTWORKS_FAIL";
export const GET_ARTWORKS_REQUEST = "GET_ARTWORKS_REQUEST";
export const GET_ARTWORKS_LOADING = "GET_ARTWORKS_LOADING";

export const GET_ARTWORK_SUCCESS = "GET_ARTWORK_SUCCESS";
export const CLEAR_GET_ARTWORK_SUCCESS = "CLEAR_GET_ARTWORK_SUCCESS";
export const GET_ARTWORK_FAIL = "GET_ARTWORK_FAIL";
export const GET_ARTWORK_REQUEST = "GET_ARTWORK_REQUEST";
export const GET_ARTWORK_LOADING = "GET_ARTWORK_LOADING";

//order types
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CLEAR_CREATE_ORDER_SUCCESS = "CLEAR_CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_FAIL = "CREATE_ORDER_FAIL";
export const CREATE_ORDER_REQUEST = "CREATE_ORDER_REQUEST";
export const CREATE_ORDER_LOADING = "CREATE_ORDER_LOADING";

export const GET_USER_ORDER_SUCCESS = "GET_USER_ORDER_SUCCESS";
export const GET_USER_ORDER_FAIL = "GET_USER_ORDER_FAIL";
export const GET_USER_ORDER_REQUEST = "GET_USER_ORDER_REQUEST";
export const GET_USER_ORDER_LOADING = "GET_USER_ORDER_LOADING";

// get all artists
export const GET_ARTISTS_SUCCESS = "GET_ARTISTS_SUCCESS";
export const CLEAR_GET_ARTISTS_SUCCESS = "CLEAR_ARTISTS_SUCCESS";
export const GET_ARTISTS_FAIL = "GET_ARTISTS_FAIL";
export const GET_ARTISTS_REQUEST = "GET_ARTISTS_REQUEST";
export const GET_ARTISTS_LOADING = "GET_ARTISTS_LOADING";

// get single artist
export const GET_SINGLE_ARTIST_SUCCESS = "GET_SINGLE_ARTIST_SUCCESS";
export const CLEAR_GET_SINGLE_ARTIST_SUCCESS =
  "CLEAR_GET_SINGLE_ARTIST_SUCCESS";
export const GET_SINGLE_ARTIST_FAIL = "GET_SINGLE_ARTIST_FAIL";
export const GET_SINGLE_ARTIST_REQUEST = "GET_SINGLE_ARTIST_REQUEST";
export const GET_SINGLE_ARTIST_LOADING = "GET_SINGLE_ARTIST_LOADING";

// EDIT single Artist
export const EDIT_SINGLE_ARTIST_SUCCESS = "EDIT_SINGLE_ARTIST_SUCCESS";
export const CLEAR_EDIT_SINGLE_ARTIST_SUCCESS =
  "CLEAR_EDIT_SINGLE_ARTIST_SUCCESS";
export const EDIT_SINGLE_ARTIST_FAIL = "EDIT_SINGLE_ARTIST_FAIL";
export const EDIT_SINGLE_ARTIST_REQUEST = "EDIT_SINGLE_ARTIST_REQUEST";
export const EDIT_SINGLE_ARTIST_LOADING = "EDIT_SINGLE_ARTIST_LOADING";

// DELETE all artists
export const DELETE_ARTIST_SUCCESS = "DELETE_ARTIST_SUCCESS";
export const DELETE_ARTIST_FAIL = "DELETE_ARTIST_FAIL";
export const DELETE_ARTIST_REQUEST = "DELETE_ARTIST_REQUEST";
export const DELETE_ARTIST_LOADING = "DELETE_ARTIST_LOADING";
export const CLEAR_DELETE_ARTIST_SUCCESS = "CLEAR_DELETE_ARTIST_SUCCESS";

// DELETE all artwork
export const DELETE_ARTWORK_SUCCESS = "DELETE_ARTWORK_SUCCESS";
export const DELETE_ARTWORK_FAIL = "DELETE_ARTWORK_FAIL";
export const DELETE_ARTWORK_REQUEST = "DELETE_ARTWORK_REQUEST";
export const DELETE_ARTWORK_LOADING = "DELETE_ARTWORK_LOADING";
export const CLEAR_DELETE_ARTWORK_SUCCESS = "CLEAR_DELETE_ARTWORK_SUCCESS";
