import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { loginRequest, clearLoginSuccess } from "../../../../redux/action";
// import Input from '../../../../atoms/input/Input';
import { FormError } from "../../../../components/formError";
import ContactButton from "../../../../atoms/button/ContactButton";
import Logo from "../../../../assets/adminLogo.svg";
import adminImg from "../../../../assets/adminImg.png";
import adminFrame from "../../../../assets/adminFrame.png";
import { InputWhite } from "../../../../atoms/input/inputWhite";

const AdminLogin = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { loginSuccess, loginError, loginLoading, user, singleUser } =
    useSelector((state) => {
      const {
        success: { login: loginSuccess },
        errors: { getUser: getUserError, login: loginError },
      } = state.ajaxStatuses;

      const { loginLoading, getUserLoading } = state.loadingIndicator;

      const { user, singleUser } = state.userData;

      return {
        getUserLoading,
        getUserError,
        loginSuccess,
        loginError,
        loginLoading,
        user,
        singleUser,
      };
    });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("Email is not valid")
        .required("Email is required"),
      password: yup.string().required("Password is required"),
    }),

    onSubmit: (prop) => {
      let formData = prop;
      dispatch(loginRequest({ formData }));
    },
  });

  useEffect(() => {
    if (loginError) {
      toast.error(loginError, {
        duration: 3000,
      });
    }
  }, [loginError]);

  useEffect(() => {
    if (loginSuccess) {
      toast.success(loginSuccess, {
        duration: 3000,
      });

      history.push("/admin/dashboard");

      dispatch(clearLoginSuccess());
    }
  }, [loginSuccess]);

  return (
    <div>
      {/* h-[100vh] p-[35px_79px_15px_27px] lg:p-[170px_384px_86px_132px] */}
      {/* overflow-scroll */}
      <div className="h-screen">
        <div className="grid lg:grid-cols-12 items-center">
          <div className="hidden lg:col-span-6 relative lg:flex items-center">
            <img
              src={adminFrame}
              alt="adminImg"
              className="h-[20vh] w-full lg:h-screen lg:w-[33vw] object-fill"
            />
            <div className="absolute lg:right-0">
              <div className="relative">
                <img
                  src={Logo}
                  alt="kachiLogo"
                  className="lg:w-[193px] lg:h-[32px] w-[76px] h-[12px] absolute top-12 lg:left-[30px] object-fill"
                />
                <img
                  src={adminImg}
                  alt="adminImg"
                  className="h-[20vh] lg:h-[60vh] object-fill"
                />
              </div>
            </div>
          </div>
          <div className="container lg:max-w-4xl px-4 lg:px-0 mt-16 lg:mt-0 mx-auto lg:pl-24 lg:col-span-5">
            <div>
              <h3 className="text-4xl lg:text-[30px] leading-[46px] font-heading text-mainBlack">
                Log In
              </h3>
              <p className="text-xl py-3 lg:py-0 lg:text-[15px] lg:leading-[44px] leading-[29px] font-body text-mainBlack">
                Welcome! Check recent updates.
              </p>
            </div>
            <div className="">
              <form autoComplete="off">
                <div className="mt-4 lg:mt-[14px] w-full">
                  <div className="mb-1">
                    <label className="text-[12px]">Email Address</label>
                  </div>
                  <InputWhite
                    inputType="email"
                    inputName="email"
                    inputPlaceholder="Email address"
                    formik={formik.getFieldProps("email")}
                  />
                  {formik.errors.email && formik.touched.email && (
                    <FormError message={formik.errors.email} />
                  )}
                </div>
                <div className="mt-4 lg:mt-[14px] w-full">
                  <div className="mb-1">
                    <label className="text-[12px]">Password</label>
                  </div>
                  <InputWhite
                    inputType="password"
                    inputName="password"
                    inputPlaceholder="Password"
                    formik={formik.getFieldProps("password")}
                  />

                  {formik.errors.password && formik.touched.password && (
                    <FormError message={formik.errors.password} />
                  )}
                </div>
              </form>
            </div>
            <div className="">
              <div className="mt-8">
                <ContactButton
                  action={formik.handleSubmit}
                  loading={loginLoading}
                  buttonText="Log in"
                  padding=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
