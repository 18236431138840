import React, { useEffect } from "react";
import { InputWhite } from "../../../../atoms/input/inputWhite";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ImageUpload } from "../../../../components/imageUpload/ImageUpload";
import AdminNavbar from "../adminNavbar/AdminNavbar";
import { AdminFormBtn } from "../../../../atoms/button/AdminFormBtn";
import { FormError } from "../../../../components/formError";
import {
  clearCreateArtworkSuccess,
  createArtworkRequest,
} from "../../../../redux/action";

export const CreateArtworkForm = () => {
  const dispatch = useDispatch();

  const { createArtworkSuccess, createArtworkError, createArtworkLoading } =
    useSelector((state) => {
      const {
        success: { createArtwork: createArtworkSuccess },
        errors: { createArtwork: createArtworkError },
      } = state.ajaxStatuses;

      const { createArtworkLoading } = state.loadingIndicator;

      return {
        createArtworkSuccess,
        createArtworkError,
        createArtworkLoading,
      };
    });

  const formik = useFormik({
    initialValues: {
      img_url: "",
      name_of_artwork: "",
      artist_email: "",
      type_of_artwork: "",
      price: "",
      description: "",
    },
    validationSchema: yup.object({
      img_url: yup.string().required("Image is required"),
      name_of_artwork: yup.string().required("Artwork name is required"),
      artist_email: yup
        .string()
        .email("Artist email is not valid")
        .required("Artist email is required"),
      price: yup.string().required("price is required"),
      description: yup.string().required("description is required"),
    }),

    onSubmit: (prop) => {
      dispatch(createArtworkRequest({ formData: prop }));
    },
  });

  useEffect(() => {
    if (createArtworkError) {
      toast.error(createArtworkError, {
        duration: 3000,
      });
    }
  }, [createArtworkError]);

  useEffect(() => {
    if (createArtworkSuccess) {
      toast.success(createArtworkSuccess, {
        duration: 3000,
      });

      dispatch(clearCreateArtworkSuccess());
    }
  }, [createArtworkSuccess]);

  return (
    <div>
      <form className="grid grid-cols-2 gap-x-6 mt-5">
        <div className="flex flex-col gap-y-4">
          <div>
            <InputWhite
              inputType="text"
              inputName="name_of_artwork"
              inputPlaceholder="Name Of Artwork"
              formik={formik.getFieldProps("name_of_artwork")}
              label={"Name of artwork"}
            />
            {formik.errors.name_of_artwork &&
              formik.touched.name_of_artwork && (
                <FormError message={formik.errors.name_of_artwork} />
              )}
          </div>
          <div>
            <div className="text-body text-sm">Artwork Type</div>
            <select
              name="title"
              className="w-full  p-[9px_9px_10px_16px] border border-black text-dark placeholder-[#666257] text-md"
              {...formik.getFieldProps("type_of_artwork")}
            >
              <option className="text-black">Select Type</option>
              <option value={"Painting"}>Painting</option>
              <option value={"Digital Painting"}>Digital Painting</option>
              <option value={"Sculpture"}>Sculpture</option>
            </select>
            {formik.errors.type_of_artwork &&
              formik.touched.type_of_artwork && (
                <FormError message={formik.errors.type_of_artwork} />
              )}
          </div>
          <div>
            <div className="text-body text-sm">Artwork description</div>
            <textarea
              type={"textbox"}
              name={"description"}
              placeholder={"write a description"}
              {...formik.getFieldProps("description")}
              className="w-full  p-[9px_9px_10px_16px] border border-black placeholder-[#666257] text-md"
              autoComplete="off"
            />
            {formik.errors.description && formik.touched.description && (
              <FormError message={formik.errors.description} />
            )}
          </div>
        </div>
        <div className="flex flex-col gap-y-4">
          <div>
            <InputWhite
              inputType="text"
              inputName="artist_email"
              inputPlaceholder="Artist Email"
              formik={formik.getFieldProps("artist_email")}
              label={"Artist email"}
            />
            {formik.errors.artist_email && formik.touched.artist_email && (
              <FormError message={formik.errors.artist_email} />
            )}
          </div>
          <div>
            <InputWhite
              inputType="number"
              inputName="Price"
              inputPlaceholder="Price"
              formik={formik.getFieldProps("price")}
              label={"Price"}
            />
            {formik.errors.price && formik.touched.price && (
              <FormError message={formik.errors.price} />
            )}
          </div>

          <div>
            <ImageUpload formik={formik} />
            {formik.errors.img_url && formik.touched.img_url && (
              <FormError message={formik.errors.img_url} />
            )}
          </div>
        </div>
        <div className="w-full mt-5">
          <AdminFormBtn
            action={formik.handleSubmit}
            buttonText={"Create Artwork"}
            loading={createArtworkLoading}
          />
        </div>
      </form>
    </div>
  );
};
