import React from 'react'
import AboutLanding from '../../../molecules/aboutLanding/AboutLanding'
import LandingLayout from '../../../components/layout/landingLayout/LandingLayout'
// import UpComingCard from '../../../molecules/upComingEvents/UpComingCard'
// import UpComingEvents from '../../../molecules/upComingEvents/UpComingEvents'
import EventsCard from '../../../molecules/events/Events'
import RhythmOfExpression from '../../../molecules/rhythmOfExpression/RhythmOfExpression'
import BookEvent from '../../../molecules/events/BookEvent'

const Events = () => {
  return (
    <div>
      <LandingLayout height={''}>
        <AboutLanding />
        <div className='container mx-auto max-w-5xl'>
          <h4 className='font-heading pl-5 md:pl-[50px] lg:pl-0 lg:text-[25px] lg:leading-[41px] mb-[12px] lg:mb-[0px] text-black'>
            Currently Happening
          </h4>
        </div>
        <EventsCard />
        <div>
          <div className='container mx-auto max-w-5xl'>
            <h4 className='font-heading pl-5 md:pl-[50px] lg:pl-0  lg:text-[25px] lg:leading-[41px] mb-[12px] lg:mb-[0px] text-black'>
              Upcoming Events
            </h4>
          </div>
          <div className='mb-16'>
            <BookEvent />
          </div>
        </div>
        <RhythmOfExpression left='left' backgroundImage={'bg-manWithLongEye'} />
      </LandingLayout>
    </div>
  );
}

export default Events
