import React, { useEffect, useState } from "react";
import LandingLayout from "../../../components/layout/landingLayout/LandingLayout";
import AboutLanding from "../../../molecules/aboutLanding/AboutLanding";
import SinglePageArtistForm from "./SinglePageArtistForm";
import singleArtistImg from "../../../assets/photo(1).png";
import pana from "../../../assets/pana.png";
import certificate from "../../../assets/certification.png";
import manCarryLoad from "../../../assets/manCarryLoad.png";
import WhatWeOffer from "../../../components/card/WhatWeOffer";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router";
import { usePaystackPayment } from "react-paystack";
import {
  clearCreateOrderSuccess,
  createOrderRequest,
  getArtworkRequest,
} from "../../../redux/action";
import { CircularProgress } from "@material-ui/core";
import ArtistCardTwo from "../../../molecules/artistCardTwo/ArtistCardTwo";

const SingleArtistPage = () => {
  const [readMoreLength, setReadMoreLength] = useState(240);
  const [toggleReadMore, setToggleReadMore] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    setReadMoreLength(240 * 2);
  }, []);

  const {
    artwork,
    getArtworkLoading,
    getArtworkError,
    user,
    createOrderSuccess,
    createOrderError,
    createOrderLoading,
  } = useSelector((state) => {
    const {
      errors: { getArtwork: getArtworkError, createOrder: createOrderError },
      success: { createOrder: createOrderSuccess },
    } = state.ajaxStatuses;

    const { getArtworkLoading, createOrderLoading } = state.loadingIndicator;

    const { user } = state.userData;
    const { artwork } = state.artworkData;

    return {
      artwork,
      getArtworkLoading,
      getArtworkError,
      user,
      createOrderSuccess,
      createOrderError,
      createOrderLoading,
    };
  });

  const config = {
    email: user.email,
    amount: artwork.price,
    metadata: {
      name: user.firstname + " " + user.lastname,
      phone: user.mobile,
    },
    publicKey: `${process.env.REACT_APP_TEST_PAYSTACK_KEY}`,
  };
  const initializePayment = usePaystackPayment(config);

  // HANDLERS
  // paystack handlers

  const onSuccess = (e) => {
    const formData = {
      reference: e.reference,
      email: user.email,
      artwork_id: artwork._id && artwork._id,
      status: "Processing",
    };

    // formdata.reference = e.reference
    // formdata.email = user.email
    // formdata.artwork_id = artwork._id
    // formdata

    dispatch(createOrderRequest({ formData }));
  };
  const onClose = () => {};

  useEffect(() => {
    dispatch(getArtworkRequest(id));
  }, [getArtworkRequest, id]);

  useEffect(() => {
    if (createOrderError) {
      toast.error(createOrderError, {
        duration: 3000,
      });
    }
  }, [createOrderError]);

  useEffect(() => {
    if (createOrderSuccess) {
      toast.success(createOrderSuccess, {
        duration: 3000,
      });
      dispatch(clearCreateOrderSuccess());
    }
  }, [createOrderSuccess]);

  return (
    <div>
      <LandingLayout>
        <AboutLanding />
        <div className="container mx-auto max-w-5xl px-4 lg:px-8">
          {getArtworkLoading ? (
            <div className="flex h-[300px] items-center justify-center">
              <CircularProgress size={20} style={{ color: "black" }} />
            </div>
          ) : (
            <ArtFirstCard artwork={artwork} />
          )}
        </div>
        <div className="my-[80px] bg-[#F8F8F7]">
          <SinglePageArtistForm artwork={artwork} />
        </div>
        {/* About Artist */}
        <div className="my-[80px] bg-[#F8F8F7]">
          {getArtworkLoading ? (
            <div className="flex h-[300px] items-center justify-center">
              <CircularProgress size={20} style={{ color: "black" }} />
            </div>
          ) : (
            <ArtSecondCard
              toggleReadMore={toggleReadMore}
              setToggleReadMore={setToggleReadMore}
              readMoreLength={readMoreLength}
              artwork={artwork}
            />
          )}
        </div>

        <div className="flex flex-col gap-y-4">
          <p className="container mx-auto px-4 font-heading text-xs lg:text-[25px] lg:leading-[41px]">
            More Art works from the same Artist
          </p>
          <ArtistCardTwo />
        </div>
        {/* what We offer */}
        <div className="mx-auto max-w-5xl px-8">
          <p className="mb-4 font-heading text-sm">What we offer</p>
          <div className="grid grid-cols-12">
            <div className="col-span-6 lg:col-span-6">
              <WhatWeOffer
                header={"On-site art tour and consultation"}
                paragraph={"On-site art tour and consultation"}
                img={pana}
              />
            </div>
            <div className="col-span-6 lg:col-span-6">
              <WhatWeOffer
                header={"Proof of authenticity"}
                paragraph={"On-site art tour and consultation"}
                img={certificate}
              />
            </div>
            <div className="col-span-6 lg:col-span-6">
              <WhatWeOffer
                header={"Gallery Appraisal"}
                paragraph={"On-site art tour and consultation"}
                img={pana}
              />
            </div>
            <div className="col-span-6 lg:col-span-6">
              <WhatWeOffer
                header={"Global shipping"}
                paragraph={"On-site art tour and consultation"}
                img={manCarryLoad}
              />
            </div>
          </div>
        </div>
      </LandingLayout>
    </div>
  );
};

const ArtFirstCard = ({ artwork }) => {
  return (
    <div className="grid grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-2 lg:mt-[0px]">
      <div className="row-start-2 lg:row-start-1">
        <h4 className="font-heading text-xl leading-[30px] lg:max-w-[750px] lg:text-[55px] lg:leading-[75px]">
          {artwork.name_of_artwork && artwork.name_of_artwork}
        </h4>
        <div className="mt-3 text-xl tracking-wide lg:mt-5 lg:text-2xl">
          <span className="text-[12px] font-normal lg:text-[20px]">By</span>{" "}
          {artwork.artist_id && artwork.artist_id.firstname}
        </div>
        <i className="text-[12px] font-normal text-[#424038] lg:text-[17px]">
          {artwork.description && artwork.description}
        </i>
        <div className="my-2 text-[12px] text-[#424038] lg:my-3 lg:text-[17px]">
          100 X 180cm 44 X 70 in
        </div>
        <div>
          <div className="mt-[24px] max-w-[400px] text-[14px] lg:mt-[34px] lg:text-[20px] lg:leading-[25px]">
            <p className="text-[15px] text-[#8A8575] lg:text-[18px]">
              About this artwork
            </p>
            <p className="pt-4">
              <b className="font-heading">
                {" "}
                {artwork.name_of_artwork && artwork.name_of_artwork}
              </b>{" "}
              {artwork.description && artwork.description}
            </p>
          </div>
          {/* <div className="mt-4 mb-10 lg:my-3 lg:mt-0 lg:mb-0">
            <p className="font-heading text-[18px] lg:text-[30px]">
              ₦{artwork.price && artwork.price}{" "}
            </p>
          </div> */}
        </div>
      </div>
      <div className="">
        <img
          src={artwork.img_url && artwork.img_url}
          alt="entrepreneur Img"
          className="h-full w-full"
        />
      </div>
    </div>
  );
};

const ArtSecondCard = ({
  artwork,
  toggleReadMore,
  setToggleReadMore,
  readMoreLength,
}) => {
  return (
    <div className="mx-auto max-w-3xl px-8 py-12">
      <p className="pb-1 text-xs">About the Artist</p>
      <h3 className="font-heading text-xl">
        <b>{artwork.artist_id && artwork.artist_id.firstname}</b>
      </h3>
      <p
        style={{
          height: toggleReadMore ? readMoreLength : 100,
          transition: "all .5s",
        }}
        className="overflow-hidden py-3 text-[#C4C2BA]"
      >
        {artwork.artist_id
          ? artwork.artist_id.artist_statement
          : `In this captivating masterpiece, we are transported to an epic glow of
        an dancer under the golden sun.`}
      </p>
      <p
        onClick={() => setToggleReadMore(!toggleReadMore)}
        className="font-semibold"
      >
        {!toggleReadMore ? " Read more..." : "close"}
      </p>
    </div>
  );
};

export default SingleArtistPage;
