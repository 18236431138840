import React from "react";
import profileImg from "../../../../assets/profileImg2.png";
import pen from "../../../../assets/pen.png";
import ContactButton from "../../../../atoms/button/ContactButton";
import { useSelector } from "react-redux";

const ProfileDetails = ({ smoothScroll }) => {
  const { user } = useSelector((state) => {
    const { user } = state.userData;

    return { user };
  });

  return (
    <div className="py-6 shadow lg:w-64">
      {/* <div className='relative w-[215px] mx-auto'>
          <img src={profileImg} alt='img' className='w-[215px] h-[215px]' />
          <div className='absolute bottom-0 right-0'>
            <img src={pen} alt='img' />
          </div>
        </div> */}
      <div className="text-center leading-[30px] pt-[16px]">
        <div>
          <p className="font-bold text-[17px]">
            {user.title && user.title} {user.firstname && user.firstname}{" "}
            {user.lastname && user.lastname}
          </p>
        </div>
        <div>
          <p>{user.email && user.email}</p>
        </div>
        <div>
          <p>{user.mobile && user.mobile}</p>
        </div>
        <div className="px-3">
          <ContactButton
            action={() => smoothScroll()}
            buttonText={"Edit Profile"}
            padding=""
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileDetails;
