import React, { useState, useEffect } from "react";
import Input from "../../../../atoms/input/Input";
import ContactInput from "../../../../atoms/input/ContactInput";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as yup from "yup";
import { FormError } from "../../../../components/formError";
import { AdminFormBtn } from "../../../../atoms/button/AdminFormBtn";
import {
  clearUpdateUserSuccess,
  updateUserRequest,
} from "../../../../redux/action";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const ProfileForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    getUserLoading,
    getUserError,
    updateUserSuccess,
    updateUserError,
    updateUserLoading,
    user,
    singleUser,
    token,
  } = useSelector((state) => {
    const {
      success: { updateUser: updateUserSuccess },
      errors: { getUser: getUserError, updateUser: updateUserError },
    } = state.ajaxStatuses;

    const { updateUserLoading, getUserLoading } = state.loadingIndicator;

    const { user, singleUser, token } = state.userData;

    return {
      getUserLoading,
      getUserError,
      updateUserSuccess,
      updateUserError,
      updateUserLoading,
      user,
      singleUser,
      token,
    };
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      firstname: "",
      lastname: "",
      mobile: "",
      address: "",
    },
    validationSchema: yup.object({
      title: yup.string().required("Title is required"),
      firstname: yup
        .string()
        .required("first name is required")
        .max(32, "first name is too long")
        .trim()
        .matches(
          /^[a-zA-Z]+$/,
          "first name must be alphabetic without spacing"
        ),
      lastname: yup
        .string()
        .required("last name is required")
        .max(32, "last name is too long")
        .trim()
        .matches(/^[a-zA-Z]+$/, "last name must be alphabetic without spacing"),
      mobile: yup.string().required("Phone number is required"),
      address: yup.string().required("Delivery address is required"),
    }),

    onSubmit: (prop) => {
      let formData = prop;
      dispatch(updateUserRequest({ formData, token }));
    },
  });

  useEffect(() => {
    if (updateUserError) {
      toast.error(updateUserError, {
        duration: 3000,
      });
    }
  }, [updateUserError]);

  useEffect(() => {
    if (updateUserSuccess) {
      toast.success(updateUserSuccess, {
        duration: 3000,
      });

      history.push("/login");

      dispatch(clearUpdateUserSuccess());
    }
  }, [updateUserSuccess]);

  useEffect(() => {
    if (user) {
      formik.setValues((values) => ({
        ...values,
        title: user.title,
        firstname: user.firstname,
        lastname: user.lastname,
        mobile: user.mobile,
        address: user.address,
      }));
    }
  }, [user]);

  return (
    <div className="">
      <form className="shadow ">
        {/* pb-[102px] */}
        <div className="flex flex-col p-4 lg:p-8 gap-4 ">
          <div className="flex flex-col">
            <label className="font-medium text-[15px]">Title</label>
            <select
              name="title"
              className="w-full p-[9px_0px_10px_10px] border-black  text-black placeholder-[#666257] border  text-md"
              {...formik.getFieldProps("title")}
            >
              <option>Select Title</option>
              <option value={"Master"}>Master</option>
              <option value={"Mr"}>Mr</option>
              <option value={"Mister"}>Mister</option>
              <option value={"Miss"}>Miss</option>
              <option value={"Mrs"}>Mrs</option>
              <option value={"Mistress"}>Mistress</option>
              <option value={"Sir"}>Sir</option>
            </select>
            {formik.errors.title && formik.touched.title && (
              <FormError message={formik.errors.title} />
            )}
          </div>
          <div className="">
            <label className="font-medium text-[15px]">First Name</label>
            <ContactInput
              inputType="text"
              inputName="firstName"
              inputPlaceholder="First Name"
              color="text-black"
              formik={formik.getFieldProps("firstname")}
            />
            {formik.errors.firstname && formik.touched.firstname && (
              <FormError message={formik.errors.firstname} />
            )}
          </div>
          <div className="">
            <label className="font-medium text-[15px]">Last Name</label>
            <ContactInput
              inputType="text"
              inputName="lastName"
              inputPlaceholder="Last Name"
              color="text-black"
              formik={formik.getFieldProps("lastname")}
            />
            {formik.errors.lastname && formik.touched.lastname && (
              <FormError message={formik.errors.lastname} />
            )}
          </div>
          <div className="">
            <label className="font-medium text-[15px]">Phone Number</label>
            <ContactInput
              inputType="number"
              inputName="phoneNumber"
              inputPlaceholder="090 **** ****"
              color="text-black"
              formik={formik.getFieldProps("mobile")}
            />
            {formik.errors.mobile && formik.touched.mobile && (
              <FormError message={formik.errors.mobile} />
            )}
          </div>
          <div className="">
            <label className="font-medium text-[15px]">Delivery Address</label>
            <ContactInput
              inputType="text"
              inputName="address"
              inputPlaceholder="Delivery Address"
              color="text-black"
              formik={formik.getFieldProps("address")}
            />
            {formik.errors.address && formik.touched.address && (
              <FormError message={formik.errors.address} />
            )}
          </div>

          <div className="w-full lg:w-[50%] mt-5">
            <AdminFormBtn
              action={formik.handleSubmit}
              buttonText={"Update"}
              loading={updateUserLoading}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProfileForm;
