import React from "react";
import ProfileSideNavbar from "./molecule/profileSideNavbar/ProfileSideNavbar";
import ProfileNavbar from "./molecule/profileNavbar/ProfileNavbar";

const ProfileDashboard = ({ children }) => {
  return (
    <div>
      <div className="fixed z-10 w-[100vw]">
        <ProfileNavbar />
      </div>
      <div className="flex relative">
        <div className="w-full px-4 lg:px-0 lg:w-[20vw] h-[20px] fixed lg:h-[100vh]">
          <ProfileSideNavbar />
        </div>
        {/* container mx-auto max-w-5xl px-8 */}
        <div className="fixed right-0 h-[20px] left-0 lg:left-auto">
          <div className="lg:px-0 lg:w-[80vw] mt-[130px] lg:mt-[120px] pb-[130px] h-[100vh] overflow-y-scroll">
            <div className="container mx-auto px-4">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDashboard;
