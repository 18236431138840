import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";

import { PrivateRoute } from "../components/PrivateRoute";
import Home from "./Webpages/Home";
import About from "./Webpages/About";
import Register from "./Webpages/Register/Register";
import Login from "./Webpages/Login/Login";
import ForgotPassword from "./Webpages/Login/ForgotPassword";
import ResetPassword from "./Webpages/Login/ResetPassword";
import ForgotPasswordSucessful from "./Webpages/Login/ForgotPasswordSucessful";
import ConfirmForgotPasswordEmail from "./Webpages/Login/ConfirmForgotPasswordEmail";
import ArtistAndArtPage from "./Webpages/artistAndArtPage/ArtistAndArtPage";
import Events from "./Webpages/Events/Events";
import ContactUs from "./Webpages/ContactUs/ContactUs";
import Auction from "./Webpages/Auction/Auction";
import ArtPage from "./Webpages/artistAndArtPage/ArtPage";

import AdminDashboard from "./AdminDashboard/pages/dashboard/AdminDashboard";
import AdminAllArtist from "./AdminDashboard/pages/adminArtist/AdminAllArtist";
import AllOrder from "./AdminDashboard/pages/allOrder/AllOrder";
import AllArtWork from "./AdminDashboard/pages/allArtWork/AllArtWork";
import Notification from "./AdminDashboard/pages/notification/Notification";
import CreateArtwork from "./AdminDashboard/pages/createArtwork/CreateArtwork";
import CreateArtist from "./AdminDashboard/pages/createArtist/CreateArtist";
import AdminLogin from "./AdminDashboard/pages/login/AdminLogin";
import EditAdminArtist from "./AdminDashboard/pages/editAdminArtist/EditAdminArtist";
// import Webpages from "./Webpages";

// Profile
import Profile from "./profileDashboard/component/profile/Profile";
import ProfileOrder from "./profileDashboard/component/profileOrder/ProfileOrder";
import SingleArtistPage from "./Webpages/newArtistpage/SingleArtistPage";

const Pages = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/about" component={About} />
      <Route exact path="/event-booking" component={Events} />
      {/* <Route exact path='/art-page/:id' component={ArtPage} /> */}
      <Route exact path="/art-page/:id" component={SingleArtistPage} />
      {/* <Route exact path='/single-artist-page' component={SingleArtistPage} /> */}
      <Route exact path="/contact-us" component={ContactUs} />
      <Route exact path="/auction-bidding" component={Auction} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/artist" component={ArtistAndArtPage} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route
        exact
        path="/forgot-password-successful"
        component={ForgotPasswordSucessful}
      />
      <Route
        exact
        path="/confirm-forgot-password-email"
        component={ConfirmForgotPasswordEmail}
      />
      {/* <Route exact path="/" component={Webpages} /> */}

      {/* dashboard   */}
      {/* <Route exact path='/dashboard' component={Dashboard} /> */}
      <Route exact path="/admin" component={AdminLogin} />
      <Route exact path="/admin/dashboard" component={AdminDashboard} />
      <Route exact path="/admin/admin-all-artist" component={AdminAllArtist} />
      <Route exact path="/admin/all-orders" component={AllOrder} />
      <Route exact path="/admin/create-artist" component={CreateArtist} />
      <Route exact path="/admin/create-artwork" component={CreateArtwork} />
      <Route
        exact
        path="/admin/edit-admin-artist/:id"
        component={EditAdminArtist}
      />
      <Route exact path="/admin/admin-all-art-works" component={AllArtWork} />
      <Route exact path="/admin/admin-notification" component={Notification} />

      {/* Profile */}
      <PrivateRoute path="/dashboard/profile" component={Profile} />
      <PrivateRoute exact path="/dashboard/orders" component={ProfileOrder} />
    </Switch>
  );
};

export default Pages;
