import React from "react";
import artImg from '../../assets/bgImg.png';

const ArtistLandingPage = () => {
  return (
    <div className="h-[32vh] md:h-[33vh] lg:h-[90vh] relative">
      <img
        className="w-full absolute h-[35vh] lg:h-[70vh] object-cover"
        src={artImg}
        alt="img"
      />
      <div className="text-white ml-[0px] lg:ml-[70px] md:pb-[0px]  absolute bottom-0 top-[23vh] md:top-[25vh] lg:top-[40vh] left-5 md:left-12 h-[28vh] ">
        <div>
          <p className="text-[10px] md:text-[20px] leading-[13px] w-[231px] lg:leading-[30px] mt-[0px] lg:mt-[15px] lg:w-[70%] font-normal">
            Finest Arts
          </p>
        </div>
        <div>
          <h4 className="font-heading text-[16px] md:text-[30px] lg:text-[40px]">
            Explore Different Piece.
          </h4>
        </div>
      </div>
    </div>
  );
};

export default ArtistLandingPage;
