import React from "react";
import AdminDashboardActivity from "../../Dashboard";
import CreateArtistForm from "../../molecule/adminForm/CreateArtistForm";

const CreateArtist = () => {
  return (
    <div>
      <AdminDashboardActivity>
        <div className='container max-w-4xl  my-16 px-4'>
          <h4 className='font-heading text-xl '>Create Artist</h4>
          <CreateArtistForm buttonText='Create Artist' />
        </div>
      </AdminDashboardActivity>
    </div>
  );
};

export default CreateArtist;
