import React from "react";
import Navbar from "../../navbar/Navbar";
import Footer from "../../footer/Footer";

const LandingLayout = ({ height, children }) => {
  return (
    <div>
      <div>
        <div className={`bg-cover bg-center ${height}`}>
          <Navbar />
          <main>{children}</main>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default LandingLayout;
