import React from "react";
import AdminNavbar from "./molecule/adminNavbar/AdminNavbar";
import AdminSideNavbar from "./molecule/adminSideNavbar/AdminSideNavbar";

const index = ({ children }) => {
  return (
    <div>
      <div className=" z-10 w-full">
        <AdminNavbar />
      </div>
      <div className="lg:flex relative">
        <div className="w-full lg:px-0  lg:w-[20vw] h-[20px]  lg:h-[100vh]">
          <AdminSideNavbar />
        </div>
        {/* container mx-auto max-w-5xl px-8 */}
        <div className="pt-10 lg:pt-0 lg:px-0 container mx-auto px-4  pb-[130px] h-[100vh] overflow-y-scroll">
          {children}
        </div>
      </div>
    </div>
  );
};

export default index;
