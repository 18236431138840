import React from "react";

const ContactInput = ({
  inputType,
  inputName,
  inputPlaceholder,
  color = "",
  onChange,
  formik,
}) => {
  return (
    <div>
      <div>
        <input
          type={inputType}
          name={inputName}
          id="input"
          placeholder={inputPlaceholder}
          onChange={onChange}
          {...formik}
          // width: "600px", height: "60px",
          className={`w-full p-[9px_0px_10px_10px] bg-white ${color} placeholder-[#918B83] border border-[#413E3A] text-[12px] lg:text-[14px]`}
          autoComplete="off"
        />
      </div>
    </div>
  );
};

export default ContactInput;
