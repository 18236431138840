import React from "react";
import LandingLayout from "../../../components/layout/landingLayout/LandingLayout";
import AboutLanding from "../../../molecules/aboutLanding/AboutLanding";
import ContactForm from "../../../molecules/contactForm/ContactForm";
import RhythmOfExpression from "../../../molecules/rhythmOfExpression/RhythmOfExpression";

const ContactUs = () => {
  return (
    <div>
      <LandingLayout>
        <AboutLanding />
        <div className='container mx-auto px-8 max-w-5xl '>
          <div>
            <h4 className='font-heading text-[15px] lg:text-[25px] lg:leading-[41px] mb-[12px] lg:mb-[20px] text-black '>
              Write Email
            </h4>
          </div>
          <ContactForm />
        </div>
        <div>
          <RhythmOfExpression
            left='left'
            backgroundImage={'bg-manWithLongEye'}
          />
        </div>
      </LandingLayout>
    </div>
  );
};

export default ContactUs;
