import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { toast } from 'react-toastify';
import Modal from '@mui/material/Modal';
import {
  clearDeleteArtistSuccess,
  deleteArtistRequest,
  getArtistsRequest,
} from '../../../../redux/action/artist';
// import { getArtistsRequest } from '../../../../redux/action/getArtists';

import AdminTableHead from '../adminTableLayout/AdminTableHead';
import AdminTableWrapper from '../adminTableLayout/AdminTableWrapper';
import ContactButton from '../../../../atoms/button/ContactButton';
import Button from '../../../../atoms/button/Button';
// import { deleteArtistRequest } from '../../../../redux/action/deleteArtist';

const AdminAllArtistTable = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  const {
    getArtistsSuccess,
    getArtistsError,
    getArtistsLoading,
    getArtists,
    deleteArtistSuccess,
    deleteArtistLoading,
    deleteArtistError,
  } = useSelector((state) => {
    const {
      success: {
        getArtists: getArtistsSuccess,
        deleteArtist: deleteArtistSuccess,
      },
      errors: { getArtists: getArtistsError, deleteArtist: deleteArtistError },
    } = state.ajaxStatuses;
    // GET artists
    const { getArtistsLoading, deleteArtistLoading } = state.loadingIndicator;
    const getArtists = state.artistData.getArtists;

    return {
      getArtistsSuccess,
      getArtistsError,
      getArtistsLoading,
      getArtists,
      deleteArtistLoading,
      deleteArtistSuccess,
      deleteArtistError,
    };
  });

  const deleteArtistFunc = (id) => {
    // dispatch(deleteArtistRequest());
    dispatch(deleteArtistRequest({ artist_id: id }));
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getArtistsRequest());
  }, [getArtistsRequest]);

  // useEffect(() => {
  //   if (deleteArtistLoading) {
  //     dispatch(deleteArtistRequest());
  //   }
  // }, [deleteArtistRequest]);

  useEffect(() => {
    if (deleteArtistSuccess) {
      toast.success(deleteArtistSuccess, {
        duration: 3000,
      });
      dispatch(getArtistsRequest());
      dispatch(clearDeleteArtistSuccess());
    }
  }, [deleteArtistSuccess]);

  const tableHeaders = [
    { title: 'Name', width: '20%' },
    { title: 'Email address', width: '30%' },
    { title: 'Phone number', width: '50%' },
    { title: 'view', width: '30%' },
    { title: '', width: '30%' },
  ];
  return (
    <table className='relative h-screen z-10'>
      <AdminTableWrapper>
        <AdminTableHead headers4={tableHeaders}>
          {getArtistsLoading ? (
            <tr className='absolute flex justify-center items-center mx-auto my-11 left-[40%]'>
              <td className=''>
                <CircularProgress size={20} color={'inherit'} />
              </td>
            </tr>
          ) : getArtists.length === 0 ? (
            <tr>
              <td
                colSpan='3'
                className='text-center p-4 font-heading my-11 text-xl'
              >
                No artist here
              </td>
            </tr>
          ) : (
            getArtists.map((artist, index) => {
              return (
                <tr
                  className='border-b-2 border-[#C4C2BA] hover:bg-[#dcdcdc]'
                  key={index}
                >
                  <td className='whitespace-nowrap p-4 '>
                    {artist.firstname} {artist.lastname}
                  </td>
                  <td className='p-4'> {artist.email}</td>
                  {/* <td className='p-4'>{artist.category}</td> */}
                  <td className='p-4'>{artist.mobile}</td>
                  <td className=''>
                    <Link to={`/admin/edit-admin-artist/${artist._id}`}>
                      <ContactButton
                        buttonText={'edit'}
                        padding='lg:p-[10px_25px_10px_25px] p-[10px_10px_10px_10px]'
                      />
                    </Link>
                  </td>
                  <td className='p-4'>
                    <Button
                      action={handleOpen}
                      buttonText={'delete'}
                      color={'border-[red] text-[red]'}
                      padding='lg:p-[10px_25px_10px_25px] p-[10px_10px_10px_10px]'
                    />
                    {/*  */}
                  </td>
                  <td>
                    {' '}
                    {open && (
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby='parent-modal-title'
                        aria-describedby='parent-modal-description'
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                        className='bg-white bg-opacity-5'
                      >
                        <div
                          //  sx={style}
                          className='bg-white h-[30vh] lg:mx-8 rounded-md flex justify-center flex-col'
                        >
                          <div>
                            <h5 className='font-heading text-sm lg:text-xl w-[80%] mx-auto pt-8'>
                              {/* Do you want to delete {item.firstname}{' '}
                              {item.lastname} ? */}
                              Do you want to delete this Artist?
                            </h5>
                          </div>
                          <div className='flex justify-center flex-col lg:flex-row items-center lg:gap-[45px] gap-[10px] my-8'>
                            <div className='w-[200px]'>
                              <Button
                                action={handleClose}
                                color={'border-[green] text-[green]'}
                                buttonText={'cancel'}
                              />
                            </div>
                            <div className='w-[200px]'>
                              <Button
                                action={() => deleteArtistFunc(artist._id)}
                                buttonText={'delete'}
                                color={'border-[red] text-[red]'}
                                // loading={deleteArtistLoading}
                                padding='lg:p-[10px_25px_10px_25px] p-[10px_10px_10px_10px]'
                              />
                            </div>
                          </div>
                        </div>
                      </Modal>
                    )}
                  </td>
                </tr>
              );
            })
          )}
        </AdminTableHead>
        {/* </div> */}
      </AdminTableWrapper>
    </table>
  );
};

export default AdminAllArtistTable;
