import React from "react";

const WhatWeOffer = ({ img, paragraph, header }) => {
  return (
    <div className="my-[4px] flex h-[23vh] w-[40vw] flex-wrap items-center bg-[#F1F0EE] p-4 md:h-[30vh] lg:my-3 lg:w-[36vw] lg:gap-[25px] lg:p-8">
      <div>
        <p className="w-full pb-3 text-xs lg:max-w-[180px] lg:pb-8 lg:text-xl lg:font-bold">
          {/* On-site art tour and consultation */}
          {/* Gallery Appraisal */}
          {header}
        </p>
        <p className="hidden text-base font-normal text-mainBlack md:block">
          {/* On-site art tour and consultation */}
          {paragraph}
        </p>
      </div>
      <div className="mx-auto">
        <img
          src={img}
          alt="img"
          className="h-[70px] w-[70px] lg:h-[100px] lg:w-[105px]"
        />
      </div>
    </div>
  );
};

export default WhatWeOffer;
