import {
  CLEAR_CREATE_ARTWORK_SUCCESS,
  CLEAR_REGISTER_ARTIST_SUCCESS,
  CLEAR_CREATE_ORDER_SUCCESS,
  CREATE_ARTWORK_FAIL,
  CREATE_ARTWORK_SUCCESS,
  CREATE_ORDER_FAIL,
  CREATE_ORDER_SUCCESS,
  GET_ARTWORKS_FAIL,
  GET_ARTWORKS_SUCCESS,
  GET_ARTWORK_FAIL,
  GET_ARTWORK_SUCCESS,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  REGISTER_ARTIST_FAIL,
  REGISTER_ARTIST_SUCCESS,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  GET_USER_ORDER_FAIL,
  GET_USER_ORDER_SUCCESS,
  CLEAR_LOGIN_SUCCESS,
  CLEAR_REGISTER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
  CLEAR_UPDATE_USER_SUCCESS,
  GET_ARTISTS_FAIL,
  GET_ARTISTS_SUCCESS,
  DELETE_ARTIST_FAIL,
  DELETE_ARTIST_SUCCESS,
  CLEAR_DELETE_ARTIST_SUCCESS,
  GET_SINGLE_ARTIST_FAIL,
  GET_SINGLE_ARTIST_SUCCESS,
  EDIT_SINGLE_ARTIST_FAIL,
  EDIT_SINGLE_ARTIST_SUCCESS,
  CLEAR_GET_ARTWORKS_SUCCESS,
  CLEAR_GET_ARTISTS_SUCCESS,
  CLEAR_GET_SINGLE_ARTIST_SUCCESS,
  CLEAR_GET_ARTWORK_SUCCESS,
  CLEAR_EDIT_SINGLE_ARTIST_SUCCESS,
  DELETE_ARTWORK_FAIL,
  DELETE_ARTWORK_SUCCESS,
  CLEAR_DELETE_ARTWORK_SUCCESS,
} from '../types';

const initialState = {
  errors: {},
  success: {},
};

const ajaxStatuses = (state = initialState, { type, payload }) => {
  switch (type) {
    //user error state
    case REGISTER_FAIL:
      state.errors.register = payload.errors;
      return { ...state };
    case REGISTER_SUCCESS:
      state.success.register = payload.message;
      return { ...state };
    case CLEAR_REGISTER_SUCCESS:
      state.success.register = "";
      return { ...state };

    case UPDATE_USER_FAIL:
      state.errors.updateUser = payload.errors;
      return { ...state };
    case UPDATE_USER_SUCCESS:
      state.success.updateUser = payload.message;
      return { ...state };
    case CLEAR_UPDATE_USER_SUCCESS:
      state.success.updateUser = "";
      return { ...state };

    case LOGIN_FAIL:
      state.errors.login = payload.errors;
      return { ...state };
    case LOGIN_SUCCESS:
      state.success.login = payload.message;
      return { ...state };
    case CLEAR_LOGIN_SUCCESS:
      state.success.login = "";
      return { ...state };

    //Artwork error state
    case CREATE_ARTWORK_FAIL:
      state.errors.createArtwork = payload.errors;
      return { ...state };
    case CREATE_ARTWORK_SUCCESS:
      state.success.createArtwork = payload.message;
      return { ...state };
    case CLEAR_CREATE_ARTWORK_SUCCESS:
      state.success.createArtwork = "";
      return { ...state };
    // Register Artist
    case REGISTER_ARTIST_FAIL:
      state.errors.registerArtist = payload.errors;
      return { ...state };
    case REGISTER_ARTIST_SUCCESS:
      state.success.registerArtist = payload.message;
      return { ...state };
    case CLEAR_REGISTER_ARTIST_SUCCESS:
      state.success.registerArtist = "";
      return { ...state };

    // get artists
    case GET_ARTISTS_FAIL:
      state.errors.getArtists = payload.errors;
      return { ...state };
    case GET_ARTISTS_SUCCESS:
      state.success.getArtists = payload.message;
      return { ...state };
    case CLEAR_GET_ARTISTS_SUCCESS:
      state.success.getArtists = "";
      return { ...state };

    // get single artist
    case GET_SINGLE_ARTIST_FAIL:
      state.errors.getSingleArtist = payload.errors;
      return { ...state };
    case GET_SINGLE_ARTIST_SUCCESS:
      state.success.getSingleArtist = payload.message;
      return { ...state };
    case CLEAR_GET_SINGLE_ARTIST_SUCCESS:
      state.success.getSingleArtist = "";
      return { ...state };

    // DELETE single artist
    case DELETE_ARTIST_FAIL:
      state.errors.deleteArtist = payload.errors;
      return { ...state };
    case DELETE_ARTIST_SUCCESS:
      state.success.deleteArtist = payload.message;
      return { ...state };
    case CLEAR_DELETE_ARTIST_SUCCESS:
      state.success.deleteArtist = "";
      return { ...state };

    // get ALL artworks
    case GET_ARTWORKS_FAIL:
      state.errors.getArtworks = payload.errors;
      return { ...state };
    case GET_ARTWORKS_SUCCESS:
      state.success.getArtworks = payload.message;
      return { ...state };
    case CLEAR_GET_ARTWORKS_SUCCESS:
      state.success.getArtworks = "";
      return { ...state };

    // get single artwork
    case GET_ARTWORK_FAIL:
      state.errors.getArtwork = payload.errors;
      return { ...state };
    case GET_ARTWORK_SUCCESS:
      state.success.getArtwork = payload.message;
      return { ...state };
    case CLEAR_GET_ARTWORK_SUCCESS:
      state.success.getArtwork = "";
      return { ...state };

    // DELETE single artwork
    case DELETE_ARTWORK_FAIL:
      state.errors.deleteArtwork = payload.errors;
      return { ...state };
    case DELETE_ARTWORK_SUCCESS:
      state.success.deleteArtwork = payload.message;
      return { ...state };
    case CLEAR_DELETE_ARTWORK_SUCCESS:
      state.success.deleteArtist = "";
      return { ...state };

    // edit single artist
    case EDIT_SINGLE_ARTIST_FAIL:
      state.errors.editSingleArtist = payload.errors;
      return { ...state };
    case EDIT_SINGLE_ARTIST_SUCCESS:
      state.success.editSingleArtist = payload.message;
      return { ...state };
    case CLEAR_EDIT_SINGLE_ARTIST_SUCCESS:
      state.success.editSingleArtist = "";
      return { ...state };

    //Order error state
    case CREATE_ORDER_FAIL:
      state.errors.createOrder = payload.errors;
      return { ...state };
    case CREATE_ORDER_SUCCESS:
      state.success.createOrder = payload.message;
      return { ...state };
    case CLEAR_CREATE_ORDER_SUCCESS:
      state.success.createOrder = "";
      return { ...state };

    case GET_USER_ORDER_FAIL:
      state.errors.getUserOrder = payload.errors;
      return { ...state };
    case GET_USER_ORDER_SUCCESS:
      state.success.getUserOrder = payload.message;
      return { ...state };

    default:
      return state;
  }
};

export default ajaxStatuses;
