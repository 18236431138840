import React from 'react'
import AdminDashboardActivity from '../../Dashboard'
import AdminAllOrders from '../../molecule/adminTable/AdminAllOrders'

const AllOrder = () => {
  return (
    <div>
      <div>
        <AdminDashboardActivity>
          <div className='container max-w-4xl mx-auto my-16'>
            <h4 className='font-heading lg:text-[25px] lg:leading-[41px] mb-[12px] lg:mb-[20px] text-black'>
              All Orders
            </h4>
            <div>
              <AdminAllOrders />
            </div>
          </div>
        </AdminDashboardActivity>
      </div>
    </div>
  )
}

export default AllOrder
