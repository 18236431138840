import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link, useHistory, useLocation } from "react-router-dom";
import { clearLoginSuccess, loginRequest } from "../../../redux/action/auth";
import { FormError } from "../../../components/formError";
import AuthNavbar from "../../../components/navbar/AuthNavbar";
import Input from "../../../atoms/input/Input";
import LoginRegBtn from "../../../atoms/button/LoginRegBtn";

const Login = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { loginSuccess, loginError, loginLoading, user, singleUser } =
    useSelector((state) => {
      const {
        success: { login: loginSuccess },
        errors: { login: loginError },
      } = state.ajaxStatuses;

      const { loginLoading } = state.loadingIndicator;

      const { user } = state.userData;

      return {
        loginSuccess,
        loginError,
        loginLoading,
        user,
      };
    });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("Email is not valid")
        .required("Email is required"),
      password: yup.string().required("Password is required"),
    }),

    onSubmit: (prop) => {
      let formData = prop;

      dispatch(loginRequest({ formData }));
    },
  });

  useEffect(() => {
    if (loginError) {
      toast.error(loginError, {
        duration: 3000,
      });
    }
  }, [loginError]);

  useEffect(() => {
    if (loginSuccess) {
      history.push("/dashboard/profile");
      dispatch(clearLoginSuccess());
    }
  }, [loginSuccess]);

  return (
    <div className="h-[100vh] overflow-scroll bg-[#000000]">
      <AuthNavbar />
      <div className="container mx-auto max-w-3xl px-4 py-10">
        <div>
          <h3 className="font-heading text-2xl text-white lg:text-4xl">
            Welcome to Kachi James Art Gallery
          </h3>
          <p className="py-4 font-body text-lg text-white lg:py-8 lg:text-xl">
            Log in to access your dashboard.
          </p>
        </div>
        <div className="">
          <form autoComplete="off">
            <div className="mt-5 flex flex-col gap-y-2">
              <Input
                inputType="email"
                inputName="email"
                inputPlaceholder="Email address"
                formik={formik.getFieldProps("email")}
              />
              {formik.errors.email && formik.touched.email && (
                <FormError message={formik.errors.email} />
              )}
            </div>
            <div className="mt-5 flex flex-col gap-y-2">
              <Input
                inputType="password"
                inputName="password"
                inputPlaceholder="Password"
                formik={formik.getFieldProps("password")}
              />

              {formik.errors.password && formik.touched.password && (
                <FormError message={formik.errors.password} />
              )}
            </div>
            <div>
              <p className="mt-2 text-end text-sm text-white">
                Forgot password?
              </p>
            </div>
            <Link to="/register">
              <p className="mt-2 text-end text-sm text-white">
                Don't have an account? Sign up
              </p>
            </Link>
          </form>
        </div>
        <div className="mt-8 w-40">
          <LoginRegBtn
            action={formik.handleSubmit}
            loading={loginLoading}
            buttonText="Log in"
            padding=""
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
