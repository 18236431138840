import React from 'react'
import { useParams } from 'react-router';
import AdminDashboardActivity from '../../Dashboard';
import EditArtistForm from '../../molecule/adminForm/EditArtistForm';


const EditAdminArtist = () => {
    const { id } = useParams();
    // console.log(id,"useParams id")
    //  useEffect(() => {
    //    dispatch(editArtistRequest(id));
    //  }, [getArtworkRequest]);
  return (
    <div>
      <AdminDashboardActivity>
        <div className='container max-w-4xl  my-16 px-4'>
          <h4 className='font-heading text-xl'>Edit Artist</h4>
          <EditArtistForm buttonText='Update Artist' id={id}/>
        </div>
      </AdminDashboardActivity>
    </div>
  );
}

export default EditAdminArtist
