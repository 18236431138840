import React from "react";
import LandingLayout from "../../../components/layout/landingLayout/LandingLayout";
import ArtistCardTwo from "../../../molecules/artistCardTwo/ArtistCardTwo";
import RhythmOfExpression from "../../../molecules/rhythmOfExpression/RhythmOfExpression";

import PartnershipImg from "../../../assets/partnershipLogo.png";
import entrepreneurImg from "../../../assets/entrepreneurImg.png";
import AboutTeam from "../../../molecules/aboutTeam/AboutTeam";
import AboutLanding from "../../../molecules/aboutLanding/AboutLanding";

const About = () => {
  return (
    <div>
      <LandingLayout height={""}>
        <div className="">
          <AboutLanding />
          {/*  */}
          <div className="container max-w-5xl mx-auto px-8">
            <div className="grid md:grid-cols-2 lg:mt-[0px] md:gap-7 lg:gap-[0px] mb-[50px] lg:mb-[100px]">
              <div>
                <div>
                  <h4 className="text-[26px] leading-[30px] lg:leading-[75px] font-heading lg:text-[70px] lg:max-w-[400px]">
                    Kachi James Aghasili
                  </h4>
                </div>
                <div>
                  <div className="mt-[24px] lg:mt-[34px] text-[14px] lg:text-[20px] lg:leading-[25px] max-w-[400px]">
                    Kachi Aghasil is a tech entrepreneur, business woman and
                    practicing attorney in the United States.
                    <p className="pt-4">
                      A graduate of Emory University with an M.Sc from Purdue
                      University and a Juris Doctorate from Harvard Law School.
                    </p>
                    <p className="pt-4">
                      She has ventured and excelled in different business fields
                      including but not limited to Client Investment, Insurance
                      Brokerage and Mid-Stream Crude Oil Haulage
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-[30px] md:mt-[0px]">
                <img
                  src={entrepreneurImg}
                  alt="entrepreneur Img"
                  className="w-full "
                />
              </div>
            </div>
          </div>

          {/* <div className="container mx-auto max-w-4xl px-8 lg:px-0"> */}
          {/* <div>
              <h4 className="font-heading lg:text-[25px] lg:leading-[41px] mb-[12px] lg:mb-[30px]">
                Team
              </h4>
            </div> */}
          {/* <div className="grid grid-cols-2 gap-[10px] lg:gap-[30px]">
              <div>
                <AboutTeam>
                  <div className="bg-[#070707] text-white bg-opacity-40 pl-[14px] py-[8px] md:py-[25px] lg:pl-[47px] lg:py-[25px] align-bottom w-full">
                    <p className="text-[10px] leading-[16px] md:text-[15px] lg:text-[25px] lg:leading-[50px] font-heading whitespace-nowrap">
                      Jacob Kadiri
                    </p>
                    <p className="text-[8px] leading-[12px] md:leading-[25px] md:text-[13px] lg:text-[18px] lg:leading-[13px] lg:mt-[0px]">
                      Art Curator
                    </p>
                  </div>
                </AboutTeam>
              </div>
              <div>
                <AboutTeam>
                  <div className="bg-[#070707] text-white bg-opacity-40 pl-[14px] py-[8px] md:py-[25px] lg:pl-[47px] lg:py-[25px] align-bottom w-full">
                    <p className="text-[10px] leading-[16px] md:text-[15px] lg:text-[25px] lg:leading-[50px] font-heading whitespace-nowrap">
                      Jacob Kadiri
                    </p>
                    <p className="text-[8px] leading-[12px] md:leading-[25px] md:text-[13px] lg:text-[18px] lg:leading-[13px] lg:mt-[0px]">
                      Art Curator
                    </p>
                  </div>
                </AboutTeam>
              </div>
            </div> */}

          {/* secound card */}
          {/* <div className="grid grid-cols-3 gap-[10px] mt-[10px] lg:mt-[30px]">
              <div>
                <AboutTeam>
                  <div className="bg-[#070707] text-white bg-opacity-40 pl-[10px] py-[5px] md:py-[15px] lg:pl-[20px] lg:py-[20px] align-bottom w-full">
                    <p className="text-[8px] leading-[16px] md:text-[15px] lg:text-[20px] lg:leading-[50px] font-heading whitespace-nowrap">
                      Jacob Kadiri
                    </p>
                    <p className="text-[7px] leading-[8px] md:leading-[25px] md:text-[13px] lg:text-[15px] lg:leading-[13px] lg:mt-[0px]">
                      Art Curator
                    </p>
                  </div>
                </AboutTeam>
              </div>
              <div>
                <AboutTeam>
                  <div className="bg-[#070707] text-white bg-opacity-40 pl-[10px] py-[5px] md:py-[15px] lg:pl-[20px] lg:py-[20px] align-bottom w-full">
                    <p className="text-[8px] leading-[16px] md:text-[15px] lg:text-[20px] lg:leading-[50px] font-heading whitespace-nowrap">
                      Jacob Kadiri
                    </p>
                    <p className="text-[7px] leading-[8px] md:leading-[25px] md:text-[13px] lg:text-[15px] lg:leading-[13px] lg:mt-[0px]">
                      Art Curator
                    </p>
                  </div>
                </AboutTeam>
              </div>
              <div>
                <AboutTeam>
                  <div className="bg-[#070707] text-white bg-opacity-40 pl-[10px] py-[5px] md:py-[15px] lg:pl-[20px] lg:py-[20px] align-bottom w-full">
                    <p className="text-[8px] leading-[16px] md:text-[15px] lg:text-[20px] lg:leading-[50px] font-heading whitespace-nowrap">
                      Jacob Kadiri
                    </p>
                    <p className="text-[7px] leading-[8px] md:leading-[25px] md:text-[13px] lg:text-[15px] lg:leading-[13px] lg:mt-[0px]">
                      Art Curator
                    </p>
                  </div>
                </AboutTeam>
              </div>
            </div> */}
          {/* </div> */}

          <div>
            <div className="container mx-auto max-w-4xl px-8 lg:px-0">
              <p className="font-heading lg:text-[25px] lg:leading-[41px] mb-[12px] lg:mb-[30px] pt-[30px] lg:pt-[50px]">
                Partnership
              </p>
            </div>
            <div className="flex flex-row 2xl:justify-center gap-[15px] overflow-x-scroll">
              {/*  */}
              <div className="w-[200px] md:w-[316px] bg-[#1B1A18] lg:w-[350px] h-[24vh] md:h-[20vh] lg:h-[50vh]">
                <div className="text-white lg:text-[25px] w-[175px] lg:w-[340px] font-heading pt-[30px] lg:pt-[50px] text-center">
                  <p>National Art Gallery Enugu</p>
                </div>
                <div className="flex justify-end w-[216px] lg:w-[300px] mt-[20px] lg:mt-[36px] pr-[58px] lg:pr-0">
                  <img
                    src={PartnershipImg}
                    alt="partnership logo"
                    className="w-[67px] h-[67px] lg:w-[100px] lg:h-[100px]"
                  />
                </div>
              </div>
              {/*  */}
              <div className="w-[200px] md:w-[316px] bg-[#1B1A18] lg:w-[350px] h-[24vh] md:h-[20vh] lg:h-[50vh]">
                <div className="text-white lg:text-[25px] w-[175px] lg:w-[340px] font-heading pt-[30px] lg:pt-[50px] text-center">
                  <p>National Art Gallery Enugu</p>
                </div>
                <div className="flex justify-end w-[216px] lg:w-[300px] mt-[20px] lg:mt-[36px] pr-[58px] lg:pr-0">
                  <img
                    src={PartnershipImg}
                    alt="partnership logo"
                    className="w-[67px] h-[67px] lg:w-[100px] lg:h-[100px]"
                  />
                </div>
              </div>
              {/*  */}
              <div className="w-[200px] md:w-[316px] bg-[#1B1A18] lg:w-[350px] h-[24vh] md:h-[20vh] lg:h-[50vh]">
                <div className="text-white lg:text-[25px] w-[175px] lg:w-[340px] font-heading pt-[30px] lg:pt-[50px] text-center">
                  <p>National Art Gallery Enugu</p>
                </div>
                <div className="flex justify-end w-[216px] lg:w-[300px] mt-[20px] lg:mt-[36px] pr-[58px] lg:pr-0">
                  <img
                    src={PartnershipImg}
                    alt="partnership logo"
                    className="w-[67px] h-[67px] lg:w-[100px] lg:h-[100px]"
                  />
                </div>
              </div>
              {/*  */}
              <div className="w-[200px] md:w-[316px] bg-[#1B1A18] lg:w-[350px] h-[24vh] md:h-[20vh] lg:h-[50vh]">
                <div className="text-white lg:text-[25px] w-[175px] lg:w-[340px] font-heading pt-[30px] lg:pt-[50px] text-center">
                  <p>National Art Gallery Enugu</p>
                </div>
                <div className="flex justify-end w-[216px] lg:w-[300px] mt-[20px] lg:mt-[36px] pr-[58px] lg:pr-0">
                  <img
                    src={PartnershipImg}
                    alt="partnership logo"
                    className="w-[67px] h-[67px] lg:w-[100px] lg:h-[100px]"
                  />
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="container mx-auto max-w-4xl px-8 lg:px-0">
            <p className="font-heading lg:text-[25px] lg:leading-[41px] mb-[12px] lg:mb-[30px] pt-[30px] lg:pt-[50px]">
              Featured Art
            </p>
          </div>
          {/*  */}
          <div>
            <ArtistCardTwo />
          </div>
          {/*  */}
          <div className="lg:mb-[100px] mb-[50px]">
            <RhythmOfExpression
              left="left"
              backgroundImage={"bg-manWithLongEye"}
            />
          </div>
        </div>
      </LandingLayout>
    </div>
  );
};

export default About;
