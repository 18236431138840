import {
  CLEAR_CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAIL,
  CREATE_ORDER_LOADING,
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
  GET_USER_ORDER_FAIL,
  GET_USER_ORDER_LOADING,
  GET_USER_ORDER_REQUEST,
  GET_USER_ORDER_SUCCESS,
} from "../types";

export const createOrderRequest = (prop) => {
  return {
    type: CREATE_ORDER_REQUEST,
    payload: prop,
  };
};

export const createOrderSuccess = (prop) => {
  return {
    type: CREATE_ORDER_SUCCESS,
    payload: prop,
  };
};

export const createOrderFailure = (errors) => {
  return {
    type: CREATE_ORDER_FAIL,
    payload: { errors },
  };
};

export const createOrderLoading = (loading) => {
  return {
    type: CREATE_ORDER_LOADING,
    payload: loading,
  };
};

export const clearCreateOrderSuccess = (prop) => {
  return {
    type: CLEAR_CREATE_ORDER_SUCCESS,
    payload: prop,
  };
};

export const getUserOrderRequest = (prop) => {
  return {
    type: GET_USER_ORDER_REQUEST,
    payload: prop,
  };
};

export const getUserOrderSuccess = (prop) => {
  return {
    type: GET_USER_ORDER_SUCCESS,
    payload: prop,
  };
};

export const getUserOrderFailure = (errors) => {
  return {
    type: GET_USER_ORDER_FAIL,
    payload: { errors },
  };
};

export const getUserOrderLoading = (loading) => {
  return {
    type: GET_USER_ORDER_LOADING,
    payload: loading,
  };
};
