import React from "react";
import LandingLayout from "../../../components/layout/landingLayout/LandingLayout";
import RhythmOfExpression from "../../../molecules/rhythmOfExpression/RhythmOfExpression";
import AboutLanding from "../../../molecules/aboutLanding/AboutLanding";
import ArtAuction from "../../../molecules/artAuction/ArtAuction";
import ContactButton from "../../../atoms/button/ContactButton";
const Auction = () => {
  return (
    <div>
      <LandingLayout>
        <div>
          <AboutLanding />
        </div>
        <div className="container mx-auto max-w-5xl">
          <h4 className="mb-[12px] pl-5 font-heading text-black md:pl-[50px] lg:mb-[20px] lg:pl-0 lg:text-[25px] lg:leading-[41px]">
            Arts On Auction
          </h4>
        </div>
        {/*  */}
        <div className="container mx-auto max-w-5xl px-4 lg:px-0">
          <div className="grid grid-cols-2 gap-[0px] md:gap-[15px] lg:gap-[20px]">
            <div className="col-span-2 max-md:pb-4 md:col-span-1">
              <div>
                <ArtAuction>
                  <div className="flex justify-end">
                    <div className="absolute top-0 h-[100%] w-[180px] bg-[#100F0E] text-start text-white lg:h-[75vh] lg:w-[250px]">
                      <div className="relative left-9 top-[70px] w-[140px] lg:top-[40px] lg:w-[180px]">
                        <div className="w-[100px] lg:mx-auto lg:w-full">
                          <div>
                            <p className="font-heading text-[18px] tracking-normal lg:text-[28px] lg:leading-[40px]">
                              Cloud Romance
                            </p>
                          </div>
                          <div className="text-left">
                            <div className="pt-3 text-[13px] font-normal lg:pt-1 lg:text-[15px]">
                              By
                            </div>
                            <div className="pt-1 text-[13px] font-semibold tracking-wide lg:text-[15px]">
                              Emily Kadiri
                            </div>
                          </div>
                        </div>

                        <div className="py-[30px] lg:py-[70px]">
                          <p className="w-[100px] border border-[#FAFAFA] bg-[#7a7a7a] p-1 text-center">
                            #230,000
                          </p>
                        </div>
                        <div className="grid grid-cols-2">
                          <div className="col-span-2 text-white">
                            <p className="text-[13px]">Bidding stops in</p>
                            <p className="text-[20px] font-bold lg:text-[25px]">
                              48 : 12 : 54
                            </p>
                          </div>
                          <div className="col-span-2 pt-4 lg:pt-2">
                            <ContactButton
                              buttonText="Bid Now"
                              padding="lg:p-[10px_30px_10px_30px] p-[10px_20px_10px_20px]"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ArtAuction>
              </div>
            </div>
            <div className="col-span-2 md:col-span-1">
              <div>
                <ArtAuction>
                  <div className="flex justify-end">
                    <div className="absolute top-0 h-[100%] w-[180px] bg-[#100F0E] text-start text-white lg:h-[75vh] lg:w-[250px]">
                      <div className="relative left-9 top-[70px] w-[140px] lg:top-[40px] lg:w-[180px]">
                        <div className="w-[100px] lg:mx-auto lg:w-full">
                          <div>
                            <p className="font-heading text-[18px] tracking-normal lg:text-[28px] lg:leading-[40px]">
                              Cloud Romance
                            </p>
                          </div>
                          <div className="text-left">
                            <div className="pt-3 text-[13px] font-normal lg:pt-1 lg:text-[15px]">
                              By
                            </div>
                            <div className="pt-1 text-[13px] font-semibold tracking-wide lg:text-[15px]">
                              Emily Kadiri
                            </div>
                          </div>
                        </div>

                        <div className="py-[30px] lg:py-[70px]">
                          <p className="w-[100px] border border-[#FAFAFA] bg-[#7a7a7a] p-1 text-center">
                            #230,000
                          </p>
                        </div>
                        <div className="grid grid-cols-2">
                          <div className="col-span-2 text-white">
                            <p className="text-[13px]">Bidding stops in</p>
                            <p className="text-[20px] font-bold lg:text-[25px]">
                              48 : 12 : 54
                            </p>
                          </div>
                          <div className="col-span-2 pt-4 lg:pt-2">
                            <ContactButton
                              buttonText="Bid Now"
                              padding="lg:p-[10px_30px_10px_30px] p-[10px_20px_10px_20px]"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ArtAuction>
              </div>
            </div>
          </div>
        </div>
        {/*long card  */}
        <div className="mt-4">
          <ArtAuction>
            <div className="flex justify-end">
              <div className="absolute top-0 h-[100%] w-[200px] bg-[#100F0E] text-start text-white md:w-[400px] lg:h-[100%] lg:w-[490px]">
                <div className="relative left-12 top-[70px] w-[140px] md:w-[250px] lg:w-[350px]">
                  <div className="w-[100px] lg:mx-auto lg:w-full">
                    <div>
                      <p className="font-heading text-[18px] tracking-normal lg:text-[28px]">
                        Cloud Romance
                      </p>
                    </div>
                    <div className="text-left">
                      <div className="pt-1 text-[13px] font-normal lg:text-[15px]">
                        By
                      </div>
                      <div className="pt-1 text-[13px] font-semibold tracking-wide lg:text-[15px]">
                        Emily Kadiri
                      </div>
                    </div>
                  </div>

                  <div className="py-[50px] lg:py-[100px]">
                    <p className="w-[100px] border border-[#FAFAFA] bg-[#7a7a7a] p-1 text-center">
                      #230,000
                    </p>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="col-span-2 text-white md:col-span-1">
                      <p className="text-[13px]">Bidding stops in</p>
                      <p className="text-[20px] font-bold lg:text-[25px]">
                        48 : 12 : 54
                      </p>
                    </div>
                    <div className="col-span-2 pt-3 md:col-span-1 lg:pt-0">
                      <ContactButton
                        buttonText="Bid Now"
                        padding="lg:p-[10px_30px_10px_30px] p-[10px_25px_10px_25px]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ArtAuction>
        </div>
        {/*  */}
        <div className="mt-4">
          <ArtAuction>
            <div className="flex justify-start">
              <div className="absolute top-0 h-[100%] w-[200px] bg-[#100F0E] text-start text-white md:w-[400px] lg:h-[100%] lg:w-[490px]">
                <div className="relative left-12 top-[70px] w-[140px] md:w-[250px] lg:w-[350px]">
                  <div className="w-[100px] lg:mx-auto lg:w-full">
                    <div>
                      <p className="font-heading text-[18px] tracking-normal lg:text-[28px]">
                        Cloud Romance
                      </p>
                    </div>
                    <div className="text-left">
                      <div className="pt-1 text-[13px] font-normal lg:text-[15px]">
                        By
                      </div>
                      <div className="pt-1 text-[13px] font-semibold tracking-wide lg:text-[15px]">
                        Emily Kadiri
                      </div>
                    </div>
                  </div>

                  <div className="py-[50px] lg:py-[100px]">
                    <p className="w-[100px] border border-[#FAFAFA] bg-[#7a7a7a] p-1 text-center">
                      #230,000
                    </p>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="col-span-2 text-white md:col-span-1">
                      <p className="text-[13px]">Bidding stops in</p>
                      <p className="text-[20px] font-bold lg:text-[25px]">
                        48 : 12 : 54
                      </p>
                    </div>
                    <div className="col-span-2 pt-3 md:col-span-1 lg:pt-0">
                      <ContactButton
                        buttonText="Bid Now"
                        padding="lg:p-[10px_30px_10px_30px] p-[10px_25px_10px_25px]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ArtAuction>
        </div>
        {/*second card  */}
        <div className="container mx-auto mb-[50px] mt-[20px] max-w-5xl px-4 lg:px-0">
          <div className="grid grid-cols-2 gap-[20px]">
            <div className="col-span-2 md:col-span-1">
              <div>
                <ArtAuction>
                  <div className="flex justify-end">
                    <div className="absolute top-0 h-[100%] w-[180px] bg-[#100F0E] text-start text-white lg:h-[75vh] lg:w-[250px]">
                      <div className="relative left-9 top-[70px] w-[140px] lg:top-[40px] lg:w-[180px]">
                        <div className="w-[100px] lg:mx-auto lg:w-full">
                          <div>
                            <p className="font-heading text-[18px] tracking-normal lg:text-[28px] lg:leading-[40px]">
                              Cloud Romance
                            </p>
                          </div>
                          <div className="text-left">
                            <div className="pt-3 text-[13px] font-normal lg:pt-1 lg:text-[15px]">
                              By
                            </div>
                            <div className="pt-1 text-[13px] font-semibold tracking-wide lg:text-[15px]">
                              Emily Kadiri
                            </div>
                          </div>
                        </div>

                        <div className="py-[30px] lg:py-[70px]">
                          <p className="w-[100px] border border-[#FAFAFA] bg-[#7a7a7a] p-1 text-center">
                            #230,000
                          </p>
                        </div>
                        <div className="grid grid-cols-2">
                          <div className="col-span-2 text-white">
                            <p className="text-[13px]">Bidding stops in</p>
                            <p className="text-[20px] font-bold lg:text-[25px]">
                              48 : 12 : 54
                            </p>
                          </div>
                          <div className="col-span-2 pt-4 lg:pt-2">
                            <ContactButton
                              buttonText="Bid Now"
                              padding="lg:p-[10px_30px_10px_30px] p-[10px_10px_10px_10px]"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ArtAuction>
              </div>
            </div>
            <div className="col-span-2 md:col-span-1">
              <div>
                <ArtAuction>
                  <div className="flex justify-end">
                    <div className="absolute top-0 h-[100%] w-[180px] bg-[#100F0E] text-start text-white lg:h-[75vh] lg:w-[250px]">
                      <div className="relative left-9 top-[70px] w-[140px] lg:top-[40px] lg:w-[180px]">
                        <div className="w-[100px] lg:mx-auto lg:w-full">
                          <div>
                            <p className="font-heading text-[18px] tracking-normal lg:text-[28px] lg:leading-[40px]">
                              Cloud Romance
                            </p>
                          </div>
                          <div className="text-left">
                            <div className="pt-3 text-[13px] font-normal lg:pt-1 lg:text-[15px]">
                              By
                            </div>
                            <div className="pt-1 text-[13px] font-semibold tracking-wide lg:text-[15px]">
                              Emily Kadiri
                            </div>
                          </div>
                        </div>

                        <div className="py-[30px] lg:py-[70px]">
                          <p className="w-[100px] border border-[#FAFAFA] bg-[#7a7a7a] p-1 text-center">
                            #230,000
                          </p>
                        </div>
                        <div className="grid grid-cols-2">
                          <div className="col-span-2 text-white">
                            <p className="text-[13px]">Bidding stops in</p>
                            <p className="text-[20px] font-bold lg:text-[25px]">
                              48 : 12 : 54
                            </p>
                          </div>
                          <div className="col-span-2 pt-4 lg:pt-2">
                            <ContactButton
                              buttonText="Bid Now"
                              padding="lg:p-[10px_30px_10px_30px] p-[10px_20px_10px_20px]"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ArtAuction>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div>
          <RhythmOfExpression
            left="left"
            backgroundImage={"bg-manWithLongEye"}
          />
        </div>
      </LandingLayout>
    </div>
  );
};

export default Auction;
