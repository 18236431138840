import { CircularProgress } from "@material-ui/core";
import React from "react";

const ContactButton = ({ buttonText, width, padding, action, loading }) => {
  return (
    <div>
      <button
        onClick={action}
        type="button"
        className="button border border-[#FA9219] text-[#FA9219] font-heading w-full h-10 text-xs lg:text-sm"
        // style={{ width: `${width}%` }}
      >
        {loading ? (
          <div>
            <CircularProgress size={20} color={"inherit"} />
          </div>
        ) : (
          buttonText
        )}
      </button>
    </div>
  );
};

export default ContactButton;
