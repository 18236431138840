export const InputWhite = ({
  inputType,
  inputName,
  inputValue,
  inputPlaceholder,
  onChange,
  formik,
  label,
}) => {
  return (
    <div>
      <div className="text-body text-sm">{label}</div>
      <input
        type={inputType}
        name={inputName}
        placeholder={inputPlaceholder}
        onChange={onChange}
        // width: "600px", height: "60px",
        className="w-full  p-[9px_9px_10px_16px] border border-black text-dark placeholder-[#666257] text-md"
        autoComplete="off"
        {...formik}
      />
    </div>
  );
};
