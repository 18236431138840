import {
  GET_ARTISTS_SUCCESS,
  REGISTER_ARTIST_SUCCESS,
  GET_SINGLE_ARTIST_SUCCESS,
  EDIT_SINGLE_ARTIST_SUCCESS,
} from "../types";

const initialState = () => ({
  registerArtist: {},
  getArtists: [],
  getSingleArtist: [],
  editSingleArtist: [],
});

const registerArtistReducer = (state = initialState(), { type, payload }) => {
  switch (type) {
    case REGISTER_ARTIST_SUCCESS:
      return {
        ...state,
        registerArtist: payload.registerArtist,
      };
    case GET_ARTISTS_SUCCESS:
      return {
        ...state,
        getArtists: payload.artists,
      };
    case GET_SINGLE_ARTIST_SUCCESS:
      return {
        ...state,
        getSingleArtist: payload.artist,
      };
    case EDIT_SINGLE_ARTIST_SUCCESS:
      return { ...state, editSingleArtist: payload };
    default:
      return state;
  }
};

export default registerArtistReducer;
