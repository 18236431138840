import axios from "./axios";
import { call, put, takeLatest, spawn } from "redux-saga/effects";

import { REGISTER_REQUEST, LOGIN_REQUEST, UPDATE_USER_REQUEST } from "../types";
import { clientErrorMessage, delay } from "./reusable";
import {
  registerFailure,
  registerLoading,
  registerSuccess,
  loginFailure,
  loginLoading,
  loginSuccess,
  updateUserLoading,
  updateUserSuccess,
  updateUserFailure,
} from "../action";

const ajaxDBCalls = {
  register: async ({ formData }) => {
    const response = await axios.post(`/auth/register`, formData);
    return response;
  },
  login: async ({ formData }) => {
    const response = await axios.post(`/auth/login`, formData);
    return response;
  },
  updateUser: async ({ formData, token }) => {
    const response = await axios.put(`/auth/update`, formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  },
};

function* register({ payload }) {
  try {
    yield put(registerLoading(true));

    const res = yield call(ajaxDBCalls.register, payload);

    yield put(registerSuccess(res.data));

    yield put(registerLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(registerFailure(errorMessage));
    yield put(registerLoading(false));
    yield delay();
    yield put(registerFailure(""));
  }
}

function* login({ payload }) {
  try {
    yield put(loginLoading(true));

    const res = yield call(ajaxDBCalls.login, payload);

    yield put(loginSuccess(res.data));

    yield put(loginLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(loginFailure(errorMessage));
    yield put(loginLoading(false));
    yield delay();
    yield put(loginFailure(""));
  }
}
function* updateUser({ payload }) {
  try {
    yield put(updateUserLoading(true));

    const res = yield call(ajaxDBCalls.updateUser, payload);

    yield put(updateUserSuccess(res.data));

    yield put(updateUserLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(updateUserFailure(errorMessage));
    yield put(updateUserLoading(false));
    yield delay();
    yield put(updateUserFailure(""));
  }
}

//Watchers
function* registerWatcher() {
  yield takeLatest(REGISTER_REQUEST, register);
}
function* loginWatcher() {
  yield takeLatest(LOGIN_REQUEST, login);
}
function* updateUserWatcher() {
  yield takeLatest(UPDATE_USER_REQUEST, updateUser);
}

export default function* authSagas() {
  yield spawn(registerWatcher);
  yield spawn(loginWatcher);
  yield spawn(updateUserWatcher);
}
