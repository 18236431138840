import axios from "./axios";
import { call, put, takeLatest, spawn } from "redux-saga/effects";
import {
  DELETE_ARTIST_REQUEST,
  GET_ARTISTS_REQUEST,
  REGISTER_ARTIST_REQUEST,
  GET_SINGLE_ARTIST_REQUEST,
  EDIT_SINGLE_ARTIST_REQUEST,
} from "../types";
import { clientErrorMessage, delay } from "./reusable";
import {
  deleteArtistFailure,
  deleteArtistLoading,
  deleteArtistSuccess,
  getArtistsFailure,
  getArtistsLoading,
  getArtistsSuccess,
  registerArtistFailure,
  registerArtistLoading,
  registerArtistSuccess,
  getSingleArtistLoading,
  getSingleArtistSuccess,
  getSingleArtistFailure,
  editSingleArtistSuccess,
  editSingleArtistFailure,
  editSingleArtistLoading,
} from "../action";

const ajaxDBCalls = {
  registerArtist: async ({ formData }) => {
    const response = await axios.post(`/artist/create`, formData);
    return response;
  },
  getArtists: async () => {
    const response = await axios.get(`/artist/artists`);
    return response;
  },
  getSingleArtist: async (id) => {
    const response = await axios.get(`/artist/artist/${id}`);
    return response;
  },
  editSingleArtist: async ({ id, formData }) => {
    const response = await axios.put(`/artist/artist/${id}`, formData);
    return response;
  },
  deleteArtist: async (data) => {
    const response = await axios.delete(`/artist/delete`, { data });
    return response;
  },
};

function* registerArtist({ payload }) {
  try {
    yield put(registerArtistLoading(true));

    const res = yield call(ajaxDBCalls.registerArtist, payload);
    yield put(registerArtistSuccess(res.data));

    yield put(registerArtistLoading(false));
  } catch (err) {
   let errorMessage = "";
   if (err.request) errorMessage = clientErrorMessage;

   if (err.response) {
     const { error } = err.response.data;
     const { message } = err.response.data;

     if (error) errorMessage = error;
     else errorMessage = message;
   }


    yield put(registerArtistFailure(errorMessage));
    yield put(registerArtistLoading(false));
    yield delay();
    yield put(registerArtistFailure(""));
  }
}

// get Artists
function* getArtists({ payload }) {
  try {
    yield put(getArtistsLoading(true));

    const res = yield call(ajaxDBCalls.getArtists, payload);
    yield put(getArtistsSuccess(res.data));
    yield put(getArtistsLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      const { error } = err.response.data;
      const { message } = err.response.data;

      if (error) errorMessage = error;
      else errorMessage = message;
    }


    yield put(getArtistsFailure(errorMessage));
    yield put(getArtistsLoading(false));
    yield delay();
    yield put(getArtistsFailure(""));
  }
}

// get single Artist
function* getSingleArtist({ payload }) {
  try {
    yield put(getSingleArtistLoading(true));

    const res = yield call(ajaxDBCalls.getSingleArtist, payload);
    yield put(getSingleArtistSuccess(res.data));
    yield put(getSingleArtistLoading(false));
  } catch (err) {
   let errorMessage = "";
   if (err.request) errorMessage = clientErrorMessage;

   if (err.response) {
     const { error } = err.response.data;
     const { message } = err.response.data;

     if (error) errorMessage = error;
     else errorMessage = message;
   }


    yield put(getSingleArtistFailure(errorMessage));
    yield put(getSingleArtistLoading(false));
    yield delay();
    yield put(getSingleArtistFailure(""));
  }
}

// edit single Artist
function* editSingleArtist({ payload }) {
  try {
    yield put(editSingleArtistLoading(true));
    const res = yield call(ajaxDBCalls.editSingleArtist, payload);
    yield put(editSingleArtistSuccess(res.data));
    yield put(editSingleArtistLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      const { error } = err.response.data;
      const { message } = err.response.data;

      if (error) errorMessage = error;
      else errorMessage = message;
    }


    yield put(editSingleArtistFailure(errorMessage));
    yield put(editSingleArtistLoading(false));
    yield delay();
    yield put(editSingleArtistFailure(""));
  }
}

// delete Artists
function* deleteArtist({ payload }) {
  try {
    yield put(deleteArtistLoading(true));
    const res = yield call(ajaxDBCalls.deleteArtist, payload);
    yield put(deleteArtistSuccess(res.data));
    yield put(deleteArtistLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      const { error } = err.response.data;
      const { message } = err.response.data;

      if (error) errorMessage = error;
      else errorMessage = message;
    }

    yield put(deleteArtistFailure(errorMessage));
    yield put(deleteArtistLoading(false));
    yield delay();
    yield put(deleteArtistFailure(""));
  }
}

//Watchers
function* deleteArtistWatcher() {
  yield takeLatest(DELETE_ARTIST_REQUEST, deleteArtist);
}

//Watchers
function* registerArtistWatcher() {
  yield takeLatest(REGISTER_ARTIST_REQUEST, registerArtist);
}

//Watchers
function* getArtistsWatcher() {
  yield takeLatest(GET_ARTISTS_REQUEST, getArtists);
}

//Watchers
function* getSingleArtistWatcher() {
  yield takeLatest(GET_SINGLE_ARTIST_REQUEST, getSingleArtist);
}

//Watchers
function* editSingleArtistWatcher() {
  yield takeLatest(EDIT_SINGLE_ARTIST_REQUEST, editSingleArtist);
}

export default function* ArtistSagas() {
  yield spawn(getArtistsWatcher);
  yield spawn(registerArtistWatcher);
  yield spawn(deleteArtistWatcher);
  yield spawn(getSingleArtistWatcher);
  yield spawn(editSingleArtistWatcher);
}
