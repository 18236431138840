import React from "react";
import { Link } from "react-router-dom";
// import ProfileDropDown from '../profileDropDown/ProfileDropDown'
import notification from "../../../../assets/notification.png";
import dropdownArrow from "../../../../assets/dropdownArrow.png";
import profileImg from "../../../../assets/profileImg.png";
import Logo from "../../../../assets/Logo.svg";
import Search from "../../../../atoms/search/Search";

const ProfileNavbar = () => {
  return (
    <div>
      <div className="flex flex-col lg:flex-row items-center bg-dashboardBg h-[60px] lg:h-[18vh] relative">
        <div className="lg:bg-black  w-[20vw]  my-auto">
          <div className="absolute left-5 lg:left-10 ">
            <Link to="/">
              <img
                src={Logo}
                alt="kachiLogo"
                className=" lg:h-[32px] h-[20px] cursor-pointer"
              />
            </Link>
          </div>
        </div>
        {/* <div className='lg:ml-auto'> */}
        {/* <div className='flex justify-between items-center'> */}
        {/* <div>
            <div>
              <div> */}
        {/* <div className='flex items-center mb-4 md:mb-6 lg:pr-5'> */}
        {/* <div className='lg:mr-28 order-4 lg:order-none'>
                    <Search inputPlaceholder='Search ...' />
                  </div> */}
        {/* <div className='order-5 ml-9 mr-3 lg:order-none'>
                    <Link to='admin-notification'>
                      <img
                        src={notification}
                        alt='internet'
                        className='w-[30px] h-[30px]'
                      />
                    </Link>
                  </div> */}
        {/* <p className='pr-9 lg:pr-5 order-1 lg:order-none'>
                    <img
                      src={profileImg}
                      alt=''
                      className='w-[50px] h-[35px] lg:h-[40px]'
                    />
                  </p>
                  <div className='order-6 lg:order-none'>
                    <img
                      src={dropdownArrow}
                      alt='arrow'
                      className='w-[10px] h-[8px]'
                    />
                  </div> */}
        {/* </div> */}
        {/* </div>
            </div> */}
        {/* <ProfileDropDown /> */}
        {/* </div> */}
        {/* </div> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default ProfileNavbar;
