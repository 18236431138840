import React from "react";
import { CircularProgress } from "@material-ui/core";

const Button = ({ buttonText, padding, loading, action,color }) => {
  return (
    <div>
      <div>
        <button
          type="button"
          onClick={action}
          className={`button border ${color ? color :"border-[#100F0E] text-[#100F0E]"} font-heading w-full h-10 text-md ${padding}`}
        >
          {loading ? (
            <div>
              <CircularProgress size={20} color={"inherit"} />
            </div>
          ) : (
            buttonText
          )}
        </button>
      </div>
    </div>
  );
};

export default Button;
