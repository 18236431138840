import React from 'react'
import eventImg4 from '../../assets/studio.jpg';
import upcoming1 from '../../assets/upcoming1.png'
import upcoming2 from '../../assets/manevent.png'
import upcoming3 from '../../assets/artstudio.jpg';
// import UpComingCard from './UpComingCard'

const UpComingEvents = () => {
  return (
    <div className='container relative lg:py-16 mx-auto max-w-5xl'>
      <div>
        <div>
          <div className='grid lg:grid-cols-2 md:gap-4 w-full'>
            <div className=''>
              <div className='relative'>
                <div>
                  <div className='absolute z-10 p-[10px] lg:p-[15px] bg-[#FAFAFA] lg:w-[45%]'>
                    <p className='font-heading text-[10px] lg:text-[14px]'>
                      Upcoming Events
                    </p>
                  </div>
                  <img
                    src={upcoming1}
                    alt=''
                    className='w-full h-[70vh] lg:w-[500px] lg:h-[100vh] object-cover'
                  />
                </div>

                <div className='bg-[#100F0E] text-end text-white bg-opacity-60 absolute bottom-0 h-[30%] lg:h-[35%] lg:w-[500px] w-full'>
                  <div className='absolute bottom-0 h-[120px] lg:h-[170px] left-[30px]'>
                    <p className='font-heading text-[20px] lg:text-[30px] tracking-normal text-left lg:w-[250px] font-extrabold lg:leading-[35px]'>
                      The Unveiling of the ARTIST
                    </p>
                    <div className='text-[13px] lg:text-[18px] font-semibold tracking-wide text-left pt-[10px] lg:pt-[22px]'>
                      December 27, 2023.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='grid grid-cols-2 lg:grid-cols-1'>
              <div className='lg:mb-4'>
                <div className='relative'>
                  <img
                    src={upcoming2}
                    alt=''
                    className='w-full h-[35vh] lg:w-[500px] lg:h-[60vh] object-cover'
                  />
                  <div className='bg-[#100F0E] text-end text-white bg-opacity-60 absolute bottom-0 w-full h-[35%] lg:h-[35%] lg:w-[500px]'>
                    <div className='absolute bottom-0 h-[65px] lg:h-[120px] left-[15px] lg:left-[30px]'>
                      <p className='font-heading text-[14px] lg:text-[30px] tracking-normal text-left lg:w-[200px] lg:leading-[40px]'>
                        Art Exhibition
                      </p>
                      <div className='text-[10px] lg:text-[18px] font-semibold tracking-wide text-left pt-[5px]'>
                        December 27, 2023.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=''>
                <div className='relative'>
                  <img
                    src={upcoming3}
                    alt=''
                    className='w-full lg:h-[37.5vh] object-cover h-[35vh] lg:w-[500px]'
                  />
                  <div className='bg-[#100F0E] text-end text-white bg-opacity-60 absolute bottom-0 w-full h-[35%] lg:h-[55%] lg:w-[500px]'>
                    <div className='absolute bottom-0 h-[65px] lg:h-[120px] left-[15px] lg:left-[30px]'>
                      <p className='font-heading text-[14px] lg:text-[30px] tracking-normal text-left lg:w-[200px] lg:leading-[40px]'>
                        Art Exhibition
                      </p>
                      <div className='text-[10px] lg:text-[18px] font-semibold tracking-wide text-left pt-[5px]'>
                        December 27, 2023.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='grid grid-cols-9 lg:grid-cols-9 lg:mt-[20px]'>
          <div className='col-span-5 lg:col-span-4'>
            <img
              src={eventImg4}
              alt='art'
              className='h-[30vh] lg:h-[50vh] w-full object-cover'
            />
          </div>
          {/* w-[500px] h-[625px] */}
          <div className='col-span-4 lg:col-span-5'>
            <div className='bg-[#100F0E] text-end text-white pl-[30px] lg:pl-[60px] h-[30vh] lg:h-[50vh] pt-[50px] lg:pt-[100px] pb-[37px]'>
              {/* p-[185px_313px_0px_127px] */}
              <div>
                <p className='font-heading text-[14px] lg:text-[30px] tracking-normal text-left w-[120px] lg:w-[250px] lg:leading-[40px]'>
                  The Unveiling of the ARTIST
                </p>
              </div>
              <div className='pt-[25px] lg:pt-[35px]'>
                <div className='text-[10px] lg:text-[18px] 2xl:text-[20px] font-semibold tracking-wide text-left'>
                  December 27, 2023.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpComingEvents
