import React, { useEffect, useState } from "react";
import Logo from "../../assets/Logo.svg";
import { useLocation } from "react-router-dom";
// import useMenuOnScroll from '../../hooks/useMenuOnScroll'
import MobileMenu from "../mobileMenu";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Navbar = () => {
  // const ref = useMenuOnScroll();
  const location = useLocation();
  const [activeNav, setActiveNav] = useState(1);
  const [navItems, setNavItems] = useState([]);
  // const [openNavbar, setOpenNavbar] = useState(false);
  const navbarLinkNames = [
    {
      name: "Artworks",
      to: "artist",
      active: location.pathname.includes("artists"),
    },
    {
      name: "About",
      to: "about",
      active: location.pathname.includes("about"),
    },
    {
      name: "Events",
      to: "event-booking",
      active: location.pathname.includes("event-booking"),
    },
    // {
    //   name: "Auction",
    //   to: "auction-bidding",
    //   active: location.pathname.includes("auction-bidding"),
    // },
    {
      name: "Contact",
      to: "contact-us",
      active: location.pathname.includes("contact-us"),
    },
  ];

  const { user, isLoggedIn } = useSelector((state) => {
    const { user, isLoggedIn } = state.userData;
    return { user, isLoggedIn };
  });

  return (
    <header
      // ref={ref}
      className="h-[100px] z-10 duration-300 ease-in-out absolute w-full"
    >
      <div className="mx-auto flex h-full items-center justify-between gap-2 pb-8 lg:py-[50px] md:px-[50px] px-[22px] relative">
        <div className="">
          <Link to="/">
            <img
              src={Logo}
              alt="kachiLogo"
              className="h-5  lg:w-[193px] lg:h-[32px] cursor-pointer"
            />
          </Link>
        </div>
        <div className="lg:hidden">
          <MobileMenu
            // openNavbar={openNavbar}
            isLoggedIn={isLoggedIn}
            navItems={navbarLinkNames}
          />
        </div>
        <div className="hidden lg:flex">
          <div className="flex justify-between text-center gap-10">
            {navbarLinkNames.map(({ name, to }, index) => (
              <div key={index}>
                <Link
                  to={`/${to}`}
                  className="flex w-full items-center justify-center max-sm:pl-4 sm:w-fit font-medium text-white font-heading text-[14px]"
                >
                  <span>{name}</span>
                </Link>
              </div>
            ))}

            {isLoggedIn ? (
              <div>
                <Link
                  to={`/dashboard/profile`}
                  className="flex w-full items-center justify-center max-sm:pl-4 sm:w-fit font-medium text-white font-heading text-[14px]"
                >
                  <span>Dashboard</span>
                </Link>
              </div>
            ) : (
              <>
                <div>
                  <Link
                    to={`/register`}
                    className="flex w-full items-center justify-center max-sm:pl-4 sm:w-fit font-medium text-white font-heading text-[14px]"
                  >
                    <span>Register</span>
                  </Link>
                </div>
                <div>
                  <Link
                    to={`/login`}
                    className="flex w-full items-center justify-center max-sm:pl-4 sm:w-fit font-medium text-white font-heading text-[14px]"
                  >
                    <span>Login</span>
                  </Link>
                </div>
              </>
            )}
          </div>
          {/* <div className="flex text-white pl-[64px] font-heading text-[14px]">
            <div>SignIn</div>
            <div>/Login</div>
          </div> */}
        </div>
      </div>
    </header>
  );
};

export default Navbar;
