import {
  CREATE_ORDER_SUCCESS,
  GET_USER_ORDER_SUCCESS,
  //   GET_ORDER_SUCCESS,
  //   GET_ORDERS_SUCCESS,
} from "../types";

const initialState = () => ({
  order: {},
  orders: [],
});

const orderReducer = (state = initialState(), { type, payload }) => {
  switch (type) {
    case CREATE_ORDER_SUCCESS:
      return {
        ...state,
        order: payload.order,
      };
    case GET_USER_ORDER_SUCCESS:
      return {
        ...state,
        orders: payload.orders,
      };
    // case GET_ORDER_SUCCESS:
    //   return {
    //     ...state,
    //     order: payload.order,
    //   };

    default:
      return state;
  }
};

export default orderReducer;
