import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getArtworksRequest } from "../../redux/action";
import { CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";

const ArtistCardTwo = () => {
  const dispatch = useDispatch();

  const {
    artworks,
    getArtworksLoading,
    getArtworksError,
    registerSuccess,
    user,
  } = useSelector((state) => {
    const {
      errors: { getArtworks: getArtworksError },
    } = state.ajaxStatuses;

    const { getArtworksLoading } = state.loadingIndicator;

    const { user } = state.userData;
    const { artworks } = state.artworkData;

    return {
      artworks,
      getArtworksLoading,
      getArtworksError,
      user,
    };
  });

  useEffect(() => {
    dispatch(getArtworksRequest());
  }, [getArtworksRequest]);

  return (
    <div className="container mx-auto pb-[50px] lg:pb-[80px] overflow-scroll">
      {getArtworksLoading ? (
        <div className="flex items-center justify-center lg:h-[550px]">
          <CircularProgress size={20} style={{ color: "black" }} />
        </div>
      ) : (
        <div
          style={{
            width:
              window.screen.width >= 1024
                ? artworks.length * 500
                : artworks.length * 300,
          }}
          className={`flex overflow-x-scroll `}
        >
          {artworks.map((artwork, index) => (
            <Link key={index} to={`/art-page/${artwork._id}`}>
              <img
                src={artwork.img_url}
                alt="art"
                className="w-[300px] h-[350px] lg:w-[500px] lg:h-[500px]  object-cover relative "
              />
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default ArtistCardTwo;
