import React from "react";
import mapimg from "../../assets/map.jpg";

export const MapComponent = ({
  left,
  backgroundImage,
  bgImageBottomCenter,
  header,
  buttonText,
  buttonIcon,
}) => {
  return (
    <div className={` h-[266px] md:h-[40vh] lg:h-[100vh]  relative `}>
      <div>
        <img src={mapimg} className="object-cover h-full w-full absolute" />
      </div>
      <div className="absolute h-full w-full backdrop-brightness-50">
        <div
          className={`left-10 bottom-5 lg:bottom-10 w-[200px] lg:w-[380px]  px-[20px] lg:px-[38px] bg-[#5A5A5A] absolute text-white  py-2 bg-opacity-90 border-[#FFFFFF] border`}
        >
          {/* rhythm card */}

          <div className="">
            <div>
              <p className="font-heading text-[13px] lg:text-[28px] tracking-normal">
                {header}
              </p>
            </div>
            <a
              target="blank"
              href="https://maps.app.goo.gl/M9LvGX6wP5fcVVA47?g_st=iw"
              className="flex items-center justify-between pt-[15px] pb-[5px]"
            >
              <div className="text-[12px] lg:text-[25px] 2xl:text-[40px] lg:leading-[30px] font-medium">
                {buttonText}
              </div>
              <div className="text-[40px] leading-[76px] font-semibold tracking-wide">
                <img
                  src={buttonIcon}
                  alt=""
                  className="w-[12px] h-[12px]  lg:w-[35px] lg:h-[30px]"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
