import React from "react";

const AdminTableWrapper = ({ children }) => {
  return (
    <div className="">
      <div className="">{children}</div>
    </div>
  );
};

export default AdminTableWrapper;
