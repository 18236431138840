import {
  CLEAR_DELETE_ARTIST_SUCCESS,
  CLEAR_EDIT_SINGLE_ARTIST_SUCCESS,
  CLEAR_REGISTER_ARTIST_SUCCESS,
  DELETE_ARTIST_FAIL,
  DELETE_ARTIST_LOADING,
  DELETE_ARTIST_REQUEST,
  DELETE_ARTIST_SUCCESS,
  EDIT_SINGLE_ARTIST_FAIL,
  EDIT_SINGLE_ARTIST_LOADING,
  EDIT_SINGLE_ARTIST_REQUEST,
  EDIT_SINGLE_ARTIST_SUCCESS,
  GET_ARTISTS_FAIL,
  GET_ARTISTS_LOADING,
  GET_ARTISTS_REQUEST,
  GET_ARTISTS_SUCCESS,
  GET_SINGLE_ARTIST_FAIL,
  GET_SINGLE_ARTIST_LOADING,
  GET_SINGLE_ARTIST_REQUEST,
  GET_SINGLE_ARTIST_SUCCESS,
  REGISTER_ARTIST_FAIL,
  REGISTER_ARTIST_LOADING,
  REGISTER_ARTIST_REQUEST,
  REGISTER_ARTIST_SUCCESS,
} from "../types";

export const registerArtistRequest = (prop) => {
  return {
    type: REGISTER_ARTIST_REQUEST,
    payload: prop,
  };
};

export const registerArtistSuccess = (prop) => {
  return {
    type: REGISTER_ARTIST_SUCCESS,
    payload: prop,
  };
};

export const registerArtistFailure = (errors) => {
  return {
    type: REGISTER_ARTIST_FAIL,
    payload: { errors },
  };
};

export const registerArtistLoading = (loading) => {
  return {
    type: REGISTER_ARTIST_LOADING,
    payload: loading,
  };
};

export const clearRegisterArtistSuccess = (prop) => {
  return {
    type: CLEAR_REGISTER_ARTIST_SUCCESS,
    payload: prop,
  };
};

// Get artist
export const getArtistsRequest = (prop) => {
  return {
    type: GET_ARTISTS_REQUEST,
    payload: prop,
  };
};

export const getArtistsSuccess = (prop) => {
  return {
    type: GET_ARTISTS_SUCCESS,
    payload: prop,
  };
};

export const getArtistsFailure = (errors) => {
  return {
    type: GET_ARTISTS_FAIL,
    payload: { errors },
  };
};

export const getArtistsLoading = (loading) => {
  return {
    type: GET_ARTISTS_LOADING,
    payload: loading,
  };
};

// Get single artist
export const getSingleArtistRequest = (prop) => {
  return {
    type: GET_SINGLE_ARTIST_REQUEST,
    payload: prop,
  };
};

export const getSingleArtistSuccess = (prop) => {
  return {
    type: GET_SINGLE_ARTIST_SUCCESS,
    payload: prop,
  };
};

export const getSingleArtistFailure = (errors) => {
  return {
    type: GET_SINGLE_ARTIST_FAIL,
    payload: { errors },
  };
};

export const getSingleArtistLoading = (loading) => {
  return {
    type: GET_SINGLE_ARTIST_LOADING,
    payload: loading,
  };
};

// edit artist
export const editSingleArtistRequest = (prop) => {
  return {
    type: EDIT_SINGLE_ARTIST_REQUEST,
    payload: prop,
  };
};

export const editSingleArtistSuccess = (prop) => {
  return {
    type: EDIT_SINGLE_ARTIST_SUCCESS,
    payload: prop,
  };
};

export const editSingleArtistFailure = (errors) => {
  return {
    type: EDIT_SINGLE_ARTIST_FAIL,
    payload: { errors },
  };
};

export const editSingleArtistLoading = (loading) => {
  return {
    type: EDIT_SINGLE_ARTIST_LOADING,
    payload: loading,
  };
};

export const clearEditSingleArtistSuccess = (prop) => {
  return {
    type: CLEAR_EDIT_SINGLE_ARTIST_SUCCESS,
    payload: prop,
  };
};

// delete artist
export const deleteArtistRequest = (prop) => {
  return {
    type: DELETE_ARTIST_REQUEST,
    payload: prop,
  };
};

export const deleteArtistSuccess = (prop) => {
  return {
    type: DELETE_ARTIST_SUCCESS,
    payload: prop,
  };
};

export const deleteArtistFailure = (errors) => {
  return {
    type: DELETE_ARTIST_FAIL,
    payload: { errors },
  };
};

export const deleteArtistLoading = (loading) => {
  return {
    type: DELETE_ARTIST_LOADING,
    payload: loading,
  };
};

export const clearDeleteArtistSuccess = (prop) => {
  return {
    type: CLEAR_DELETE_ARTIST_SUCCESS,
    payload: prop,
  };
};
