import React from 'react'
import AdminDashboardActivity from '../../Dashboard'
import AdminTotalActivity from '../../molecule/adminTotalActivity/AdminTotalActivity'
import AdminTableLayout from '../../molecule/adminTableLayout/AdminTableLayout'

import notificationImg from '../../../../assets/notificationImg.svg'
import circleImg from '../../../../assets/circleImg.svg'
import arrowLeft from '../../../../assets/arrowLeft.svg'
import AdminTableWrapper from '../../molecule/adminTableLayout/AdminTableWrapper'
import AdminTableHead from '../../molecule/adminTableLayout/AdminTableHead'
import NotificationTableHead from '../../molecule/adminTableLayout/NotificationTableHead'

const Notification = () => {
  const tableHeaders = [
    { title: 'Description', width: '20%' },
    { title: 'Quantity', width: '30%' },
    { title: 'Amount', width: '30%' },
  ]
  const notificationData = [
    {
      artName: 'Shameful Pain',
      author: 'Gozie Adewale',
      quantity: 2,
      amount: '200,000',
    },
    {
      artName: 'Shameful Pain',
      author: 'Gozie Adewale',
      quantity: 2,
      amount: '200,000',
    },
  ]
  return (
    <div>
      <div>
        <AdminDashboardActivity>
          <div className='container max-w-5xl mx-auto px-8 my-16'>
            <div className=''>
              <h4 className='font-heading lg:text-[25px] lg:leading-[41px] mb-[12px] lg:mb-[20px] text-black'>
                Notifications
              </h4>
            </div>
            {/*  */}
            <div className='shadow-md p-9 h-[25vh] my-5'>
              <div className='flex items-center gap-[20px]'>
                <img src={notificationImg} alt='' className='w-[65px]' />
                <div>
                  <p className='text-[17px]'>
                    Beta Darvin made payment for <b>shameful pain</b> by Gozie
                    Adewale
                  </p>
                  <div className='flex items-center justify-between w-[180px] text-[#777369] pt-3'>
                    <p>1 mins ago</p>
                    <div>
                      <img src={circleImg} alt='' />
                    </div>
                    <div>
                      <p>Art Painting</p>
                    </div>
                  </div>
                  <div className='relative top-10'>
                    <p className='px-4'>
                      <b>Order Alert</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            <div className='shadow-md p-9 h-[25vh] my-5'>
              <div className='flex items-center gap-[20px]'>
                <img src={notificationImg} alt='' className='w-[65px]' />
                <div>
                  <p className='text-[17px]'>
                    Beta Darvin made payment for <b>shameful pain</b> by Gozie
                    Adewale
                  </p>
                  <div className='flex items-center justify-between w-[180px] text-[#777369] pt-3'>
                    <p>1 mins ago</p>
                    <div>
                      <img src={circleImg} alt='' />
                    </div>
                    <div>
                      <p>Art Painting</p>
                    </div>
                  </div>
                  <div className='relative top-10'>
                    <p className='px-4'>
                      <b>Order Alert</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            <div className='shadow-md p-9 h-[25vh] my-5'>
              <div className='flex items-center gap-[20px]'>
                <img src={notificationImg} alt='' className='w-[65px]' />
                <div>
                  <p className='text-[17px]'>
                    Beta Darvin made payment for <b>shameful pain</b> by Gozie
                    Adewale
                  </p>
                  <div className='flex items-center justify-between w-[180px] text-[#777369] pt-3'>
                    <p>1 mins ago</p>
                    <div>
                      <img src={circleImg} alt='' />
                    </div>
                    <div>
                      <p>Art Painting</p>
                    </div>
                  </div>
                  <div className='relative top-10'>
                    <p className='px-4'>
                      <b>Order Alert</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            <div className='shadow-md p-9 h-[25vh] my-5'>
              <div className='flex items-center gap-[20px]'>
                <img src={notificationImg} alt='' className='w-[65px]' />
                <div>
                  <p className='text-[17px]'>
                    Beta Darvin made payment for <b>shameful pain</b> by Gozie
                    Adewale
                  </p>
                  <div className='flex items-center justify-between w-[180px] text-[#777369] pt-3'>
                    <p>1 mins ago</p>
                    <div>
                      <img src={circleImg} alt='' />
                    </div>
                    <div>
                      <p>Art Painting</p>
                    </div>
                  </div>
                  <div className='relative top-10'>
                    <p className='px-4'>
                      <b>Order Alert</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            <div className='mt-[130px]'>
              <div>
                <div>
                  <img src={arrowLeft} alt='' className='w-[25px] h-[25px]' />
                </div>
                <div>
                  <div className='flex flex-row gap-[30px]'>
                    <div>
                      <p>
                        <b>From</b>
                      </p>
                    </div>
                    <div>
                      <p>Beta Darvin</p>
                      <p>betadarvin@gmail.com</p>
                      <p>+234 906 786 8976</p>
                      <p>No3 sanziwe street, new heaven Enugu state.</p>
                    </div>
                  </div>
                </div>
                <div>
                  <table>
                    <AdminTableWrapper>
                      <NotificationTableHead headers4={tableHeaders}>
                        {/* <div className='border-[red] border-b-2'> tw-border-solid*/}
                        {notificationData.map((item, index) => {
                          return (
                            <tr
                              className='border-b-2 border-[#C4C2BA]'
                              key={index}
                            >
                              <td className='whitespace-nowrap p-4 '>
                                <div>
                                  <p className='text-[13px] lg:text-[15px] tracking-normal'>
                                    <b>{item.artName}</b>
                                  </p>
                                  <div className='text-[9px] lg:text-[15px] font-normal tracking-wide'>
                                    by {item.author}
                                  </div>
                                </div>
                                {item.description}
                              </td>
                              <td className='p-4 font-[500]'>
                                {item.quantity}
                              </td>
                              <td className='p-4 font-[500]'>#{item.amount}</td>
                            </tr>
                          )
                        })}
                        {/* </div> */}
                      </NotificationTableHead>
                    </AdminTableWrapper>
                  </table>
                  <div className='flex items-center justify-center'>
                    <div>
                      <p className='text-[24px] font-bold'>Total:</p>
                    </div>
                    <div className='font-[500] pl-3'>#406, 000</div>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        </AdminDashboardActivity>
      </div>
    </div>
  )
}

export default Notification
