import React from "react";
import { Link } from "react-router-dom";

const ArtIsLife = ({
  headerOne = "",
  headerTwo,
  paragraph,
  buttonText = "",
  buttonIcon = "",
  buttonColor = "",
  img1 = "",
  img4 = "",
  img3 = "",
  img5 = "",
  url,
}) => {
  return (
    <div className="container mx-auto lg:px-8 relative py-5 md:py-16 px-4">
      <div className="grid md:grid-cols-5 justify-between">
        <div className="col-span-2 justify-self-end mt-6 lg:mt-12">
          <div className="text-[8px] lg:text-[20px] 2xl:text-[36px]">
            <p>{headerOne}</p>
          </div>
          <div className="text-[26px] leading-[30px] lg:leading-[75px] font-heading lg:text-[70px]  lg:max-w-[400px]">
            {headerTwo}
          </div>
          <div className="text-sm md:text-[20px] font-normal 2xl:text-[30px]  2xl:leading-[46px] lg:max-w-[300px] mt-2 mb-4 lg:mb-0 lg:mt-8">
            {paragraph}
          </div>
          <Link
            to={url}
            className="flex justify-items-center items-center text-[#FA9219] gap-5 pb-9 lg:pb-0 pt-[0px] md:pt-[28px]"
          >
            <p className="text-[12px] md:text-[25px] 2xl:text-[118px] font-heading">
              {buttonText}
            </p>
            {buttonIcon && (
              <img
                src={buttonIcon}
                alt="arrow"
                className="w-[11px] h-[12px] md:w-[25px] md:h-[25px]"
              />
            )}
          </Link>
        </div>
        <div className=" col-span-3 grid grid-cols-2 gap-3 lg:gap-5 relative">
          <div className="flex flex-col gap-y-4">
            <img
              data-aos="fade-up"
              src={img1}
              alt="art"
              className="lg:h-[350px] w-full object-cover"
            />
            <img
              data-aos="fade-up"
              src={img5}
              alt="art"
              className="lg:h-[400px] w-full object-cover"
            />
          </div>

          <div className="flex flex-col gap-y-4">
            <img
              data-aos="fade-up"
              src={img4}
              alt="art"
              className="lg:h-[350px] w-[350px] object-fill"
            />
            <img
              data-aos="fade-up"
              src={img3}
              alt="art"
              className="h-full lg:h-[400px] w-full object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtIsLife;
